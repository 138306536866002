import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_OFFLINE_CAR_INSURANCE } from '../../constants/routes/offline-services/offline-car-insurance.constants';
import { OfflineCarInsuranceStepOneComponent } from '../../../offline-services/car-insurance/offline-car-insurance-step-one/offline-car-insurance-step-one.component';
import { OfflineCarInsuranceStepTwoComponent } from '../../../offline-services/car-insurance/offline-car-insurance-step-two/offline-car-insurance-step-two.component';
import { OfflineCarInsuranceOrdersComponent } from '../../../offline-services-orders/car-insurance/offline-car-insurance-orders/offline-car-insurance-orders.component';
import { OfflineCarInsuranceOrderDetailComponent } from '../../../offline-services-orders/car-insurance/offline-car-insurance-order-detail/offline-car-insurance-order-detail.component';

export const OfflineCarInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineCarInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineCarInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineCarInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineCarInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineCarInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CAR_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineCarInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];