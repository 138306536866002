import { FormGroup, FormControl, Validators } from '@angular/forms';
import { vehicleNumberValidator } from '../../../common/utilities/form-rules';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        vehicle_number: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            vehicleNumberValidator
        ]),
    });
}
