import { Component } from '@angular/core';

@Component({
  selector: 'app-online-car-insurance-payment',
  standalone: true,
  imports: [],
  templateUrl: './online-car-insurance-payment.component.html'
})
export class OnlineCarInsurancePaymentComponent {

}
