<section class="sec insplan pt-4 pb-5">
    <div class="container">
        <div class="col-md-12 col-lg-10 mx-auto">
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-6 col-xl-6 my-auto">
                    <div class="bg-white box_design border shadow p-4 rounded text-center text-md-start">
                        <h2 class="fw-bold fs-4 mb-3 agent_reg text-center">OTP Verification</h2>
                        <div class="mt-2 mb-3 text-center text-dark">Please Enter OTP</div>
                        <div class="row">
                            <div class="col-md-12 d-flex align-items-center justify-content-center">
                                <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                    <div class="d-flex justify-content-between">
                                        <input id="otp1" formControlName="otp1" type="tel" maxlength="1" class="form-control box_top text-center"
                                               (input)="onOtpInput(1, $event)" (keydown)="onKeyDown(1, $event)" />
                                        <input id="otp2" formControlName="otp2" type="tel" maxlength="1" class="form-control box_top text-center"
                                               (input)="onOtpInput(2, $event)" (keydown)="onKeyDown(2, $event)" />
                                        <input id="otp3" formControlName="otp3" type="tel" maxlength="1" class="form-control box_top text-center"
                                               (input)="onOtpInput(3, $event)" (keydown)="onKeyDown(3, $event)" />
                                        <input id="otp4" formControlName="otp4" type="tel" maxlength="1" class="form-control box_top text-center"
                                               (input)="onOtpInput(4, $event)" (keydown)="onKeyDown(4, $event)" />
                                      </div>
                                    <div class="otptimer text-center fs-14 mb-3">
                                        @if(resendButtonDisabled) {
                                            <span class="text-muted"> {{ timerValue }}</span>
                                            <button type="button" class="fs-14 border-0 bg-white fw-semi resendotp" [disabled]="resendButtonDisabled">Resend OTP</button>
                                        } @else {
                                            <button type="button" class="text-success fs-14 border-0 bg-white fw-semi resendotp" (click)="resendOtp()" [disabled]="resendButtonDisabled">Resend OTP</button>
                                        }
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group text-center mb-2">
                                                <button type="submit"
                                                    class="btn btn-success rounded-5 btn-lg w-100 mb-4" [disabled]="isLoading">VERIFY
                                                    OTP</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>