import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { API_RESPONSE } from '../constants/api-responses.constants';
import { LocalStorageService } from './local-storage.service';
import { CookieStorageService } from './cookie-storage.service';
import { environment } from '../../../environments/environment';

import { storeResetUserProfileGuard } from '../../store/actions/user-profile-guard.actions';
import { storeResetUserProfile } from '../../store/actions/user-profile.actions';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private apiURL = '';
  globalConstant = GLOBAL_CONSTANT;
  constructor(
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private store: Store,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService
  ) {
    this.apiURL = environment?.apiURL;
  }

  request<T>(method: string, url: string, body?: any, show_message: boolean = true, headers?: HttpHeaders): Observable<T> {
    switch (method.toUpperCase()) {
      case 'GET':
        return this.http.get<T>(this.apiURL + url, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      case 'POST':
        return this.http.post<T>(this.apiURL + url, body, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      case 'PUT':
        return this.http.put<T>(this.apiURL + url, body, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      case 'GET_AUTH':
        return this.http.get<T>(this.apiURL + url, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      case 'POST_AUTH':
        return this.http.post<T>(this.apiURL + url, body, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      case 'PUT_AUTH':
        return this.http.put<T>(this.apiURL + url, body, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      case 'GET_AUTH_NON_AUTH':
        return this.http.get<T>(this.apiURL + url, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      case 'POST_AUTH_NON_AUTH':
        return this.http.post<T>(this.apiURL + url, body, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      case 'PUT_AUTH_NON_AUTH':
        return this.http.put<T>(this.apiURL + url, body, { headers, observe: 'response' })
          .pipe(
            map((response: HttpResponse<T>) => this.handleResponse(response, show_message)),
            catchError((error) => this.handleError(error, show_message))
          );
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }
  }

  private handleResponse<T>(response: HttpResponse<T>, show_message: boolean): T {
    const body: any = response.body;
    if (body && typeof body === 'object' && 'status' in body && body['status'] === API_RESPONSE?.status?.error) {
      throw new Error(body['message'] || 'Unknown error occurred');
    }
    return body;
  }

  private handleError(error: any, show_message: boolean): Observable<never> {
    if (error?.status == API_RESPONSE?.status_codes?.unauthorized || error?.error?.status_code == API_RESPONSE?.status_codes?.unauthorized) {
      this.store.dispatch(storeResetUserProfileGuard());
      this.store.dispatch(storeResetUserProfile());
      this.localStorageService.removeItem(this.globalConstant?.session?.auth_token);
      this.cookieStorageService.deleteCookie(this.globalConstant?.cookie?.otp_verification_data);
      this.cookieStorageService.deleteCookie(this.globalConstant?.cookie?.service_login_data);
      this.router.navigate([this.globalConstant.base_url]);
    }
    let errorMessage = 'Unknown error!';
    if (error?.error instanceof HttpErrorResponse) {
      errorMessage = `Error: ${error?.error?.message}`;
    } else if (error?.error && error?.error?.message) {
      errorMessage = `Error: ${error?.error?.message}`;
    } else {
      errorMessage = `Error: ${error?.message}`;
    }
    if (show_message) {
      this.snackBar.open(errorMessage, 'Close', {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['danger-snackbar']
      });
    }
    return throwError(error);
  }
}
