import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../common/constants/global-constant.constants';
import { ROUTES_PAGES } from '../common/constants/routes/pages/pages.constants';
import { UtilitiesService } from '../common/utilities/utilities.service';
import { OfflineServicesOrdersService } from './offline-services-orders.service';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-posp-services',
  standalone: true,
  imports: [
    NgFor,
    RouterModule
  ],
  templateUrl: './offline-services-orders.component.html'
})

export class OfflineServicesOrdersComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;

  projectName = this.globalConstant?.projectName;

  servicesCount: any = {};

  private apiServicesCountSubscription: Subscription | undefined;

  constructor(
    private utilitiesService: UtilitiesService,
    private offlineServicesOrdersService: OfflineServicesOrdersService
  ) { }

  ngOnInit(): void {
    this.apiGetServicesCount();
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  getOffLineServiceOrdersURL(id: number, orderShowType: string = this.globalConstant?.offline_order_show_type?.orders, dateKey: string = ''): string[] {
    return this.utilitiesService.getOffLineServiceOrdersURL(id, orderShowType, dateKey);
  }

  apiGetServicesCount() {
    this.apiServicesCountSubscription = this.offlineServicesOrdersService.getServicesCount().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            this.servicesCount = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiServicesCountSubscription) {
      this.apiServicesCountSubscription.unsubscribe();
    }
  }

}
