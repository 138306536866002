import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineTwoWheelerInsuranceChoosePlanService {

  constructor(
    private apiService: ApiService
  ) { }

  getVehicleDetail(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/vehicle-detail-fetch', formData, false);
  }

  getQuotesList(formData: any) {
    return this.apiService.request<any>('POST_AUTH_NON_AUTH', 'two-wheeler-insurance/get-quote', formData, false);
  }
}
