import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { OfflineCommisionService } from './offline-commision.service';

@Component({
  selector: 'app-offline-commission',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './offline-commission.component.html',
})
export class OfflineCommissionComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  offlineCommissionList: any = {};

  private apiOfflineCommissionListSubscription: Subscription | undefined;

  constructor(
    private utilitiesService: UtilitiesService,
    private offlineCommissionService: OfflineCommisionService
  ) { }

  ngOnInit(): void {
    this.apiGetOfflineCommissionList();
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  getOffLineServiceOrdersURL(id: number, orderShowType: string = this.globalConstant?.offline_order_show_type?.commission, dateKey: string = ''): string[] {
    return this.utilitiesService.getOffLineServiceOrdersURL(id, orderShowType, dateKey);
  }

  apiGetOfflineCommissionList() {
    this.apiOfflineCommissionListSubscription = this.offlineCommissionService.getOfflineCommissionList().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.offlineCommissionList = response?.data && this.objectLength(response?.data) > 0 ? response.data : {};
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiOfflineCommissionListSubscription) {
      this.apiOfflineCommissionListSubscription.unsubscribe();
    }
  }

}
