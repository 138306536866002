import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PospKycService {

  constructor(
    private apiService: ApiService
  ) { }

  getFormData() {
    return this.apiService.request<any>('GET_AUTH', 'posp/posp-status');
  }

  postData(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'posp/kyc-process', formData);
  }

}
