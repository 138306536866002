import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private localStorage!: Storage;
  private storageKey = 'vehicleDetails';

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage = window.localStorage;
    } else {
    }
  }

  setItem(key: string, value: any): void {
    if (this.localStorage) {
      try {
        this.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
      }
    }
  }

  getItem(key: string): any {
    if (this.localStorage) {
      const item = this.localStorage.getItem(key);
      if (item) {
        try {
          return JSON.parse(item);
        } catch (error) {
          return null;
        }
      }
    }
    return null;
  }

  removeItem(key: string): void {
    if (this.localStorage) {
      try {
        this.localStorage.removeItem(key);
      } catch (error) {
      }
    }
  }

  isItem(key: string): boolean {
    return !!this.getItem(key);
  }

  isLoggedIn(key: string): boolean {
    return !!this.getItem(key);
  }

  saveVehicleDetails(vehicleDetails: any): void {
    localStorage.setItem(this.storageKey, JSON.stringify(vehicleDetails));
  }

  getVehicleDetails(): any {
    const details = localStorage.getItem(this.storageKey);
    return details ? JSON.parse(details) : null;
  }

  clearVehicleDetails(): void {
    localStorage.removeItem(this.storageKey);
  }

}
