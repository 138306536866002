<div mat-dialog-content class="custom-dialog modal fade show" tabindex="-1" aria-modal="true" role="dialog" style="display: block; padding-left: 0px;">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header">
              @if(data?.title && data?.title != '') {
                <h1 class="modal-title fs-6" [innerHTML]="data?.title"></h1>
              }
              <a (click)="onClose()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
          </div>
          <div class="modal-body">
            <div [innerHTML]="data?.content"></div>
          </div>
          <div class="modal-footer">
              <a (click)="onClose()" class="btn btn-success btn-sm rounded-1">{{ data?.button_label && data?.button_label != '' ? data?.button_label : 'Close' }}</a>
          </div>
      </div>
  </div>
</div>