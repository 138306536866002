import { Action, createReducer, on } from '@ngrx/store';
import { storeSetHome, storeResetHome } from '../actions/home.actions';

export interface StoreHome { [key: string]: any; }

export interface StoreStateHome {
  storeData: StoreHome | null;
}

export const initialState: StoreStateHome = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetHome, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetHome, state => ({ ...state, storeData: null }))
);

export function reducerHome(state: StoreStateHome | undefined, action: Action) { return _storeReducer(state, action); }