import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class PospReExamService {

  constructor(
    private apiService: ApiService
  ) { }

  getPageData() {
    return this.apiService.request<any>('GET_AUTH', 'posp/exam/re-exam');
  }

}
