import { GLOBAL_CONSTANT } from '../global-constant.constants';
import { ROUTES_ONLINE_TWO_WHEELER_INSURANCE } from '../routes/online-services/online-two-wheeler-insurance.constants';
import { ROUTES_ONLINE_CAR_INSURANCE } from '../routes/online-services/online-car-insurance.constants';

export const ONLINE_SERVICES_ID = {
  TWO_WHEELER_INSURANCE: 1,
  CAR_INSURANCE: 2
}

export const ONLINE_SERVICES_STEP = {
  STEP_1: 'step_1',
  STEP_2: 'step_2',
  STEP_3: 'step_3',
  STEP_4: 'step_4'
}

export const ONLINE_SERVICES_URL: Record<string, any> = {
  step_1: {
    [ONLINE_SERVICES_ID.TWO_WHEELER_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_ONLINE_TWO_WHEELER_INSURANCE.BASIC_DETAILS],
    [ONLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_ONLINE_CAR_INSURANCE.BASIC_DETAILS]
  },
  step_2: {
    [ONLINE_SERVICES_ID.TWO_WHEELER_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_ONLINE_TWO_WHEELER_INSURANCE.CHOOSE_PLAN],
    [ONLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_ONLINE_CAR_INSURANCE.CHOOSE_PLAN]
  },
  step_3: {
    [ONLINE_SERVICES_ID.TWO_WHEELER_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PERSONAL_DETAILS],
    [ONLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_ONLINE_CAR_INSURANCE.PERSONAL_DETAILS]
  },
  step_4: {
    [ONLINE_SERVICES_ID.TWO_WHEELER_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_ONLINE_TWO_WHEELER_INSURANCE.PAYMENT],
    [ONLINE_SERVICES_ID.CAR_INSURANCE]: [GLOBAL_CONSTANT.base_url, ROUTES_ONLINE_CAR_INSURANCE.PAYMENT]
  }
};

