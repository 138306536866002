<section class="sec insplan py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto">
                <div class="bg-white w-100 text-start border rounded-2 shadow-sm overflow-hidden">
                    <div class="py-2 px-3 bg-light-success border-bottom">
                        <h2 class="fs-18 mb-0" [innerHTML]="courseData?.title"></h2>
                    </div>
                    <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off"
                        enctype="multipart/form-data">
                        <div class="px-4 py-3">
                            <div class="col-12">
                                <p class="m-0 fs-14" [innerHTML]="courseData?.subtitle"></p>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <div class="form-group">
                                        @if(arrayLength(courseData?.training_type) > 0) {
                                        @for(data of courseData?.training_type; track i; let i=$index) {
                                        <div
                                            class="form-check form-check-inline typeradio select-course customradio w-100">
                                            <input id="{{ data?.key || '' }}" formControlName="course"
                                                value="{{ data?.key || '' }}" type="radio" class="form-check-input">
                                            <label for="{{ data?.key || '' }}" class="form-check-label"> {{ data?.value
                                                || '' }} </label>
                                        </div>
                                        }
                                        }
                                        <mat-error *ngIf="getErrorMessage('course')" class="custom-error">
                                            {{ getErrorMessage('course') }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group text-center my-4">
                                        <button type="submit" aria-label="plans"
                                            class="btn btn-green rounded-5 px-4 btn-lg" [disabled]="isLoading"> Start Training </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>