import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_USERS } from '../../common/constants/routes/users/users.constants';
import { API_RESPONSE } from '../../common/constants/api-responses.constants';
import { NumericInputDirective } from '../../common/directives/numeric-input.directive';
import { LocalStorageService } from '../../common/utilities/local-storage.service';
import { CookieStorageService } from '../../common/utilities/cookie-storage.service';
import { MomentUtilitiesService } from '../../common/utilities/moment-utilities.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { FormUtilitiesService } from '../../common/utilities/form-utilities.service';
import { TimerService } from '../../common/utilities/timer.service';
import { AuthUsersService } from '../../common/utilities/auth-users.service';
import { LoaderService } from '../../common/loader/loader.service';
import { UserSignInOtpVerificationService } from './user-sign-in-otp-verification.service';
import { getFormValidators } from './form-utils';

@Component({
  selector: 'app-user-sign-in-otp-verification',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    NumericInputDirective
  ],
  templateUrl: './user-sign-in-otp-verification.component.html'
})
export class UserSignInOtpVerificationComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesUsers = ROUTES_USERS;

  mobileNumber: string | null = '';
  timeDifference: number = 0;
  startTime: Date | null = null;

  timerValue: string = '00:00';
  resendButtonDisabled: boolean = false;

  isLoading = false;

  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};

  private apiSubmitFormDataSubscription: Subscription | undefined;
  private apiResendOtpSubscription: Subscription | undefined;
  private timerSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private momentUtilitiesService: MomentUtilitiesService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private timerService: TimerService,
    private authUsersService: AuthUsersService,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    private userSignInOtpVerificationService: UserSignInOtpVerificationService
  ) {
    const state = this.cookieStorageService.getCookie(this.globalConstant?.cookie?.otp_verification_data);
    this.mobileNumber = state?.mobileNumber;
    this.timeDifference = state?.timeDifference;
    this.startTime = state?.startTime;
    this.customForm = getFormValidators(this.mobileNumber);
  }

  ngOnInit(): void {
    if (this.startTime) {
      this.startTimer(this.startTime, this.timeDifference, 'seconds');
    }
  }

  startTimer(startTime: Date, durationTime: number, type: 'hours' | 'minutes' | 'seconds') {
    this.timerSubscription = this.timerService.startTimer(startTime, durationTime, type, false).subscribe(
      (remainingTime) => {
        this.timerValue = remainingTime;
        this.resendButtonDisabled = true;
        if (remainingTime === '00:00' || remainingTime === '00:00:00') {
          this.resendButtonDisabled = false;
        }
      }
    );
  }

  resendOtp(): void {
    let mobile_number = this.customForm.value.mobile_number;
    if (mobile_number == '') {
      this.isLoading = false;
      this.loaderService.hide();
      this.router.navigate([this.globalConstant?.base_url, ROUTES_USERS?.USER, ROUTES_USERS?.USER_SIGN_IN]);
    }
    const data = {
      mobile_number: mobile_number
    };
    this.apiResendOtpSubscription = this.userSignInOtpVerificationService.resendOTP(data).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data?.time_difference && response?.data?.time_difference > 0) {
            this.timeDifference = response?.data?.time_difference;
            this.startTime = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();
            this.startTimer(this.startTime, this.timeDifference, 'seconds');
            const stateData = {
              mobileNumber: mobile_number,
              timeDifference: this.timeDifference,
              startTime: this.momentUtilitiesService.getCurrentDateTimeAsDateTime()
            }
            this.cookieStorageService.setCookie(this.globalConstant?.cookie?.otp_verification_data, stateData, this.globalConstant?.cookie?.otp_verification_data_expire, 'minutes');
          }
        },
        (error) => {
          if (error?.error?.data?.time_difference && error?.error?.data?.time_difference > 0) {
            this.timeDifference = error?.error?.data?.time_difference;
            this.startTime = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();
            this.startTimer(this.startTime, this.timeDifference, 'seconds');
          }
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  onOtpInput(currentInput: number, event: Event): void {
    const input = event.target as HTMLInputElement;
    const nextInput = currentInput + 1;
    const prevInput = currentInput - 1;
    input.value = input.value.replace(/\D/g, '');
    if (input.value.length === 1 && nextInput <= 4) {
      document.getElementById(`otp${nextInput}`)?.focus();
    } else if (input.value.length === 0 && prevInput > 0) {
      document.getElementById(`otp${prevInput}`)?.focus();
    }
  }

  onKeyDown(currentInput: number, event: KeyboardEvent): void {
    const nextInput = currentInput + 1;
    const prevInput = currentInput - 1;

    if (event.key === 'ArrowRight') {
      if (nextInput <= 4) {
        document.getElementById(`otp${nextInput}`)?.focus();
      }
      event.preventDefault();
    } else if (event.key === 'ArrowLeft') {
      if (prevInput > 0) {
        document.getElementById(`otp${prevInput}`)?.focus();
      }
      event.preventDefault();
    } else if (event.key === 'Backspace') {
      if (event.target && (event.target as HTMLInputElement).value.length === 0 && prevInput > 0) {
        document.getElementById(`otp${prevInput}`)?.focus();
      }
    } else if (event.key === 'Delete') {
      if (event.target && (event.target as HTMLInputElement).value.length === 1 && nextInput <= 4) {
        document.getElementById(`otp${nextInput}`)?.focus();
      }
    }
  }

  onSubmit() {
    const otp = `${this.customForm.value.otp1}${this.customForm.value.otp2}${this.customForm.value.otp3}${this.customForm.value.otp4}`;
    let mobile_number = this.customForm.value.mobile_number;
    if (mobile_number == '') {
      this.isLoading = false;
      this.loaderService.hide();
      this.router.navigate([this.globalConstant?.base_url, ROUTES_USERS?.USER, ROUTES_USERS?.USER_SIGN_IN]);
    }
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const data = {
        mobile_number: mobile_number,
        otp: otp
      };

      this.apiSubmitFormDataSubscription = this.userSignInOtpVerificationService.postData(data).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            this.cookieStorageService.deleteCookie(this.globalConstant?.cookie?.otp_verification_data);
            if (response?.data?.access_token) {
              this.localStorageService.setItem(this.globalConstant?.session?.auth_token, response?.data?.access_token)
            }
            this.authUsersService.setIsLogin();
            const serviceState = this.cookieStorageService.getCookie(this.globalConstant?.cookie?.service_login_data);
            if (serviceState?.service_id && serviceState.service_type == this.globalConstant.service_type_match.offline) {
              const getURL = this.utilitiesService.getOffLineServiceURL(serviceState?.service_id, 'step_1');
              this.router.navigate(getURL);
            } else {
              this.router.navigate([this.globalConstant?.base_url, this.routesUsers?.USER_DASHBOARD || '']);
            }
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
    if (this.apiResendOtpSubscription) {
      this.apiResendOtpSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

}
