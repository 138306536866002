<section class="sec pt-4 about_bg_img d-flex align-items-center border-bottom">
  <div class="container position-relative">
    <div class=" text-white">
      <h1 class="fs-5 fw-bold text-green">Telsysweb Insurance</h1>
      <p class="display-5 fw-bold text-dark">India's Best Insurance <br>Company</p>
    </div>
  </div>
</section>
<section class="bg-light">
  <div class="container py-4 px-3">
    <p class="fw-bold fs-2 text-center">About Us</p>
    <p>Telsysweb Insurance is dedicated to providing financial security to individuals, families, and businesses across
      the nation. With a steadfast commitment to excellence and integrity, we have proudly served our clients with
      trusted insurance solutions for over 1 year. We offer a diverse range of coverage options tailored to fit your
      unique needs, whether you're safeguarding your home, car, bike, business assets, or loved ones.</p>
    <p>We prioritize personalized service and aim to build lasting relationships with our clients. Our team of
      experienced agents is committed to delivering reliable guidance and support, ensuring you have the protection and
      peace of mind you deserve.</p>
    <p>Beyond insurance policies, we are actively engaged in giving back to the communities we serve. Through
      sponsorships, volunteer efforts, and local partnerships, we strive to make a positive impact and contribute to the
      well-being of our neighborhoods.</p>
  </div>
</section>
<section class="bg_slate">
  <div class="container py-4 px-3">
    <div class="row">
      <div class="col-sm-12 col-lg-8 col-xl-8">
        <p class="fs-2 fw-bold"><span class="text-green">Our</span> Mission</p>
        <ul class="ins-nav mt-4">
          <li>Founded on principles of trust and dedication, Telsysweb Insurance has been a stalwart in the insurance
            industry, serving individuals, families, and businesses nationwide.</li>
          <li>We have earned a reputation for delivering reliable and customized insurance solutions.</li>
          <li>Our mission is clear: to empower our clients by providing comprehensive coverage that adapts to their
            unique needs and circumstances.</li>
          <li>Beyond insurance, we are deeply committed to enriching the communities we serve through philanthropic
            endeavors and local partnerships.</li>
          <li>We believe in building enduring relationships based on transparency, empathy, and a commitment to
            excellence.</li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-4 col-xl-4 text-center my-auto">
        <img src="images/pages/safe-insurance.png" alt="telsyswebinsurance" width="300" height="300">
      </div>
    </div>
  </div>
</section>
<section class="bg-light contact_us_page">
  <div class="container py-4 px-3">
    <p class="fs-2 fw-bold text-center mb-4"><span class="text-green">Our</span> Core Values</p>
    <div class="row">
      <div class="col-sm-12 col-lg-4 col-xl-4 mb-3 sidebarrow ">
        <div class="bg_one p-3 border rounded h-100 shadow-sm ">
          <img src="images/pages/honesty.png" alt="Telsysweb Insurance" height="50" width="50" />
          <p class="fs-4 fw-bold mb-0">Integrity</p>
          <p class="mb-0">We believe in doing the right thing, even when no one is watching. Honesty, transparency, and
            ethical behavior are non-negotiables for us.</p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4 col-xl-4 mb-3">
        <div class="bg_two p-3 border rounded h-100 shadow-sm">
          <img src="images/pages/invation.png" alt="Telsysweb Insurance" height="50" width="50" />
          <p class="fs-4 fw-bold mb-0">Innovation</p>
          <p>We're not satisfied with the status quo. We're constantly pushing the boundaries, exploring new ideas, and
            challenging ourselves to think differently.</p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4 col-xl-4 mb-3">
        <div class="bg_three p-3 border rounded h-100 shadow-sm">
          <img src="images/pages/excellent.png" alt="Telsysweb Insurance" height="50" width="50" />
          <p class="fs-4 fw-bold mb-0">Excellence</p>
          <p>We strive for excellence in everything we do. From the products we create to the services we provide, we're
            committed to delivering nothing but the best.</p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4 col-xl-4 mb-3">
        <div class="bg_four p-3 border rounded h-100 shadow-sm">
          <img src="images/pages/customer-focus.png" alt="Telsysweb Insurance" height="50" width="50" />
          <p class="fs-4 fw-bold mb-0">Customer Focus</p>
          <p>Our customers are at the center of everything we do. We're committed to understanding their needs,
            exceeding their expectations, and delivering exceptional value.</p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4 col-xl-4 mb-3">
        <div class="bg_five p-3 border rounded h-100 shadow-sm">
          <img src="images/pages/unity.png" alt="Telsysweb Insurance" height="50" width="50" />
          <p class="fs-4 fw-bold mb-0">Teamwork</p>
          <p>We believe in the power of collaboration. We work together, support each other, and celebrate our
            collective successes.</p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4 col-xl-4 mb-3">
        <div class="bg_six p-3 border rounded h-100 shadow-sm">
          <img src="images/pages/accountability.png" alt="Telsysweb Insurance" height="50" width="50" />
          <p class="fs-4 fw-bold mb-0">Accountability</p>
          <p>We take responsibility for our actions and decisions, ensuring trust and credibility with our customers.</p>
        </div>
      </div>
    </div>
  </div>
</section>