import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_PAGES } from '../../constants/routes/pages/pages.constants';
import { AboutUsComponent } from '../../../pages/about-us/about-us.component';
import { ContactUsComponent } from '../../../pages/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from '../../../pages/terms-and-conditions/terms-and-conditions.component';
import { PospTermsAndConditionsComponent } from '../../../pages/posp-terms-and-conditions/posp-terms-and-conditions.component';
import { CareerComponent } from '../../../pages/career/career.component';
import { FaqsComponent } from '../../../pages/faqs/faqs.component';
import { PrivacyPolicyComponent } from '../../../pages/privacy-policy/privacy-policy.component';
import { RefundAndCancellationComponent } from '../../../pages/refund-and-cancellation/refund-and-cancellation.component';

export const PagesRoutes: Routes = [
    {
        path: ROUTES_PAGES.ABOUT_US,
        component: AboutUsComponent,
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_PAGES.CONTACT_US,
        component: ContactUsComponent,
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_PAGES.TERMS_AND_CONDITIONS,
        component: TermsAndConditionsComponent,
        data: { footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_PAGES.POSP_TERMS_AND_CONDITIONS,
        component: PospTermsAndConditionsComponent,
        data: { footerLayoutType: 'remove-footer' }
    },
    {
        path: ROUTES_PAGES.CAREER,
        component: CareerComponent,
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_PAGES.FAQ,
        component: FaqsComponent,
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_PAGES.PRIVACY_POLICY,
        component: PrivacyPolicyComponent,
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_PAGES.REFUND_AND_CANCELLATION,
        component: RefundAndCancellationComponent,
        canActivate: [checkUsersGuard]
    }
];