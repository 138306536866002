import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_OFFLINE_LIC_PLAN } from '../../constants/routes/offline-services/offline-lic-plan.constants';
import { OfflineLicPlanStepOneComponent } from '../../../offline-services/lic-plan/offline-lic-plan-step-one/offline-lic-plan-step-one.component';
import { OfflineLicPlanStepTwoComponent } from '../../../offline-services/lic-plan/offline-lic-plan-step-two/offline-lic-plan-step-two.component';
import { OfflineLicPlanOrdersComponent } from '../../../offline-services-orders/lic-plan/offline-lic-plan-orders/offline-lic-plan-orders.component';
import { OfflineLicPlanOrderDetailComponent } from '../../../offline-services-orders/lic-plan/offline-lic-plan-order-detail/offline-lic-plan-order-detail.component';

export const OfflineLicPlanRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_LIC_PLAN.OFFLINE_STEP_ONE,
        component: OfflineLicPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_LIC_PLAN.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineLicPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_LIC_PLAN.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineLicPlanStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_LIC_PLAN.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineLicPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_LIC_PLAN.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineLicPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_LIC_PLAN.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineLicPlanOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];