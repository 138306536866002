import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { switchMap, catchError } from 'rxjs/operators';

import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { LocalStorageService } from '../utilities/local-storage.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { AuthUsersService } from '../utilities/auth-users.service';
import { navigatePopsStatus } from '../utilities/navigate-posp-status';

import { storeSetUserProfileGuard } from '../../store/actions/user-profile-guard.actions';
import { StoreStateUserProfileGuard } from '../../store/reducers/user-profile-guard.reducers';
import { selectStoreUserProfileGuard } from '../../store/selectors/user-profile-guard.selectors';

export const checkUsersGuard: CanActivateFn = (route, state) => {
  const localStorageService = inject(LocalStorageService);
  const utilitiesService = inject(UtilitiesService);
  const authUsersService = inject(AuthUsersService);
  const router = inject(Router);
  const store = inject(Store<StoreStateUserProfileGuard>);

  if (!localStorageService.isLoggedIn(GLOBAL_CONSTANT?.session?.auth_token)) {
    return of(true);
  }

  return store.pipe(
    select(selectStoreUserProfileGuard),
    switchMap(userProfileGuard => {
      if (userProfileGuard) {
        if (userProfileGuard?.['user_type'] === GLOBAL_CONSTANT?.user_type?.posp) {
          const navigatedRoute = navigatePopsStatus(userProfileGuard);
          if (navigatedRoute?.status && utilitiesService?.objectLength(navigatedRoute?.route)) {
            router.navigate(navigatedRoute.route as string[]);
            return of(false);
          }
        }
        return of(true);
      } else {
        return authUsersService.getProfileProcessAllUsersData().pipe(
          switchMap(response => {
            if (response?.data && utilitiesService.objectLength(response?.data) > 0) {
              let userData = response?.data;
              if (userData?.user_type === GLOBAL_CONSTANT?.user_type?.posp) {
                const navigatedRoute = navigatePopsStatus(userData);
                if (navigatedRoute?.status && utilitiesService?.objectLength(navigatedRoute?.route)) {
                  router.navigate(navigatedRoute.route as string[]);
                  return of(false);
                }
                store.dispatch(storeSetUserProfileGuard({ storeData: response.data }));
                return of(true);
              }
              store.dispatch(storeSetUserProfileGuard({ storeData: response.data }));
              return of(true);
            }
            authUsersService.logoutUsers();
            return of(true);
          }),
          catchError(error => {
            authUsersService.logoutUsers();
            return of(true);
          })
        );
      }
    })
  );
};
