import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        course: new FormControl(GLOBAL_CONSTANT.defaultTrainingCourse, [
            Validators.required
        ])
    });
}
