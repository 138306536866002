import { FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function trimValidator(control: FormControl) {
    if (control.value && typeof control.value === 'string') {
        const trimmedValue = control.value.trim();
        control.patchValue(trimmedValue, { emitEvent: false });
    }
    return null;
}

export function panCardValidator(control: AbstractControl): ValidationErrors | null {
    const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    if (!control.value) {
        return null;
    }
    const valid = panRegex.test(control.value);
    return valid ? null : { invalidPAN: true };
}

export function aadharCardValidator(control: AbstractControl): ValidationErrors | null {
    const aadharRegex = /^\d{12}$/;
    if (!control.value) {
        return null;
    }
    const valid = aadharRegex.test(control.value);
    return valid ? null : { invalidAadhar: true };
}

export function ifscCodeValidator(control: AbstractControl): ValidationErrors | null {
    const ifscRegex = /^[A-Za-z]{4}[0][A-Za-z0-9]{6}$/
    if (!control.value) {
        return null;
    }
    const valid = ifscRegex.test(control.value);
    return valid ? null : { invalidIFSC: true };
}

export function confirmationValidator(controlName: string, confirmControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const mainControl = control.get(controlName);
        const confirmControl = control.get(confirmControlName);
        if (!mainControl || !confirmControl) {
            return null;
        }
        if (!confirmControl.value) {
            return null;
        }
        if (mainControl.value === confirmControl.value) {
            confirmControl.setErrors(null);
        } else {
            confirmControl.setErrors({ mismatch: true });
        }
        return null;
    };
}

export function vehicleNumberValidator(control: AbstractControl): ValidationErrors | null {
    const vehicleNumberRegex = /^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/;
    if (!control.value) {
        return null; 
    }
    const isValid = vehicleNumberRegex.test(control.value);
    return isValid ? null : { invalidVehicleNumber: true };
}

export function handleFileSelection(event: Event, allowedExtensions: string[], maxFileSize: number): { status: boolean, file: File | null, error: object | null } {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];

        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.split('.').pop();
        if (!fileExtension || allowedExtensions.indexOf(fileExtension) === -1) {
            return {
                status: false,
                file: null,
                error: { fileType: `${allowedExtensions.join(', ')}` }
            };
        }

        if (file.size > maxFileSize) {
            return {
                status: false,
                file: null,
                error: { fileSize: `${maxFileSize / (1024 * 1024)}` }
            };
        }

        return {
            status: true,
            file,
            error: null
        };
    }
    return {
        status: false,
        file: null,
        error: null
    };
}

export function handleReadFile(file: File, callback: (result: string) => void): void {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
        if (file.type && file.type === 'application/pdf') {
            const result = 'images/icons/pdf.png';
            callback(result);
        } else {
            const result = e.target?.result as string;
            callback(result);
        }
    };
    reader.readAsDataURL(file);
}
