import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_OFFLINE_INVESTMENT_PLAN } from '../../constants/routes/offline-services/offline-investment-plan.constants';
import { OfflineInvestmentPlanStepOneComponent } from '../../../offline-services/investment-plan/offline-investment-plan-step-one/offline-investment-plan-step-one.component';
import { OfflineInvestmentPlanStepTwoComponent } from '../../../offline-services/investment-plan/offline-investment-plan-step-two/offline-investment-plan-step-two.component';
import { OfflineInvestmentPlanOrdersComponent } from '../../../offline-services-orders/investment-plan/offline-investment-plan-orders/offline-investment-plan-orders.component';
import { OfflineInvestmentPlanOrderDetailComponent } from '../../../offline-services-orders/investment-plan/offline-investment-plan-order-detail/offline-investment-plan-order-detail.component';

export const OfflineInvestmentPlanRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_STEP_ONE,
        component: OfflineInvestmentPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineInvestmentPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineInvestmentPlanStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineInvestmentPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineInvestmentPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_INVESTMENT_PLAN.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineInvestmentPlanOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];