import { Component } from '@angular/core';

@Component({
  selector: 'app-online-two-wheeler-insurance-payment',
  standalone: true,
  imports: [],
  templateUrl: './online-two-wheeler-insurance-payment.component.html'
})
export class OnlineTwoWheelerInsurancePaymentComponent {

}
