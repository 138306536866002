import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';

export function getFormValidators(): FormGroup {
    const globalConstant = GLOBAL_CONSTANT;
    return new FormGroup({
        kyc_step: new FormControl(globalConstant?.posp_kyc_step_match?.education_details || 'education_details'),
        educational_qualification: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        already_posp: new FormControl('no', [
            Validators.maxLength(50)
        ]),
        educational_certificate_doc: new FormControl('', [
            Validators.required
        ])
    });
}
