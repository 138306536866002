import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_ONLINE_CAR_INSURANCE } from '../../constants/routes/online-services/online-car-insurance.constants';

import { OnlineCarInsuranceBasicDetailsComponent } from '../../../online-services/car-insurance/online-car-insurance-basic-details/online-car-insurance-basic-details.component';
import { OnlineCarInsuranceChoosePlanComponent } from '../../../online-services/car-insurance/online-car-insurance-choose-plan/online-car-insurance-choose-plan.component';
import { OnlineCarInsurancePersonalDetailsComponent } from '../../../online-services/car-insurance/online-car-insurance-personal-details/online-car-insurance-personal-details.component';
import { OnlineCarInsurancePaymentComponent } from '../../../online-services/car-insurance/online-car-insurance-payment/online-car-insurance-payment.component';

export const OnlineCarInsuranceRoutes: Routes = [
    {
        path: ROUTES_ONLINE_CAR_INSURANCE.BASIC_DETAILS,
        component: OnlineCarInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_CAR_INSURANCE.CHOOSE_PLAN,
        component: OnlineCarInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_CAR_INSURANCE.CHOOSE_PLAN + '/:uuid',
        component: OnlineCarInsuranceChoosePlanComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_CAR_INSURANCE.PERSONAL_DETAILS,
        component: OnlineCarInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_CAR_INSURANCE.PERSONAL_DETAILS + '/:uuid',
        component: OnlineCarInsurancePersonalDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_CAR_INSURANCE.PAYMENT,
        component: OnlineCarInsuranceBasicDetailsComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_ONLINE_CAR_INSURANCE.PAYMENT + '/:uuid',
        component: OnlineCarInsurancePaymentComponent,
        data: { headerLayoutType: 'public-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    }
];