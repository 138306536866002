import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';
import { RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ROUTES_POSP_TRAINING } from '../../../common/constants/routes/posp/posp-training.constants';
import { ROUTES_POSP_EXAM } from '../../../common/constants/routes/posp/posp-exam.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { LoaderService } from '../../../common/loader/loader.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { AuthUsersService } from '../../../common/utilities/auth-users.service';
import { navigatePopsError } from '../../../common/utilities/navigate-posp-status';
import { PospTrainingChapterService } from './posp-training-chapter.service';

@Component({
  selector: 'app-posp-training-chapter',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './posp-training-chapter.component.html'
})
export class PospTrainingChapterComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  routesPospTraining = ROUTES_POSP_TRAINING;
  routesPospExam = ROUTES_POSP_EXAM;
  apiResponse = API_RESPONSE;

  chapterId: number = 1;
  trainingData: any = {};
  isLoading = false;
  public chapterContent: any[] = [];

  private apiPospTrainingChapterSubscription: Subscription | undefined;
  private apiPospTrainingReadChapterSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private authUsersService: AuthUsersService,
    private pospTrainingChapterService: PospTrainingChapterService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const id = params.get('chapterId');
      this.chapterId = id !== null ? +id : 1;
    });
    this.getChapter({ chapter: this.chapterId });
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  nextChapter(): void {
    this.readChapter({ chapter: this.chapterId });
  }

  previousChapter(): void {
    let getChapterId = this.chapterId;
    if (getChapterId > 1) {
      this.getChapter({ chapter: this.chapterId - 1 });
      this.router.navigate([this.globalConstant.base_url, this.routesPospTraining.POSP_TRAINING || '', this.routesPospTraining.POSP_TRAINING_CHAPTER || '', this.chapterId - 1 || 1]).then(() => {
        this.viewportScroller.scrollToPosition([0, 0]);
      });
    }
  }

  getChapter(formData: any): void {
    this.loaderService.show();
    this.apiPospTrainingChapterSubscription = this.pospTrainingChapterService.getChapter(formData).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.loaderService.hide();
          if (response?.data?.training_data && this.objectLength(response?.data?.training_data) > 0) {
            this.trainingData = response?.data?.training_data;
          }
          this.chapterContent = response?.data?.chapter_content && this.arrayLength(response?.data?.chapter_content) > 0 ? response?.data?.chapter_content : [];
        },
        (error) => {
          const error_code = error?.error?.res_code;
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
          const navigatedRoute = navigatePopsError(error);
          if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
            this.router.navigate(navigatedRoute.route as string[]);
          }
          if (error_code == this.apiResponse?.status_codes?.bad_request) {
            this.router.navigate([GLOBAL_CONSTANT?.base_url, ROUTES_POSP_TRAINING?.POSP_TRAINING]);
          }
        }
      )
    );
  }

  readChapter(formData: any): void {
    this.isLoading = true;
    this.loaderService.show();
    this.apiPospTrainingReadChapterSubscription = this.pospTrainingChapterService.readChapter(formData).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.isLoading = false;
          this.loaderService.hide();
          this.chapterContent = response?.data?.chapter_content && this.arrayLength(response?.data?.chapter_content) > 0 ? response?.data?.chapter_content : [];
          if (response?.data?.training_data && this.objectLength(response?.data?.training_data) > 0) {
            if (response?.data?.training_data?.total_chapter == formData?.chapter) {
              this.authUsersService.resetUserProfileStore();
              this.router.navigate([this.globalConstant.base_url, this.routesPospExam.POSP_EXAM || '']);
            } else {
              this.router.navigate([this.globalConstant.base_url, this.routesPospTraining.POSP_TRAINING || '', this.routesPospTraining.POSP_TRAINING_CHAPTER || '', formData?.chapter + 1 || 1]).then(() => {
                this.viewportScroller.scrollToPosition([0, 0]);
              });
            }
          }
        },
        (error) => {
          this.isLoading = false;
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
          const navigatedRoute = navigatePopsError(error);
          if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
            this.router.navigate(navigatedRoute.route as string[]);
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiPospTrainingChapterSubscription) {
      this.apiPospTrainingChapterSubscription.unsubscribe();
    }
    if (this.apiPospTrainingReadChapterSubscription) {
      this.apiPospTrainingReadChapterSubscription.unsubscribe();
    }
  }

}
