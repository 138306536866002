import { Routes } from '@angular/router';
import { pospProcessGuard } from '../../guards/posp-process.guard';
import { ROUTES_POSP_KYC } from '../../constants/routes/posp/posp-kyc.constants';
import { PospKycComponent } from '../../../posp/posp-kyc/posp-kyc.component';
import { PospKycBasicDetailsComponent } from '../../../posp/posp-kyc/posp-kyc-basic-details/posp-kyc-basic-details.component';
import { PospKycPanAadharDetailsComponent } from '../../../posp/posp-kyc/posp-kyc-pan-aadhar-details/posp-kyc-pan-aadhar-details.component';
import { PospKycEducationDetailsComponent } from '../../../posp/posp-kyc/posp-kyc-education-details/posp-kyc-education-details.component';
import { PospKycBankDetailsComponent } from '../../../posp/posp-kyc/posp-kyc-bank-details/posp-kyc-bank-details.component';

export const PospKycRoutes: Routes = [
    {
        path: ROUTES_POSP_KYC.POSP_KYC,
        data: { headerLayoutType: 'private-layout', footerLayoutType: 'remove-footer' },
        children: [
            {
                path: '',
                component: PospKycComponent,
                canActivate: [pospProcessGuard]
            },
            {
                path: ROUTES_POSP_KYC.POSP_KYC_BASIC_DETAILS,
                component: PospKycBasicDetailsComponent,
                canActivate: [pospProcessGuard]
            },
            {
                path: ROUTES_POSP_KYC.POSP_KYC_PAN_AADHAR_DETAILS,
                component: PospKycPanAadharDetailsComponent,
                canActivate: [pospProcessGuard]
            },
            {
                path: ROUTES_POSP_KYC.POSP_KYC_EDUCATION_DETAILS,
                component: PospKycEducationDetailsComponent,
                canActivate: [pospProcessGuard]
            },
            {
                path: ROUTES_POSP_KYC.POSP_KYC_BANK_DETAILS,
                component: PospKycBankDetailsComponent,
                canActivate: [pospProcessGuard]
            }
        ]
    }
];