import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar-footer',
  standalone: true,
  imports: [],
  templateUrl: './sidebar-footer.component.html'
})
export class SidebarFooterComponent {
  constructor(
  ) { }
}
