<section class="sec pt-4">
    <div class="container contact_us_bg p-4">
        <div class="text-center py-3">
            <h1 class="fs-3 fw-semibold">Support Center for All Your Insurance Queries</h1>
            <p class="fw-semibold">Let us know how we can help?</p>
            <span class="bg-success py-2 px-3 text-white rounded fw-semibold shadow-sm">Call Us or Visit Help
                Center</span>
        </div>
    </div>
</section>
<section>
    <div class="container pt-5">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-0 mt-md-4">
                <a href="tel:+917413827827" target="_blank" class="text-black">
                    <div class="contact_us_section">
                        <img class="loaderImg ms-1" src="images/pages/call-us.png" alt="Telsysweb Insurance" width="40"
                            height="40">
                        <p class="fs-5 box_heading_content pt-2 mb-0">Call</p>
                        <p><strong>+91 7413827827</strong></p>
                        <span class="text-black"> Quick Response </span>
                    </div>
                </a>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4">
                <a href="mailto:support&#64;telsyswebinsurance.com" target="_blank" class="text-black">
                    <div class="contact_us_section">
                        <img class="loaderImg ms-1" src="images/pages/support-email.png" alt="Telsysweb Insurance"
                            width="40" height="40">
                        <p class="fs-5 pt-2 mb-0 box_heading_content">Email Supports</p>
                        <p><strong>support&#64;telsyswebinsurance.com</strong></p>
                        <span class="text-black">Response Time: 12 hrs</span>
                    </div>
                </a>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4">
                <a href="https://wa.me/7413827827" target="_blank" class="text-black">
                    <div class="contact_us_section">
                        <img class="loaderImg ms-1" src="images/pages/whatsapp.png" alt="Telsysweb Insurance" width="40"
                            height="40">
                        <p class="fs-5 box_heading_content pt-2 mb-0">WhatsApp</p>
                        <p><strong>+91 7413 826826</strong></p>
                        <span class="text-black">Response Time: 12 hrs</span>
                    </div>
                </a>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4">
                <a href="tel:+917413827827" target="_blank" class="text-black">
                    <div class="contact_us_section">
                        <img class="loaderImg ms-1" src="images/pages/address-office.png" alt="Telsysweb Insurance"
                            width="40" height="40">
                        <p class="fs-5 box_heading_content pt-2 mb-0">Address</p>
                        <span class="text-black">IInd Floor, Web Plaza, Shyam Nagar, Nadi ka Phatak, Benar Road,
                            Jhotwara, Jaipur, Rajasthan 302012</span>
                    </div>
                </a>
            </div>
        </div>
    </div>

</section>