import { Injectable } from '@angular/core';
import { ApiService } from '../../common/utilities/api.service';


@Injectable({
  providedIn: 'root'
})
export class PospProfileService {

  constructor(
    private apiService: ApiService
  ) { }

  getPospData() {
    return this.apiService.request<any>('GET_AUTH', 'posp/profile');
  }
}
