import { Routes } from '@angular/router';
import { authDisableGuard } from '../../guards/auth-disable.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_POSP } from '../../constants/routes/posp/posp.constants';
import { PospSignInComponent } from '../../../posp/posp-sign-in/posp-sign-in.component';
import { PospSignInOtpVerificationComponent } from '../../../posp/posp-sign-in-otp-verification/posp-sign-in-otp-verification.component';
import { PospSignUpComponent } from '../../../posp/posp-sign-up/posp-sign-up.component';
import { PospSignUpOtpVerificationComponent } from '../../../posp/posp-sign-up-otp-verification/posp-sign-up-otp-verification.component';
import { PospProfileComponent } from '../../../posp/posp-profile/posp-profile.component';
import { PospExamScoreComponent } from '../../../posp/posp-exam-score/posp-exam-score.component';
import { OfflineCommissionComponent } from '../../../commission/offline-commission/offline-commission.component';

export const PospRoutes: Routes = [
    {
        path: ROUTES_POSP.POS,
        component: PospSignUpComponent,
        canActivate: [authDisableGuard],
        data: { headerLayoutType: 'private-layout', footerLayoutType: 'public-layout' }
    },
    {
        path: ROUTES_POSP.POSP,
        data: { headerLayoutType: 'private-layout', footerLayoutType: 'public-layout' },
        children: [
            {
                path: '',
                component: PospSignUpComponent,
                canActivate: [authDisableGuard]
            },
            {
                path: ROUTES_POSP.POSP_SIGN_IN,
                component: PospSignInComponent,
                canActivate: [authDisableGuard]
            },
            {
                path: ROUTES_POSP.POSP_SIGN_IN_OTP_VERIFICATION,
                component: PospSignInOtpVerificationComponent,
                canActivate: [authDisableGuard]
            },
            {
                path: ROUTES_POSP.POSP_SIGN_UP,
                component: PospSignUpComponent,
                canActivate: [authDisableGuard]
            },
            {
                path: ROUTES_POSP.POSP_SIGN_UP_OTP_VERIFICATION,
                component: PospSignUpOtpVerificationComponent,
                canActivate: [authDisableGuard]
            },
        ]
    },
    {
        path: ROUTES_POSP.POSP,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        children: [
            {
                path: ROUTES_POSP.POSP_PROFILE,
                component: PospProfileComponent,
                canActivate: [authUsersGuard]
            }
        ]
    },
    {
        path: ROUTES_POSP.POSP,
        data: { headerLayoutType: 'private-layout', footerLayoutType: 'remove-footer' },
        children: [
            {
                path: ROUTES_POSP.POSP_EXAM_SCORE,
                component: PospExamScoreComponent,
                canActivate: [authUsersGuard]
            }
        ]
    },
    {
        path: ROUTES_POSP.POSP,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        children: [
            {
                path: ROUTES_POSP.POSP_OFFLINE_COMMISSION,
                component: OfflineCommissionComponent,
                canActivate: [authUsersGuard]
            }
        ]
    },
];