import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieStorageService {

  constructor(private cookieService: CookieService) { }

  setCookie(key: string, value: any, time?: number, timeUnit?: 'minutes' | 'hours' | 'days', path: string = '/') {
    try {
      let expirationDate: Date | undefined;
      if (time && timeUnit) {
        const now = new Date();
        switch (timeUnit) {
          case 'minutes':
            expirationDate = new Date(now.getTime() + time * 60 * 1000);
            break;
          case 'hours':
            expirationDate = new Date(now.getTime() + time * 60 * 60 * 1000);
            break;
          case 'days':
            expirationDate = new Date(now.getTime() + time * 24 * 60 * 60 * 1000);
            break;
        }
      }
      const existingValue = this.getCookie(key);
      if (existingValue !== null) {
        this.updateCookie(key, value, expirationDate, path);
      } else {
        this.cookieService.set(key, JSON.stringify(value), expirationDate, path);
      }
    } catch (error) {
    }
  }

  private updateCookie(key: string, value: any, expirationDate?: Date, path: string = '/') {
    try {
      this.cookieService.delete(key, path);
      this.cookieService.set(key, JSON.stringify(value), expirationDate, path);
    } catch (error) {
    }
  }

  getCookie(key: string) {
    try {
      const item = this.cookieService.get(key);
      if (item) {
        return JSON.parse(item);
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  deleteCookie(key: string, path: string = '/') {
    try {
      this.cookieService.delete(key, path);
    } catch (error) {
    }
  }

  clearCookies(path: string = '/') {
    try {
      this.cookieService.deleteAll(path);
    } catch (error) {
    }
  }

}
