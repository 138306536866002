import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class StoreUtilitiesService {

    constructor() { }

    processStore<T>(observable$: Observable<T>, apiCall: () => Promise<void>, setStoreData: (data: T) => void): void {
        observable$.pipe(
            take(1)
        ).subscribe({
            next: async (data: T) => {
                if (!data) {
                    try {
                        await apiCall();
                    } catch (error) {
                    } finally {
                    }
                } else {
                    setStoreData(data);
                }
            },
            error: (error) => {
            }
        });
    }
}
