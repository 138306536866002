import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class PospSelectCourseService {

  constructor(
    private apiService: ApiService
  ) { }

  getCourse() {
    return this.apiService.request<any>('GET_AUTH', 'posp/training/course');
  }

  postCourse(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'posp/training/select-course', formData);
  }
}
