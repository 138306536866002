import { Injectable } from '@angular/core';
import { timer, Observable } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor() { }

  startTimer(startTime: Date, durationTime: number, type: 'hours' | 'minutes' | 'seconds', showHour: boolean = true): Observable<string> {
    startTime = new Date(startTime);
    if (!(startTime instanceof Date)) {
      console.log('startTime should be a Date object.');
    }

    const durationInSeconds = this.convertToSeconds(durationTime, type);
    const endTime = new Date(startTime.getTime() + durationInSeconds * 1000);

    return timer(0, 1000).pipe(
      map(() => Math.floor((endTime.getTime() - new Date().getTime()) / 1000)),
      takeWhile(remaining => remaining >= 0),
      map(remaining => this.formatTime(remaining, showHour))
    );
  }

  private convertToSeconds(time: number, type: 'hours' | 'minutes' | 'seconds'): number {
    switch (type) {
      case 'hours':
        return time * 3600;
      case 'minutes':
        return time * 60;
      case 'seconds':
      default:
        return time;
    }
  }

  private formatTime(seconds: number, showHour: boolean): string {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    if (hrs > 0 || showHour) {
      return `${this.pad(hrs)}:${this.pad(mins)}:${this.pad(secs)}`;
    } else {
      return `${this.pad(mins)}:${this.pad(secs)}`;
    }
  }

  private pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

}
