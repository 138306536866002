import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ROUTES_POSP } from '../../../common/constants/routes/posp/posp.constants';
import { ROUTES_POSP_EXAM } from '../../../common/constants/routes/posp/posp-exam.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { LoaderService } from '../../../common/loader/loader.service';
import { DialogService } from '../../../common/dialog/dialog.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { TimerService } from '../../../common/utilities/timer.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { AuthUsersService } from '../../../common/utilities/auth-users.service';
import { navigatePopsError } from '../../../common/utilities/navigate-posp-status';
import { PospExamQuestionService } from './posp-exam-question.service';
import { getFormValidators } from './form-utils';

@Component({
  selector: 'app-posp-exam-question',
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule
  ],
  templateUrl: './posp-exam-question.component.html'
})
export class PospExamQuestionComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPosp = ROUTES_POSP;
  routesPospExam = ROUTES_POSP_EXAM;

  projectName = this.globalConstant?.projectName;

  timerValue: string = '00:00:00';
  isShowTimer = true;
  examStartDateTime: Date | null = null;
  examDuration: number = 0;

  chapterId: number = 1;

  isLoading = false;

  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};
  public examQuestionContent: any = {};
  private apiGetQuestionSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;
  private timerSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pospExamQuestionService: PospExamQuestionService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private utilitiesService: UtilitiesService,
    private timerService: TimerService,
    private authUsersService: AuthUsersService,
    private formUtilitiesService: FormUtilitiesService
  ) {
    this.customForm = getFormValidators();
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const id = params.get('chapterId');
      this.chapterId = id !== null ? +id : 1;
    });
    this.apiGetQuestion();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  startTimer(startTime: Date, durationTime: number, type: 'hours' | 'minutes' | 'seconds') {
    this.timerSubscription = this.timerService.startTimer(startTime, durationTime, type).subscribe(
      (remainingTime) => {
        this.timerValue = remainingTime;
        if (remainingTime === '00:00' || remainingTime === '00:00:00') {
          this.isShowTimer = false;
          this.timerValue = '00:00:00';
          const dialogRef = this.dialogService.openDialog({
            title: 'Exam Time Expired',
            content: 'Your exam time is expired.',
            button_label: 'Start Again Exam',
          });
          dialogRef.afterClosed().subscribe(result => {
            this.router.navigate([this.globalConstant?.base_url, this.routesPospExam?.POSP_EXAM]);
          });
        }
      }
    );
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const formData = {
        exam: this.customForm.value.exam,
        question: this.customForm.value.question,
        answer: this.customForm.value.answer
      };
      this.apiSubmitFormDataSubscription = this.pospExamQuestionService.submitQuestion(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.isLoading = false;
            this.loaderService.hide();
            this.serverErrors = {};
            if (getData?.is_done && getData?.is_done == this.globalConstant?.status_match?.yes) {
              this.customForm.patchValue({
                exam: '',
                question: '',
                answer: ''
              });
              if (getData?.is_exam_passs && getData?.is_exam_passs == this.globalConstant?.status_match?.yes) {
                this.authUsersService.resetUserProfileStore();
                this.router.navigate([this.globalConstant?.base_url, this.routesPosp?.POSP || '', this.routesPosp?.POSP_EXAM_SCORE || '']);
                return;
              } else {
                this.router.navigate([this.globalConstant?.base_url, this.routesPospExam?.POSP_EXAM]);
                return;
              }
            }
            this.customForm.patchValue({
              exam: getData?.exam_id || '',
              question: getData?.question?.id || '',
              answer: ''
            });

            this.examStartDateTime = getData?.exam_start_date_time;
            this.examDuration = getData?.exam_duration;
            if (this.examStartDateTime) {
              this.startTimer(this.examStartDateTime, this.examDuration, 'hours');
            }
            this.examQuestionContent = getData;
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  apiGetQuestion(): void {
    this.loaderService.show();
    this.apiGetQuestionSubscription = this.pospExamQuestionService.getQuestion().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.loaderService.hide();
          if (response?.data && this.objectLength(response?.data) > 0 && response?.data?.question && this.objectLength(response?.data?.question) > 0) {
            const getData = response?.data;
            this.customForm.patchValue({
              exam: getData?.exam_id || '',
              question: getData?.question?.id || '',
              answer: ''
            });
            this.examStartDateTime = getData?.exam_start_date_time;
            this.examDuration = getData?.exam_duration;
            if (this.examStartDateTime) {
              this.startTimer(this.examStartDateTime, this.examDuration, 'hours');
            }
            this.examQuestionContent = getData;
          }
        },
        (error) => {
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
          const navigatedRoute = navigatePopsError(error);
          if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
            this.router.navigate(navigatedRoute.route as string[]);
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiGetQuestionSubscription) {
      this.apiGetQuestionSubscription.unsubscribe();
    }
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
