import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ROUTES_USERS } from '../../../common/constants/routes/users/users.constants';
import { OFFLINE_SERVICES_ID } from '../../../common/constants/services/offline-services-url.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { NumericInputDirective } from '../../../common/directives/numeric-input.directive';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { LoaderService } from '../../../common/loader/loader.service';
import { LocalStorageService } from '../../../common/utilities/local-storage.service';
import { CookieStorageService } from '../../../common/utilities/cookie-storage.service';
import { AuthUsersService } from '../../../common/utilities/auth-users.service';
import { OfflineTaxiInsuranceStepOneService } from './offline-taxi-insurance-step-one.service';
import { getFormValidators } from './form-utils';

@Component({
  selector: 'app-offline-taxi-insurance-step-one',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    NumericInputDirective
  ],
  templateUrl: './offline-taxi-insurance-step-one.component.html'
})
export class OfflineTaxiInsuranceStepOneComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesUsers = ROUTES_USERS;
  offlineServicesID = OFFLINE_SERVICES_ID;
  projectName = this.globalConstant?.projectName;

  isLoading = false;
  uuId: string | null = null;
  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};

  private apiGetFormDataSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    private authUsersService: AuthUsersService,
    private offlineTaxiInsuranceStepOneService: OfflineTaxiInsuranceStepOneService
  ) {
    this.customForm = getFormValidators();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
    });
    const serviceState = this.cookieStorageService.getCookie(this.globalConstant?.cookie?.service_login_data);
    if (serviceState?.service_id && serviceState?.service_id == this.offlineServicesID.TAXI_INSURANCE) {
      this.customForm.patchValue({
        full_name: serviceState?.full_name || '',
        email: serviceState?.email || '',
        phone: serviceState?.phone || ''
      });
    }
    if (this.uuId != null && this.uuId != '') {
      this.apiGetFormData({ 'uuid': this.uuId });
    }
  }

  apiGetFormData(params: object) {
    this.apiGetFormDataSubscription = this.offlineTaxiInsuranceStepOneService.getFormData(params).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            let orderDetail = response?.data;
            this.customForm.patchValue({
              full_name: orderDetail?.full_name || '',
              phone: orderDetail?.phone || '',
              email: orderDetail?.email || ''
            });
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const formData = {
        uuid: this.uuId,
        service_step: this.customForm.value.service_step,
        full_name: this.customForm.value.full_name,
        email: this.customForm.value.email,
        phone: this.customForm.value.phone
      };
      if (this.localStorageService.isLoggedIn(GLOBAL_CONSTANT?.session?.auth_token)) {
        this.apiSubmitFormDataSubscription = this.offlineTaxiInsuranceStepOneService.postData(formData).subscribe(
          this.utilitiesService.handleSubscription(
            (response) => {
              this.serverErrors = {};
              this.isLoading = false;
              this.loaderService.hide();
              let orderDetail = response?.data;
              this.cookieStorageService.deleteCookie(this.globalConstant?.cookie?.service_login_data);
              let getRoute = this.utilitiesService.getOffLineServiceURL(this.offlineServicesID.TAXI_INSURANCE, 'step_2');
              if (orderDetail?.uuid && orderDetail?.uuid != '') {
                getRoute = [...getRoute, orderDetail?.uuid];
              }
              this.router.navigate(getRoute);
            },
            (error) => {
              this.isLoading = false;
              this.loaderService.hide();
              if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
                this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
              } else {
                this.serverErrors = {};
                this.utilitiesService.handleHttpError(error);
              }
            }
          )
        );
      } else {
        const formData = {
          uuid: this.uuId,
          service_step: this.customForm.value.service_step,
          full_name: this.customForm.value.full_name,
          email: this.customForm.value.email,
          phone: this.customForm.value.phone,
          service_type: this.globalConstant.service_type_match.offline,
          service_id: this.offlineServicesID.TAXI_INSURANCE,
          action_type: 'service_signin'
        };
        this.apiSubmitFormDataSubscription = this.authUsersService.getUserLogIn(formData).subscribe(
          (response) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (response?.status) {
              if (response?.loginData) {
                this.cookieStorageService.setCookie(this.globalConstant?.cookie?.otp_verification_data, response?.loginData, this.globalConstant?.cookie?.otp_verification_data_expire, 'minutes');
              }
              if (response?.serviceData) {
                this.cookieStorageService.setCookie(this.globalConstant?.cookie?.service_login_data, response?.serviceData, this.globalConstant?.cookie?.service_login_data_expire, 'minutes');
              }
              this.router.navigate(response.route);
            }
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            this.router.navigate([this.globalConstant?.base_url || '/', this.routesUsers?.USER || '/', this.routesUsers?.USER_SIGN_IN || '/']);
          }
        );
      }
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetFormDataSubscription) {
      this.apiGetFormDataSubscription.unsubscribe();
    }
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }

}
