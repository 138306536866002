import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { LocalStorageService } from '../utilities/local-storage.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const localStorageService = inject(LocalStorageService);
  const token = localStorageService.getItem(GLOBAL_CONSTANT?.session?.auth_token);
  let headers = {};
  if (token && token != '') {
    headers = {
      'Authorization': 'Bearer ' + token
    };
  }
  const clonedRequest = req.clone({ setHeaders: headers });
  return next(clonedRequest);
};
