@if(headerLayoutType == 'public-layout') {
    <app-public-header></app-public-header>
} @else if(headerLayoutType == 'private-layout') {
    <app-private-header></app-private-header>
} @else if(headerLayoutType == 'sidebar-layout') {
    @if((isLoggedIn$ | async)) {
        <app-sidebar-header></app-sidebar-header>
    } @else {
        <app-public-header></app-public-header>
    }    
} @else {
    <app-public-header></app-public-header>
}
@if(headerLayoutType == 'sidebar-layout') {
    @if((isLoggedIn$ | async)) {
        <div class="pos_home sidebar-content-body">
            <router-outlet />
            @if(footerCompanyInfoLayoutType === true) {
                <app-sidebar-footer></app-sidebar-footer>
            }
        </div>
    } @else {
        <router-outlet />
        @if(footerCompanyInfoLayoutType === true) {
            <app-sidebar-footer></app-sidebar-footer>
        }
    }  
} @else {
    <router-outlet />
}
<app-loader></app-loader>
@if(footerLayoutType == 'public-layout') {
    <app-public-footer></app-public-footer>
}