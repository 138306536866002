<section class="sec sec-planfooter py-4 border-top bg-light">
    <div class="container">
        <div class="disclaimer pb-3">
            <ul class="fs-12">
                <li># Standard Terms and Conditions of the insurance plan shall apply</li>
                <li>* Discounts are provided by the Insurance company as filed in the products</li>
                <li>**Claim TAT are provided by insurers and 
                    Telsysweb Insurance
                    is not responsible for its violation.</li>
            </ul>
        </div>
        <div class="gsc_row border-top pt-3 fw-semi">
            <p class="mb-1">Telsysweb Insurance Brokers Pvt. Ltd.</p>
            <p class="mb-1 fs-14">IRDAI License Number : U67110RJ2022PTC083201</p>
        </div>
    </div>
</section>