import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { API_RESPONSE } from '../constants/api-responses.constants';

@Injectable({
  providedIn: 'root'
})
export class FormUtilitiesService {

  constructor() { }

  toFormData(obj: any): FormData {
    const formData = new FormData();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item: any, index: number) => {
            formData.append(`${key}[${index}]`, item);
          });
        } else if (obj[key] instanceof File) {
          formData.append(key, obj[key], obj[key].name);
        } else {
          formData.append(key, obj[key]);
        }
      }
    }
    return formData;
  }

  markAllControlsAsTouched(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      if (control) {
        control.markAsTouched();
        const controlErrors = control.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            /* console.error(`Key control: ${key}, keyError: ${keyError}, err value: ${controlErrors[keyError]}`); */
          });
        }
      }
    });
  }

  getErrorMessage(control: AbstractControl, controlName: string, serverErrors: { [key: string]: string[] }): string | null {
    if (control.touched && control.invalid) {
      if (serverErrors[controlName]?.length) {
        return serverErrors[controlName][0];
      } else if (control.errors?.['required']) {
        return `${this.formatControlName(controlName)} is required.`;
      } else if (control.errors?.['minlength']) {
        const minLength = control.errors['minlength'].requiredLength;
        return `${this.formatControlName(controlName)} must be at least ${minLength} characters long.`;
      } else if (control.errors?.['maxlength']) {
        const maxLength = control.errors['maxlength'].requiredLength;
        return `${this.formatControlName(controlName)} cannot be more than ${maxLength} characters long.`;
      } else if (control.errors?.['fileSize']) {
        const fileSize = control.errors['fileSize'];
        return `${this.formatControlName(controlName)} must be smaller than ${fileSize} MB.`;
      } else if (control.errors?.['fileType']) {
        const fileType = control.errors['fileType'];
        return `${this.formatControlName(controlName)} must be a valid file type (${fileType}).`;
      } else if (control.errors?.['pattern']) {
        return `${this.formatControlName(controlName)} must be a valid pattern.`;
      } else {
        return `${this.formatControlName(controlName)} must be a valid.`;
      }
    } else if (serverErrors[controlName]?.length) {
      return serverErrors[controlName][0];
    }

    return null;
  }

  handleServerValidationError(error: any, form: FormGroup): { [key: string]: string[] } {
    let serverErrors: { [key: string]: string[] } = {};
    const reformattedErrors: { [key: string]: string[] } = {};
    if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
      serverErrors = error.error.data;
      for (const key in serverErrors) {
        if (serverErrors[key]?.length) {
          reformattedErrors[key] = serverErrors[key];
        }
      }
      if (Object.keys(reformattedErrors).length > 0) {
        this.updateFormErrors(reformattedErrors, form);
      }
    }
    return reformattedErrors;
  }

  private updateFormErrors(serverErrors: { [key: string]: string[] }, form: FormGroup): void {
    Object.keys(serverErrors).forEach(controlName => {
      const control = form.get(controlName);
      if (control) {
        control.markAsTouched();
        control.setErrors({ serverError: true });
      }
    });
  }

  private formatControlName(name: string): string {
    const formattedName = name.replace(/_/g, ' ');
    return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
  }

}
