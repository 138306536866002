<section class="sec serviceForm basicInfo bg-lgwhite py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="border shadow-sm p-3 rounded-1 bg-white">
            <div class="text-center mb-3">
              <h1 class="fs-5 mb-1 fw-bold">Business Insurance Policy</h1>
              <div class="fs-18 fw-semi text-center">Let's Start Business Insurance Fill the Basic Details</div>
            </div>
            <div class="cmnform services-form">
              <div class="indicators">
                <div class="step-tabs step-current"><span>Basic Details</span></div>
                <div class="step-tabs step-current"><span>Insurance Details</span></div>
              </div>
              <div class="stpesForm mt-4">
                <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                  <div class="row">
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">PAN Number / (पैन कार्ड नंबर) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-4">
                       <input matInput name="pan_card" formControlName="pan_card" id="pan_card" placeholder="PAN Card Number" minlength="10" maxlength="10" type="text" class="form-control custom-input text-uppercase" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/pan-card.svg" [alt]="projectName" width="18" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('pan_card')" class="custom-error">
                          {{ getErrorMessage('pan_card') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Date of Birth  (जन्म की तारीख) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-4">
                        <input matInput [matDatepicker]="date_of_birth" [min]="minDobDate" [max]="maxDobDate" id="date_of_birth" formControlName="date_of_birth" placeholder="DD-MM-YYYY" type="text" class="form-control custom-input" autocomplete="off" (click)="openDobDatepicker()" (keydown)="restrictNonNumericInput($event)" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/date.svg" [alt]="projectName" width="20" height="20" />
                          <mat-datepicker #date_of_birth></mat-datepicker>
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('date_of_birth')" class="custom-error">{{ getErrorMessage('date_of_birth') }}</mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <mat-label class="fs-14 mb-1 custom-label">Education / (शिक्षा) <span class="text-danger"> * </span></mat-label>
                        <div class="form-group icon-group mb-3">
                          <select id="education" formControlName="education" class="form-control form-control-custom form-select-custom form-select">
                            <option value="">-- Select Education --</option>
                            @if(arrayLength(getEducationType) > 0) {
                              @for(data of getEducationType; track i; let i=$index) {
                                <option value="{{ data?.key || '' }}">{{ data?.label || '' }}</option>
                              }
                            }
                          </select>
                          <mat-icon matSuffix class="custom-icon icon">
                            <img src="images/icons/select-list.svg" [alt]="projectName" width="20" height="20">
                          </mat-icon>
                          <mat-error *ngIf="getErrorMessage('education')" class="custom-error">
                            {{ getErrorMessage('education') }}
                          </mat-error>
                        </div>
                      </div>
                    <div class="col- col-sm-12 col-md-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Nominee Name / (नॉमिनी नाम) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-4">
                        <input matInput name="nominee_name" formControlName="nominee_name" id="nominee_name" placeholder="Nominee Name" maxlength="100" type="text" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/user.svg" [alt]="projectName" width="18" height="16">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('nominee_name')" class="custom-error">
                          {{ getErrorMessage('nominee_name') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Nominee D.O.B / (नॉमिनी जन्म तिथि) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-4">
                        <input matInput [matDatepicker]="nominee_dob" [min]="minNomineeDobDate" [max]="maxNomineeDobDate" id="nominee_dob" formControlName="nominee_dob" placeholder="DD-MM-YYYY" type="text" class="form-control custom-input" autocomplete="off" (click)="openNomineeDatepicker()" (keydown)="restrictNonNumericInput($event)" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/date.svg" [alt]="projectName" width="20" height="20" />
                          <mat-datepicker #nominee_dob></mat-datepicker>
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('nominee_dob')" class="custom-error">{{ getErrorMessage('nominee_dob') }}</mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Nominee Relation / (नॉमिनी सबंध) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <select id="nominee_relation" formControlName="nominee_relation" class="form-control form-control-custom form-select-custom form-select">
                          <option value="">-- Select Nominee Relation --</option>
                          @if(arrayLength(getNomineeRelation) > 0) {
                            @for(data of getNomineeRelation; track i; let i=$index) {
                              <option value="{{ data?.key || '' }}">{{ data?.label || '' }}</option>
                            }
                          }
                        </select>
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/relation.svg" [alt]="projectName" width="20" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('nominee_relation')" class="custom-error">
                          {{ getErrorMessage('nominee_relation') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Select State / (राज्य) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <select id="state_code" formControlName="state_code" class="form-control form-control-custom form-select-custom form-select">
                          <option value="">-- Select state --</option>
                          @if(arrayLength(getState) > 0) {
                            @for(data of getState; track i; let i=$index) {
                              <option value="{{ data?.state_code || '' }}">{{ data?.name || '' }}</option>
                            }
                          }
                        </select>
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/select-list.svg" [alt]="projectName" width="20" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('state_code')" class="custom-error">
                          {{ getErrorMessage('state_code') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">City / (शहर) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="city" formControlName="city" id="city" placeholder="City" maxlength="100" type="text" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/city.svg" [alt]="projectName" width="22" height="22">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('city')" class="custom-error">
                          {{ getErrorMessage('city') }}
                        </mat-error>
                      </div>
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="fs-14 mb-1 custom-label">Pincode / (पिन कोड) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput appNumericInput name="pincode" formControlName="pincode" id="pincode" placeholder="Pincode" minlength="6" maxlength="6" type="text" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/file.svg" [alt]="projectName" width="18" height="18">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('pincode')" class="custom-error">
                          {{ getErrorMessage('pincode') }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col- col-sm-12 col-md-6 col-lg-12 col-xl-12">
                      <mat-label class="fs-14 mb-1 custom-label">Address / (पता) <span class="text-danger"> * </span></mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="address" formControlName="address" id="address" placeholder="Address" type="text" class="form-control custom-input" />
                        <mat-icon matSuffix class="custom-icon icon">
                          <img src="images/icons/building.svg" [alt]="projectName" width="20" height="20">
                        </mat-icon>
                        <mat-error *ngIf="getErrorMessage('address')" class="custom-error">
                          {{ getErrorMessage('address') }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="text-danger fs-12 fw-semi col-12 mb-2">{{ globalConstant.file.max_file_label2 }}</div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">PAN Card Photo /  (पैन कार्ड फ़ोटो अपलोड करें)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="pan_doc" formControlName="pan_doc" id="pan_doc" (change)="onPANDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('pan_doc')" class="custom-error">
                          {{ getErrorMessage('pan_doc') }}
                        </mat-error>
                      </div>
                      @if(panDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                        <div class="service-document-file">
                          @if(isPdfOrImage(panDocFileUrl) == globalConstant.file_setting.type_pdf) {
                            <img (click)="downloadDocument(panDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                          } @else {
                            <img (click)="downloadDocument(panDocFileUrl)" [src]="panDocFileUrl" alt="Preview"  class="custom-download-document" />
                          }
                        </div>
                        <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="rc_doc_remove" (click)="onPANDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">Aadhar Card Photo / (आधार कार्ड फ़ोटो अपलोड करें)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="aadhar_doc" formControlName="aadhar_doc" id="aadhar_doc" (change)="onAadhaarDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('aadhar_doc')" class="custom-error">
                          {{ getErrorMessage('aadhar_doc') }}
                        </mat-error>
                      </div>
                      @if(aadhaarDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                          <div class="service-document-file">
                            @if(isPdfOrImage(aadhaarDocFileUrl) == globalConstant.file_setting.type_pdf) {
                              <img (click)="downloadDocument(aadhaarDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                            } @else {
                              <img (click)="downloadDocument(aadhaarDocFileUrl)" [src]="aadhaarDocFileUrl" alt="Preview"  class="custom-download-document" />
                            }
                          </div>
                          <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="last_insurance_doc_remove" (click)="onAadhaarDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4 col-md-4">
                      <mat-label class="mb-1 font_doc">Other Documents 1 /  (अन्य दस्तावेज 1)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="other_doc1" formControlName="other_doc1" id="other_doc1" (change)="onOtherOneDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('other_doc1')" class="custom-error">
                          {{ getErrorMessage('other_doc1') }}
                        </mat-error>
                      </div>
                      @if(otherOneDocFileUrl) {
                        <div class="doc_img-privews preview-bx mb-3">
                          <div class="service-document-file">
                            @if(isPdfOrImage(otherOneDocFileUrl) == globalConstant.file_setting.type_pdf) {
                              <img (click)="downloadDocument(otherOneDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                            } @else {
                              <img (click)="downloadDocument(otherOneDocFileUrl)" [src]="otherOneDocFileUrl" alt="Preview"  class="custom-download-document" />
                            }
                          </div>
                          <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="other_doc1_remove" (click)="onOtherOneDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                        </div>
                      }
                    </div>
                    <div class="col- col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <mat-label class="mb-1 font_doc">Other Documents 2 /  (अन्य दस्तावेज 2)</mat-label>
                      <div class="form-group icon-group mb-3">
                        <input matInput name="other_doc2" formControlName="other_doc2" id="other_doc2" (change)="onOtherTwoDocFileSelected($event)" accept="{{ globalConstant.file.file_image_pdf_accept }}" type="file" class="form-control custom-file docupld" />
                        <mat-error *ngIf="getErrorMessage('other_doc2')" class="custom-error">
                          {{ getErrorMessage('other_doc2') }}
                        </mat-error>
                      </div>
                      @if(otherTwoDocFileUrl) {
                              <div class="doc_img-privews preview-bx mb-3">
                                <div class="service-document-file">
                                  @if(isPdfOrImage(otherTwoDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                    <img (click)="downloadDocument(otherTwoDocFileUrl)" src="images/icons/pdf.png" alt="Preview" class="custom-download-document" />
                                  } @else {
                                    <img (click)="downloadDocument(otherTwoDocFileUrl)" [src]="otherTwoDocFileUrl" alt="Preview"  class="custom-download-document" />
                                  }
                                </div>
                                <button type="button" class="btn btn-remove text-danger fs-11 fw-bold" id="other_doc2_remove" (click)="onOtherTwoDocFileRemove()"><i class="bx bx-trash me-1"></i>REMOVE</button>
                              </div>
                            }
                    </div>
                    <div class="col- col-md-12">
                      <div class="mb-3 form-check">
                        <input id="accept_t_c" formControlName="accept_t_c" value="yes" type="checkbox" class="form-check-input" />
                        <label for="accept_t_c" class="form-check-label">
                          I accept all <a [routerLink]="[globalConstant.base_url, routesPages.TERMS_AND_CONDITIONS || '']" target="_blank" class="text-success">Terms and Conditions</a>
                        </label>
                        <mat-error *ngIf="getErrorMessage('accept_t_c')" class="custom-error">
                            {{ getErrorMessage('accept_t_c') }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group text-center">
                        <a [routerLink]="[globalConstant.base_url, routesBusinessInsurance.OFFLINE_STEP_ONE || '', uuId || '']" class="btn btn-dark rounded-5 btn-lg px-5 me-2">
                          BACK
                        </a>
                        <button type="submit" class="btn btn-success rounded-5 btn-lg px-5" [disabled]="isLoading">
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>