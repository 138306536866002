import { Routes } from '@angular/router';
import { authDisableGuard } from '../../guards/auth-disable.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_USERS } from '../../constants/routes/users/users.constants';
import { UserSignInComponent } from '../../../users/user-sign-in/user-sign-in.component';
import { UserSignInOtpVerificationComponent } from '../../../users/user-sign-in-otp-verification/user-sign-in-otp-verification.component';
import { UserDashboardComponent } from '../../../users/user-dashboard/user-dashboard.component';
import { UserProfileComponent } from '../../../users/user-profile/user-profile.component';
import { OfflineServicesOrdersComponent } from '../../../offline-services-orders/offline-services-orders.component';

export const UsersRoutes: Routes = [
    {
        path: ROUTES_USERS.USER,
        data: { headerLayoutType: 'private-layout', footerLayoutType: 'public-layout' },
        children: [
            {
                path: '',
                component: UserSignInComponent,
                canActivate: [authDisableGuard]
            },
            {
                path: ROUTES_USERS.USER_SIGN_IN,
                component: UserSignInComponent,
                canActivate: [authDisableGuard]
            },
            {
                path: ROUTES_USERS.USER_SIGN_IN_OTP_VERIFICATION,
                component: UserSignInOtpVerificationComponent,
                canActivate: [authDisableGuard]
            },
            {
                path: ROUTES_USERS.USER_PROFILE,
                component: UserProfileComponent,
                canActivate: [authUsersGuard]
            },
        ]
    },
    {
        path: ROUTES_USERS.USER_DASHBOARD,
        component: UserDashboardComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_USERS.USER_SERVICES,
        component: OfflineServicesOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];