import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private http: HttpClient) { }

  private getFileExtension(url: string): string {
    return url.split('.').pop()?.split(/\#|\?/)[0] || '';
  }

  private getFileNameFromUrl(url: string): string {
    const segments = url.split('/');
    return segments[segments.length - 1] || 'download';
  }

  downloadDocument(url: string, filename?: string): void {
    const fileExtension = this.getFileExtension(url);
    const fileName = filename ? `${filename}.${fileExtension}` : this.getFileNameFromUrl(url);

    this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    }, error => {
      console.error('Download failed:', error);
    });
  }
}
