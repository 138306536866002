<section class="sec quote-plans py-4">
    <div class="container">
        <div class="row">
            @if(objectLength(vehicleDetail) > 0) {
                <div class="col-md-4 col-lg-3">
                    <div class="rounded-2 bg-white border shadow-sm border-success overflow-hidden">
                        <div class="vcard p-3">
                            <div class="title">
                                <h6 class="mb-0">Your vehicle details</h6>
                            </div>
                            <!-- <div class="edit">
                                <button type="button" class="text-muted fs-13 p-0 bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#vehicleEditModal">Edit</button>
                            </div> -->
                        </div>
                        <div class="vehicle_detail px-3 pb-2">
                            <span class="imgspace">
                                <img src="images/plans/bike-landing.svg" width="36" height="20" [alt]="projectName" />
                            </span>
                            <div class="view">
                                <div class="fs-13">
                                    {{ vehicleDetail?.vehicle_model }}
                                </div>
                            </div>
                        </div>
                        <div class="bg-light px-3 py-2 rto_detail">
                            <ul>
                                <li class="rtoyear">
                                    <div class="heading">Year</div>
                                    <div class="value">
                                        @if(vehicleDetail?.registration_date && vehicleDetail?.registration_date != '') {
                                            {{ formatDate(vehicleDetail?.registration_date) }}
                                        }
                                    </div>
                                </li>
                                <li>
                                    <div class="heading">RTO</div>
                                    <div class="value">
                                        {{ vehicleDetail?.vehicle_number }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- <div class="p-2 bg-white rounded-2 shadow-sm border mt-3">
                        <div class="c-title fw-semi">Add-ons cover</div>
                        <div class="add-ons mt-2">
                            <div class="form-check py-1">
                                <input class="form-check-input" type="checkbox" name="pacover" id="pacover" value="PA cover">
                                <label class="form-check-label" for="pacover">Personal accident cover</label>
                            </div>
                            <div class="form-check py-1">
                                <input class="form-check-input" type="checkbox" name="zerodepre" id="zerodepre" value="zerodepre">
                                <label class="form-check-label" for="zerodepre">Zero Depreciation Cover</label>
                            </div>
                            <div class="form-check py-1">
                                <input class="form-check-input" type="checkbox" name="roadassistance" id="roadassistance" value="roadassistance">
                                <label class="form-check-label" for="roadassistance">24x7 Roadside Assistance</label>
                            </div>
                            <div class="form-check py-1">
                                <input class="form-check-input" type="checkbox" name="drivercover" id="drivercover" value="drivercover">
                                <label class="form-check-label" for="drivercover">Driver Cover</label>
                            </div>
                            <div class="form-check py-1">
                                <input class="form-check-input" type="checkbox" name="invoicecover" id="invoicecover" value="invoicecover">
                                <label class="form-check-label" for="invoicecover">Invoice Cover</label>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="p-2 bg-white rounded-2 shadow-sm border mt-3">
                        <div class="d-flex justify-content-between">
                            <div class="c-title fw-semi">No claim bonus</div>
                            <div class="edit">
                                <button type="button" class="text-muted fs-13 p-0 bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#editNCBmodal">Edit</button>
                            </div>
                        </div>
                        <div class="ncb mt-2 fs-13 text-muted">
                            <p class="mb-2">Current applicable NCB: <span class="fw-semi">50%</span></p>
                            <p class="mb-2">Previous year NCB: <span class="fw-semi">35%</span></p>
                            <p class="mb-0">Claim in last policy: <span class="fw-semi">No</span></p>
                        </div>
                    </div> -->
                </div>

                <div class="col-md-8 col-lg-9">
                    <div class="main_quotes bg-white rounded-2 py-3 px-4 border">
                        <div class="pqotes_panel">
                            <div class="tabs">
                                <nav class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button class="nav-link active" id="comprehensive-tab" data-bs-toggle="tab" data-bs-target="#comprehensive-feature" type="button" role="tab" aria-controls="comprehensive-feature" aria-selected="true">Comprehensive Plan</button>
                                    <button class="nav-link" id="thirtyparty-tab" data-bs-toggle="tab" data-bs-target="#thirtyparty-cashless" type="button" role="tab" aria-controls="thirtyparty-cashless" aria-selected="false">Third Party Plan</button>
                                </nav>
                                <div class="tab-content p-3" id="nav-tabContent">
                                    <div class="tab-pane fade active show" id="comprehensive-feature" role="tabpanel" aria-labelledby="comprehensive-tab">
                                        <div class="row mt-3">
                                            <div class="col-12 mb-3">
                                                <!-- <div *ngIf="loading">Loading...</div>
                                                <div *ngIf="!loading"> -->
                                                @if(objectLength(quotesList) > 0) {
                                                    <div class="card-body">
                                                        <div class="fw-semi fs-18 mb-2">1 Comprehensive plans</div>
                                                        <ul class="ins-nav half full mb-1">
                                                            <li class="fw-normal">Covers damages to your vehicle and
                                                                third-party</li>
                                                        </ul>
                                                    </div>
                                                    <div class="quotecard border border-light shadow-sm rounded-1">
                                                        <div class="company_quotes">
                                                            <div class="company-logo border p-1 rounded-1">
                                                                <img [src]="quotesList?.insurer_logo ?? 'images/default/default-insurance.png'" [alt]="projectName" width="80" height="53" />
                                                            </div>
                                                            <div class="company-plans">
                                                                <div class="plan_title text-success fs-15 text-uppercase fw-bold">
                                                                    {{ quotesList?.insurer_name || '' }}
                                                                </div>
                                                                <div class="plan-cover">
                                                                    <span class="dv-value fw-semi">IDV: {{ quotesList?.currency_symbol }}{{ quotesList?.idv?.default_idv || '' }}</span>
                                                                    <span class="minmax-idv">IDV:
                                                                        <span>{{ quotesList?.currency_symbol }}{{ quotesList?.idv?.minimum_idv || '' }}</span>
                                                                        -
                                                                        <span>{{ quotesList?.currency_symbol || '' }}{{ quotesList?.idv?.maximum_idv || '' }}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="btn-element text-center">
                                                            <!-- <button (click)="selectInsurer(insurerQuote?.insurer_id)"> -->
                                                            <button type="button" class="btn btn-green d-block w-100 fs-18 fw-normal">
                                                                {{ quotesList?.currency_symbol }}{{ quotesList?.net_premium || '' }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                } @else {
                                                    <p class="no-data-found">No Data Available</p>
                                                }
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="thirtyparty-cashless" role="tabpanel" aria-labelledby="thirtyparty-tab">
                                        <!-- Tab Code Here -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            } @else {
                <div class="col-md-12 col-lg-12 text-center">
                    Vehicle details not found.
                </div>
            }
        </div>
    </div>
</section>
<app-insurance-terms-conditions></app-insurance-terms-conditions>