import { Action, createReducer, on } from '@ngrx/store';
import { storeSetTwoWheelerInsuranceBasicDetail, storeResetTwoWheelerInsuranceBasicDetail } from '../../../actions/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.actions';

export interface StoreTwoWheelerInsuranceBasicDetail { [key: string]: any; }

export interface StoreStateTwoWheelerInsuranceBasicDetail {
  storeData: StoreTwoWheelerInsuranceBasicDetail | null;
}

export const initialState: StoreStateTwoWheelerInsuranceBasicDetail = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetTwoWheelerInsuranceBasicDetail, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetTwoWheelerInsuranceBasicDetail, state => ({ ...state, storeData: null }))
);

export function reducerOnlineTwoWheelerInsuranceBasicDetail(state: StoreStateTwoWheelerInsuranceBasicDetail | undefined, action: Action) { return _storeReducer(state, action); }