<div class="blog pt-3 mt-md-0 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-8 col-xl-8">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-xl-6 py-3">
                        <div class="border rounded h-100 pb-2">
                            <div class="blog_image"><a href="blog-details.html" class="d-flex align-items-center justify-content-center"><img
                                        src="images/blog/health-insurance-blog.jpg"
                                        class="img-fliud rounded-top" alt=""></a></div>
                            <div class="bolg_content">
                                <ul>
                                    <li class="mt-lg-3 mt-xl-0">
                                        <span class="font_14"><i class="bx bx-calendar pe-1"></i>10 DEC 2024</span>
                                    </li>
                                </ul>
                                <h3 class="fw-bold fs-5"><a href="blog-details.html" class="text-dark">The
                                        Essential Guide to Health Insurance</a></h3>
                                <p class="">Health insurance is one of those things that often feels like a
                                    complex maze filled with jargon and endless options.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-6 py-3">
                        <div class="border rounded h-100 pb-2">
                            <div class="blog_image"><a href="blog-details.html" class="d-flex align-items-center justify-content-center"><img
                                        src="images/blog/health-insurance-blog.jpg"
                                        class="img-fliud rounded-top" alt=""></a></div>
                            <div class="bolg_content">
                                <ul>
                                    <li class="mt-lg-3 mt-xl-0">
                                        <span class="font_14"><i class="bx bx-calendar pe-1"></i>10 DEC 2024</span>
                                    </li>
                                </ul>
                                <h3 class="fw-bold fs-5"><a href="blog-details.html" class="text-dark">The
                                        Benfits of Car Insurance</a></h3>
                                <p class="">Car insurance is one of those things that often feels like a
                                    complex maze filled with jargon and endless options.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4 py-3 order-md-1">
                <div class="second_blog">
                    <div class=" mb-4 rounded">
                        <form>
                            <div class="input-group">
                                <input type="text" class="form-control py-0" style="height:38px ;"
                                    placeholder="Search..." aria-label="Username" aria-describedby="basic-addon1">
                                <button class="input-group-text border-0 text-white" id="basic-addon1">Search</button>
                            </div>
                        </form>
                    </div>
                    <div class=" mb-4">
                        <div class="blog-detail-box rounded-0 text-white">
                            <p class="px-3 py-1 mb-0 font_18">Latest Blogs</p>
                        </div>
                        <div class="px-3 pt-3 border">
                            <ul class="latest_post m-0 p-0">
                                <li>
                                    <div class="post_pic">
                                        <a href="blog-details.html">
                                            <img src="images/blog/health-insurance-blog.jpg"
                                                class="img-fluid" width="80" height="53" alt="">
                                        </a>
                                    </div>
                                    <div class="post_info">
                                        <p class="lh-1 fw-bold mb-1 heading">
                                            <a href="blog-details.html" class=" text-dark">
                                                Essential Guide to Health Insurance
                                            </a>
                                        </p>
                                        <p class="m-0 font_12"><i class="bx bx-calendar pe-1"></i>4 DEC 2024</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="post_pic">
                                        <a href="blog-details.html">
                                            <img src="images/blog/health-insurance-blog.jpg"
                                                class="img-fluid" width="80" height="53" alt="">
                                        </a>
                                    </div>
                                    <div class="post_info">
                                        <p class="lh-1 fw-bold mb-1 heading">
                                            <a href="blog-details.html" class=" text-dark">
                                                Essential Guide to Health Insurance
                                            </a>
                                        </p>
                                        <p class="m-0 font_12"><i class="bx bx-calendar pe-1"></i>4 DEC 2024</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div class="blog-detail-box rounded-0 text-white">
                            <p class="px-3 py-1 mb-0 font_18">Blog Categories</p>
                        </div>
                        <ul class="border tagging_tags d-flex flex-wrap p-3">
                            <li>
                                <a href="#" class="tag_group ">Car Insurance</a>
                            <li>
                            <li>
                                <a href="#" class="tag_group ">Bike Insurance</a>
                            <li>
                            <li>
                                <a href="#" class="tag_group ">Health Insurance</a>
                            <li>
                            <li>
                                <a href="#" class="tag_group ">Child Saving Plan</a>
                            <li>
                            <li>
                                <a href="#" class="tag_group ">Retirement Plan</a>
                            <li>
                            <li>
                                <a href="#" class="tag_group ">Term Life Insurance</a>
                            <li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>