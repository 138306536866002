import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { aadharCardValidator, panCardValidator } from '../../../common/utilities/form-rules';

export function getFormValidators(): FormGroup {
    const globalConstant = GLOBAL_CONSTANT;
    return new FormGroup({
        kyc_step: new FormControl(globalConstant?.posp_kyc_step_match?.pan_aadhar_details || 'pan_aadhar_details'),
        pan_card: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            panCardValidator,
        ]),
        aadhar_number: new FormControl('', [
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(12),
            aadharCardValidator,
        ]),
        pan_card_doc: new FormControl('', [
            Validators.required
        ]),
        aadhar_front_doc: new FormControl('', [
            Validators.required
        ]),
        aadhar_back_doc: new FormControl('', [
            Validators.required
        ])
    });
}
