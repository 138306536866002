import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { Subscription } from 'rxjs';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ROUTES_POSP_TRAINING } from '../../../common/constants/routes/posp/posp-training.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { LoaderService } from '../../../common/loader/loader.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { navigatePopsError } from '../../../common/utilities/navigate-posp-status';
import { PospSelectCourseService } from './posp-select-course.service';
import { getFormValidators } from './form-utils';

@Component({
  selector: 'app-posp-select-course',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatFormFieldModule
  ],
  templateUrl: './posp-select-course.component.html'
})
export class PospSelectCourseComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  routesPospTraining = ROUTES_POSP_TRAINING;

  courseData: any = {};
  isLoading = false;
  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};

  private apiPospGetCourseSubscription: Subscription | undefined;
  private apiPospPospCourseSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private pospSelectCourseService: PospSelectCourseService
  ) {
    this.customForm = getFormValidators();
  }

  ngOnInit(): void {
    this.getCourse();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  getCourse(): void {
    this.loaderService.show();
    this.apiPospGetCourseSubscription = this.pospSelectCourseService.getCourse().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.loaderService.hide();
          if (response?.data && this.objectLength(response?.data) > 0) {
            this.courseData = response?.data;
          }
        },
        (error) => {
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
          const navigatedRoute = navigatePopsError(error);
          if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
            this.router.navigate(navigatedRoute.route as string[]);
          }
        }
      )
    );
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const formData = {
        course: this.customForm.value.course
      };
      this.apiPospPospCourseSubscription = this.pospSelectCourseService.postCourse(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            this.router.navigate([this.globalConstant.base_url, this.routesPospTraining.POSP_TRAINING || '']);
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiPospGetCourseSubscription) {
      this.apiPospGetCourseSubscription.unsubscribe();
    }
    if (this.apiPospPospCourseSubscription) {
      this.apiPospPospCourseSubscription.unsubscribe();
    }
  }
}
