import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_OFFLINE_CHILD_SAVING_PLAN } from '../../constants/routes/offline-services/offline-child-saving-plan.constants';
import { OfflineChildSavingPlanStepOneComponent } from '../../../offline-services/child-saving-plan/offline-child-saving-plan-step-one/offline-child-saving-plan-step-one.component';
import { OfflineChildSavingPlanStepTwoComponent } from '../../../offline-services/child-saving-plan/offline-child-saving-plan-step-two/offline-child-saving-plan-step-two.component';
import { OfflineChildSavingPlanOrdersComponent } from '../../../offline-services-orders/child-saving-plan/offline-child-saving-plan-orders/offline-child-saving-plan-orders.component';
import { OfflineChildSavingPlanOrderDetailComponent } from '../../../offline-services-orders/child-saving-plan/offline-child-saving-plan-order-detail/offline-child-saving-plan-order-detail.component';

export const OfflineChildSavingPlanRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_STEP_ONE,
        component: OfflineChildSavingPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineChildSavingPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineChildSavingPlanStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineChildSavingPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineChildSavingPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_CHILD_SAVING_PLAN.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineChildSavingPlanOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];