import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class PospExamQuestionService {

  constructor(
    private apiService: ApiService
  ) { }

  getQuestion() {
    return this.apiService.request<any>('GET_AUTH', 'posp/exam/get-question');
  }

  submitQuestion(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'posp/exam/submit-question', formData);
  }
}
