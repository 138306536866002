<div class="main_container">
    <div class="container">
        <div class="row">
            <div class="bg-white">
                <h1 class="fs-5 mb-3">All Commissions</h1>
                <div class="box_outer border rounded service-sidebar">
                    <div class="row">
                        <div class="col-md-12 innerpages">
                            <div class="table-responsive">
                                <table
                                    class="table caption-top mb-0 table-ds table-bordered table-striped fs-14 dataTable no-footer">
                                    <thead>
                                        <tr class="table_header">
                                            <th scope="col" class="text-center">SERVICE NAME</th>
                                            <th scope="col" class="text-center">TOTAL</th>
                                            <th scope="col" class="text-center">TODAY</th>
                                            <th scope="col" class="text-center">THIS WEEK</th>
                                            <th scope="col" class="text-center">THIS MONTH</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        @if(objectLength(offlineCommissionList) > 0) {
                                        @for(data of offlineCommissionList; track i; let i=$index) {
                                        <tr>
                                            <td>
                                                {{ data?.name || '' }}
                                            </td>
                                            <td class="text-center">
                                                <a class="text-success"
                                                    [routerLink]="data?.total && data?.total > 0 ? getOffLineServiceOrdersURL(data.service_type_id, globalConstant.offline_order_show_type.commission) : null">
                                                    {{ data?.total || 0 }}
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <a class="text-primary"
                                                    [routerLink]="data?.today && data?.today > 0 ? getOffLineServiceOrdersURL(data.service_type_id, globalConstant.offline_order_show_type.commission, globalConstant.date_key_match.today) : null">
                                                    {{ data?.today || 0 }}
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <a class="text-warning"
                                                    [routerLink]="data?.this_week && data?.this_week > 0 ? getOffLineServiceOrdersURL(data.service_type_id, globalConstant.offline_order_show_type.commission, globalConstant.date_key_match.this_week) : null">
                                                    {{ data?.this_week || 0 }}
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <a class="text-primary"
                                                    [routerLink]="data?.this_month && data?.this_month > 0 ? getOffLineServiceOrdersURL(data.service_type_id, globalConstant.offline_order_show_type.commission, globalConstant.date_key_match.this_month) : null">
                                                    {{ data?.this_month || 0 }}
                                                </a>
                                            </td>
                                        </tr>
                                        }
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>