@if(arrayLength(serviceList) > 0) {
<section class="sec categroy py-md-4 pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-11 mx-auto text-center">
        <div class="sec-head mb-5">
          <h1 class="fs-3 fw-bolder" [innerHTML]="title"></h1>
          <p class="fs-5 mb-4" [innerHTML]="subTitle"></p>
          <p><span class="fs-5 bg-success p-2 px-4 fw-semi text-white rounded-2">Request for Offline Quotes</span></p>
        </div>
        <ul class="cat-list">
          @for(data of serviceList; track i; let i=$index) {
          <li>
            <a [routerLink]="getOffLineServiceURL(data?.service_type_id, 'step_1')" aria-label="Services">
              <div class="cat-bx">
                <img [src]="data?.image ?? 'images/default/default-insurance.png'" alt="{{ data?.name }}" width="55"
                  height="55" />
              </div>
              <span class="cat-name d-block" [innerHTML]="data?.name"></span>
            </a>
          </li>
          }
        </ul>
      </div>
    </div>
  </div>
</section>
}

@if(arrayLength(achievement) > 0) {
<section class="sec achive py-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-8 mx-auto">
        <div class="row">
          @for(data of achievement; track i; let i=$index) {
          <div class="col-md-4 mb-4 mb-md-0">
            <div class="achive-bx bg-light d-flex align-items-center justify-content-center p-3 h-100 rounded-2 border">
              @if(data?.src && data?.src !== '') {
              <span class="iconsBg">
                <img [src]="data?.src" alt="{{ data?.alt }}" width="60" height="60" />
              </span>
              }
              <div class="content">
                <div class="fs-4 fw-bolder" [innerHTML]="data?.counter"></div>
                <div class="fs-6" [innerHTML]="data?.title"></div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>
}

@if(objectLength(insuredProcess) > 0) {
<section class="sec insure pt-md-2 pb-4">
  <div class="container">
    <div class="col-12 col-xl-10 mx-auto">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="text-green text-uppercase fw-bold fs-5 mb-1" [innerHTML]="insuredProcess?.shor_title"></div>
          <h2 class="fw-bold fs-2 mb-3" [innerHTML]="insuredProcess?.title"></h2>
          <p [innerHTML]="insuredProcess?.sub_title"></p>
          @if(insuredProcess?.data && arrayLength(insuredProcess?.data) > 0) {
          <ul class="ins-nav mt-4">
            @for(data of insuredProcess?.data; track i; let i=$index) {
            <li [innerHTML]="data"></li>
            }
          </ul>
          }
        </div>
        @if(insuredProcess?.src && insuredProcess?.src !== '') {
        <div class="col-md-6 text-center mt-4 mt-md-0">
          <img [src]="insuredProcess?.src" alt="{{ insuredProcess?.alt }}" width="474" height="392" />
        </div>
        }
      </div>
    </div>
  </div>
</section>
}

@if(arrayLength(security) > 0) {
<section class="sec awards py-4 bg-light">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          @for(data of security; track i; let i=$index) {
          <div class="col-md-4">
            <div class="award-bx text-center">
              @if(data?.src && data?.src !== '') {
              <span class="iconsBg">
                <img [src]="data?.src" alt="{{ data?.alt }}" width="100" height="100" />
              </span>
              }
              <div class="fs-4 fw-bolder" [innerHTML]="data?.title"></div>
              <p [innerHTML]="data?.sub_title"></p>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>
}

@if(objectLength(workProcess) > 0) {
<section class="sec how-work pt-5 pb-lg-4 mt-lg-4">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6 mb-4 mb-lg-0">
        <div class="bg-lgreen fw-semi fs-18 rounded-5 d-inline-block px-4 py-2" [innerHTML]="workProcess?.shor_title">
        </div>
        <div class="content mt-2">
          <h2 class="fw-bold fs-2 mb-4" [innerHTML]="workProcess?.title"></h2>
          <p [innerHTML]="workProcess?.content"></p>
          @if(workProcess?.data && arrayLength(workProcess?.data) > 0) {
          <ul class="ins-nav mt-4">
            @for(data of workProcess?.data; track i; let i=$index) {
            <li [innerHTML]="data"></li>
            }
          </ul>
          }
        </div>
      </div>
      @if(workProcess?.work_process_data && arrayLength(workProcess?.work_process_data) > 0) {
      <div class="col-12 col-lg-6">
        <div class="row">
          @for(data of workProcess?.work_process_data; track i; let i=$index) {
          <div class="col-12 col-md-6 mb-4">
            <div class="bg-light twi-bx rounded-2">
              @if(data?.src && data?.src !== '') {
              <div class="icon">
                <span class="iconsBg">
                  <img [src]="data?.src" alt="{{ data?.alt }}" width="60" height="60" />
                </span>
              </div>
              }
              <div class="fs-5 fw-semi mb-1" [innerHTML]="data?.title"></div>
              <p class="mb-0" [innerHTML]="data?.sub_title"></p>
            </div>
          </div>
          }
        </div>
      </div>
      }
    </div>
  </div>
</section>
}

@if(objectLength(consultation) > 0) {
<section class="sec contsult py-4 mt-lg-5">
  <div class="container">
    <div class="oninq">
      @if(consultation?.src && consultation?.src !== '') {
      <img [src]="consultation?.src" alt="{{ consultation?.alt }}" width="364" height="374" />
      }
      <div class="row align-items-center">
        <div class="col-12 col-xl-9">
          <h2 class="fw-normal fs-2 mb-2" [innerHTML]="consultation?.title"></h2>
          <p [innerHTML]="consultation?.sub_title"></p>
          @if(consultation?.data && arrayLength(consultation?.data) > 0) {
          <ul class="allpocicy">
            @for(data of consultation?.data; track i; let i=$index) {
            <li [innerHTML]="data"></li>
            }
          </ul>
          }
        </div>
        <div class="col-12 col-xl-3 text-center mt-4 mt-xl-0">
          <a href="javascript:void(0);" class="btn btn-dark">
            <i class="bx bx-phone align-middle me-1"></i>
            {{ consultation?.button_label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
}

@if(objectLength(ourPartners) > 0) {
<section class="sec partner py-4">
  <div class="container">
    <div class="sec-head mb-4 text-center">
      <h2 class="fs-2 fw-bold" [innerHTML]="ourPartners?.title"></h2>
      <p [innerHTML]="ourPartners?.sub_title"></p>
    </div>
    @if(ourPartners?.logos && arrayLength(ourPartners?.logos) > 0) {
    <div class="w-100">
      <ul class="partner-list">
        @for(data of ourPartners?.logos; track i; let i=$index) {
        <li>
          <div class="partner-bx border rounded-2 d-flex align-items-center justify-content-center h-100 p-2">
            <span class="partnerBg">
              <img [src]="data?.src" alt="{{ data?.alt }}" width="110" height="65" />
            </span>
          </div>
        </li>
        }
      </ul>
    </div>
    }
  </div>
</section>
}

@if(objectLength(dataSecurity) > 0) {
<section class="sec data-secure bg-light py-4">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="text-green fw-bold fs-5 mb-1" [innerHTML]="dataSecurity?.shor_title"></div>
        <h2 class="fw-bold fs-2 mb-3" [innerHTML]="dataSecurity?.title"></h2>
        <p [innerHTML]="dataSecurity?.content"></p>
        @if(dataSecurity?.data && arrayLength(dataSecurity?.data) > 0) {
        <ul class="ins-nav mt-4">
          @for(data of dataSecurity?.data; track i; let i=$index) {
          <li [innerHTML]="data"></li>
          }
        </ul>
        }
      </div>
      @if(dataSecurity?.src && dataSecurity?.src !== '') {
      <div class="col-md-6 text-center">
        <img [src]="dataSecurity?.src" alt="{{ dataSecurity?.alt }}" width="352" height="262" />
      </div>
      }
    </div>
  </div>
</section>
}

@if(objectLength(groupBrands) > 0) {
<section class="sec brands pt-4">
  <div class="container">
    <div class="sec-head mb-4 text-center">
      <h2 class="fs-2 fw-bold" [innerHTML]="groupBrands?.title"></h2>
      <p [innerHTML]="groupBrands?.sub_title"></p>
    </div>
    @if(groupBrands?.logos && arrayLength(groupBrands?.logos) > 0) {
    <div class="w-100">
      <ul class="partner-list">
        @for(data of groupBrands?.logos; track i; let i=$index) {
        <li>
          <div class="partner-bx border rounded-1 d-flex align-items-center justify-content-center h-100 p-2">
            <span class="brandBg">
              <img [src]="data?.src" alt="{{ data?.alt }}" width="180" height="70" />
            </span>
          </div>
        </li>
        }
      </ul>
    </div>
    }
  </div>
</section>
}

@if(objectLength(support) > 0) {
<section class="sec helpsupport pt-4">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 col-lg-5">
        <h2 class="fs-2 fw-bold mb-2" [innerHTML]="support?.title"></h2>
        <p [innerHTML]="support?.content"></p>
      </div>
      <div class="col-md-6 col-lg-6 offset-lg-1">
        <div class="row">
          <div class="col-xl-6 col-12">
            <div class="hs-bx bg-light d-flex align-items-center py-3 px-2 h-100 rounded-2 border">
              <span class="icon bx bx-envelope-open"></span>
              <div class="content">
                <p class="mb-0" [innerHTML]="support?.support_email_title"></p>
                <div class="fs-6 fw-semi" [innerHTML]="support?.support_email"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-12 mt-4 mt-xl-0">
            <div class="hs-bx bg-light d-flex align-items-center py-3 px-2 h-100 rounded-2 border">
              <span class="icon bx bx-headphone"></span>
              <div class="content">
                <p class="mb-0" [innerHTML]="support?.support_phone_title"></p>
                <div class="fs-6 fw-semi" [innerHTML]="support?.support_phone"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
}

@if(objectLength(faqs) > 0) {
<section class="sec faq pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-8 mx-auto">
        <h2 class="fs-2 fw-bold text-center mb-4" [innerHTML]="faqs?.title"></h2>
        @if(faqs?.faqs && arrayLength(faqs?.faqs) > 0) {
        <div class="faq">
          <div class="accordion" id="faqaccordion">
            <mat-accordion>
              @for(data of faqs?.faqs; track i; let i=$index) {
              <mat-expansion-panel class="faqs-design" [expanded]="i === 0">
                <mat-expansion-panel-header>
                  <mat-panel-title [innerHTML]="data?.question"></mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHTML]="data?.answer"></div>
              </mat-expansion-panel>
              }
            </mat-accordion>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</section>
}