import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_POSP_TRAINING } from '../../common/constants/routes/posp/posp-training.constants';
import { ROUTES_POSP_EXAM } from '../../common/constants/routes/posp/posp-exam.constants';
import { LoaderService } from '../../common/loader/loader.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { navigatePopsError } from '../../common/utilities/navigate-posp-status';
import { DownloadService } from '../../common/utilities/download.service';
import { PospExamScoreService } from './posp-exam-score.service';

@Component({
  selector: 'app-posp-exam-score',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './posp-exam-score.component.html'
})
export class PospExamScoreComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPospTraining = ROUTES_POSP_TRAINING;
  routesPospExam = ROUTES_POSP_EXAM;

  projectName = this.globalConstant?.projectName;

  getExamData: any = {};

  private apiExamDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private pospExamScoreService: PospExamScoreService,
    private downloadService: DownloadService
  ) { }

  ngOnInit(): void {
    this.apiGetPageData();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  apiGetPageData() {
    this.loaderService.show();
    this.apiExamDataSubscription = this.pospExamScoreService.getPageData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.loaderService.hide();
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            this.getExamData = response?.data;
          }
        },
        (error) => {
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
          const navigatedRoute = navigatePopsError(error);
          if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
            this.router.navigate(navigatedRoute.route as string[]);
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiExamDataSubscription) {
      this.apiExamDataSubscription.unsubscribe();
    }
  }

}
