import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { GLOBAL_CONSTANT } from '../../constants/global-constant.constants';
import { ROUTES_PAGES } from '../../constants/routes/pages/pages.constants';
import { ROUTES_POSP } from '../../constants/routes/posp/posp.constants';
import { ROUTES_USERS } from '../../constants/routes/users/users.constants'
import { OFFLINE_SERVICES_ID } from '../../constants/services/offline-services-url.constants';
import { UtilitiesService } from '../../utilities/utilities.service';
import { AuthUsersService } from '../../utilities/auth-users.service';

@Component({
  selector: 'app-public-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule
  ],
  templateUrl: './public-header.component.html'
})
export class PublicHeaderComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;
  routesPosp = ROUTES_POSP;
  routesUsers = ROUTES_USERS;
  offlineServicesID = OFFLINE_SERVICES_ID;

  projectName = this.globalConstant?.projectName;

  isLoggedIn$!: Observable<boolean>;

  constructor(
    private router: Router,
    private utilitiesService: UtilitiesService,
    private authUsersService: AuthUsersService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authUsersService.isLoggedIn$;
  }

  getOffLineServiceFullName(id: number): string {
    return this.utilitiesService.getOffLineServiceFullName(id);
  }

  getOffLineServiceURL(id: number, step: string): string[] {
    return this.utilitiesService.getOffLineServiceURL(id, step);
  }

  async logout(): Promise<void> {
    try {
      await this.authUsersService.logoutUsers();
      this.router.navigate([this.globalConstant.base_url]);
    } catch (error) {
      this.router.navigate([this.globalConstant.base_url]);
    }
  }

  isProfileOpen = false;

  myProfileOpen() {
    this.isProfileOpen = true;
  }

  myProfileClosed() {
    this.isProfileOpen = false;
  }

  isMenuOpen: boolean = false;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closetoggleMenu() {
    this.isMenuOpen = false;
  }

  isSubMenuOpen: boolean = false;

  SubMenuOpen() {
    this.isSubMenuOpen = !this.isSubMenuOpen;
  }

}
