import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_PICKUP_INSURANCE } from '../../constants/routes/offline-services/offline-pickup-insurance.constants';
import { OfflinePickupInsuranceStepOneComponent } from '../../../offline-services/pickup-insurance/offline-pickup-insurance-step-one/offline-pickup-insurance-step-one.component';
import { OfflinePickupInsuranceStepTwoComponent } from '../../../offline-services/pickup-insurance/offline-pickup-insurance-step-two/offline-pickup-insurance-step-two.component';
import { OfflinePickupInsuranceOrdersComponent } from '../../../offline-services-orders/pickup-insurance/offline-pickup-insurance-orders/offline-pickup-insurance-orders.component';
import { OfflinePickupInsuranceOrderDetailComponent } from '../../../offline-services-orders/pickup-insurance/offline-pickup-insurance-order-detail/offline-pickup-insurance-order-detail.component';

export const OfflinePickupInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflinePickupInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflinePickupInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflinePickupInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflinePickupInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflinePickupInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_PICKUP_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflinePickupInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];