@if(objectLength(getUserData) > 0) {
<section class="sec insplan py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="row">
                    <div class="col-md-12">
                        @if(getUserData?.kyc_status && getUserData?.kyc_status != globalConstant.status_match.approved)
                        {
                        @if(getUserData?.kyc_status == globalConstant.status_match.pending) {
                        <p class="fw-semi text-center mb-4">
                            <span class="text-success d-block fs-5 mb-1">Thanks For Applying</span>
                            <span class="d-block text-muted">Your KYC is Under Review, you will be notify <br>once your
                                documents get approved.</span>
                        </p>
                        }
                        @else if(getUserData?.kyc_status == globalConstant.status_match.rejected) {
                        <p class="text-center fw-semi mb-4">
                            <span class="text-danger d-block fs-5 mb-1">Your Application is Rejected - {{
                                getUserData?.kyc_rejected_remark || '' }}</span>
                            <span class="d-block">Please Update Your KYC
                                <a [routerLink]="[globalConstant.base_url, routesPospKyc.POSP_KYC || '', routesPospKyc.POSP_KYC_BASIC_DETAILS || '']"
                                    class="link"><u>Click Here</u></a>
                            </span>
                        </p>
                        }
                        @else if(getUserData?.kyc_status == globalConstant.status_match.no_activity ||
                        getUserData?.kyc_status == globalConstant.status_match.processing) {
                        <p class="text-center fw-semi mb-4">
                            <span class="text-success d-block fs-5 mb-1">Thanks For Applying</span>
                            <span class="d-block">Your KYC Documents is pending. Please Update Your KYC
                                <a [routerLink]="[globalConstant.base_url, routesPospKyc.POSP_KYC || '', routesPospKyc.POSP_KYC_BASIC_DETAILS || '']"
                                    class="link"><u>Click Here</u></a>
                            </span>
                        </p>
                        }
                        }
                        @else {
                        @if(getUserData?.verified_status == "" || getUserData?.verified_status == null) {
                        <p class="fw-semi text-center mb-4">
                            <span class="text-success d-block fs-5 mb-1">Thanks For Applying</span>
                            <span class="d-block text-muted">Your KYC is Under Review, you will be notify <br>once your
                                documents get approved.</span>
                        </p>
                        }
                        @else if(getUserData?.verified_status ==
                        globalConstant.posp_verified_status_match.registered_with_other_company) {
                        <p class="fw-semi text-center mb-4">
                            <span class="text-success d-block fs-5 mb-1">Thanks For Applying</span>
                            <span class="d-block text-danger">You Are Registered With Other Company</span>
                        </p>
                        }
                        @else if(getUserData?.verified_status ==
                        globalConstant.posp_verified_status_match.migration_request_in_processing) {
                        <p class="fw-semi text-center mb-4">
                            <span class="text-success d-block fs-5 mb-1">Thanks For Applying</span>
                            <span class="d-block text-danger">Your Migration Request is under Process</span>
                        </p>
                        }
                        @else if(getUserData?.verified_status ==
                        globalConstant.posp_verified_status_match.agent_deactivation_request_permanent) {
                        <p class="fw-semi text-center mb-4">
                            <span class="text-success d-block fs-5 mb-1">Thanks For Applying</span>
                            <span class="d-block text-danger">As per your request your account is Permanently
                                Deactivated</span>
                        </p>
                        }
                        }
                    </div>
                    <div class="col-md-5">
                        <div class="bg-success p-4 rounded text-center h-100 text-white">
                            <div class="user_pic">
                                <img src="images/icons/user.png" [alt]="projectName" />
                            </div>
                            <h2 class="text-white fs-6 fw-semi mt-3 mb-0">Welcome</h2>
                            <h3 class="text-white fs-6 mb-2 text-capitalize"> {{ getUserData?.full_name || '' }}</h3>
                            @if(getUserData?.kyc_status && getUserData?.kyc_status !=
                            globalConstant.status_match.approved) {
                            @if(getUserData?.kyc_status == globalConstant.status_match.pending) {
                            <p>आवेदन करने के लिए धन्यवाद, आपकी केवाईसी की समीक्षा चल रही है, आपको सूचित कर दिया जाएगा एक
                                बार जब आपके दस्तावेज़ स्वीकृत हो जाएं।</p>
                            }
                            @else if(getUserData?.kyc_status == globalConstant.status_match.rejected) {
                            <p>आपका आवेदन अस्वीकार कर दिया गया है, केवाईसी अपडेट करने के लिए कृपया नीचे दिए गए लिंक पर
                                क्लिक करें</p>
                            <p><a [routerLink]="[globalConstant.base_url, routesPospKyc.POSP_KYC || '', routesPospKyc.POSP_KYC_BASIC_DETAILS || '']"
                                    class="btn btn-white text-success rounded-1 btn-sm px-4">Update KYC</a></p>
                            }
                            @else if(getUserData?.kyc_status == globalConstant.status_match.no_activity ||
                            getUserData?.kyc_status == globalConstant.status_match.processing) {
                            <p>आपके केवाईसी दस्तावेज़ लंबित हैं, कृपया केवाईसी अपडेट करने के लिए नीचे दिए गए लिंक पर
                                क्लिक करें</p>
                            <p><a [routerLink]="[globalConstant.base_url, routesPospKyc.POSP_KYC || '', routesPospKyc.POSP_KYC_BASIC_DETAILS || '']"
                                    class="btn btn-white text-success rounded-1 btn-sm px-4">Update KYC</a></p>
                            }
                            } @else {
                            @if(getUserData?.verified_status == "" || getUserData?.verified_status == null) {
                            <p>आवेदन करने के लिए धन्यवाद, आपकी केवाईसी की समीक्षा चल रही है, आपको सूचित कर दिया जाएगा एक
                                बार जब आपके दस्तावेज़ स्वीकृत हो जाएं।</p>
                            }
                            @else if(getUserData?.verified_status ==
                            globalConstant.posp_verified_status_match.registered_with_other_company) {
                            <p>आप अन्य कंपनी के साथ पंजीकृत हैं</p>
                            }
                            @else if(getUserData?.verified_status ==
                            globalConstant.posp_verified_status_match.migration_request_in_processing) {
                            <p>आपका प्रवासन अनुरोध प्रक्रियाधीन है</p>
                            }
                            @else if(getUserData?.verified_status ==
                            globalConstant.posp_verified_status_match.agent_deactivation_request_permanent) {
                            <p>आपके अनुरोध के अनुसार आपका खाता स्थायी रूप से निष्क्रिय कर दिया गया है</p>
                            }
                            }
                        </div>
                    </div>
                    <div class="col-md-7 mt-4 mt-md-0">
                        <div class="row kyc_heading_light">
                            <div class="col-6 col-md-6 mb-3">
                                <div class="bg-white w-100 text-center py-4 border-bottom rounded-2 border-2 h-100">
                                    <span>
                                        <img src="images/icons/complete-training.png" [alt]="projectName" width="80" />
                                    </span>
                                    <p class="fw-semi pt-4 m-0">Complete Training</p>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 mb-3">
                                <div class="bg-white w-100 text-center py-4 border-bottom rounded-2 border-2 h-100">
                                    <span>
                                        <img src="images/icons/exam.png" [alt]="projectName" width="80" />
                                    </span>
                                    <p class="fw-semi pt-4 m-0">Complete Examination</p>
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="bg-white w-100 text-center py-4 border-bottom rounded-2 border-2 h-100">
                                    <span>
                                        <img src="images/icons/certificate.png" [alt]="projectName" width="80" />
                                    </span>
                                    <p class="fw-semi pt-4 m-0">Download Certificate</p>
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="bg-white w-100 text-center py-4 border-bottom rounded-2 border-2 h-100">
                                    <span>
                                        <img src="images/icons/earn-money.png" [alt]="projectName" width="80" />
                                    </span>
                                    <p class="fw-semi pt-4 m-0">Earn Money</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
}