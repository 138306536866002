import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from '../utilities/local-storage.service';
import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';

export const authDisableGuard: CanActivateFn = (route, state) => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);

  if (localStorageService.isLoggedIn(GLOBAL_CONSTANT?.session?.auth_token)) {
    router.navigate([GLOBAL_CONSTANT.base_url]);
    return false;
  } else {
    return true;
  }
};
