import { Routes } from '@angular/router';
import { pospProcessGuard } from '../../guards/posp-process.guard';
import { ROUTES_POSP_EXAM } from '../../constants/routes/posp/posp-exam.constants';
import { PospExamComponent } from '../../../posp/posp-exam/posp-exam.component';
import { PospReExamComponent } from '../../../posp/posp-exam/posp-re-exam/posp-re-exam.component';
import { PospExamQuestionComponent } from '../../../posp/posp-exam/posp-exam-question/posp-exam-question.component';

export const PospExamRoutes: Routes = [
    {
        path: ROUTES_POSP_EXAM.POSP_EXAM,
        data: { headerLayoutType: 'private-layout', footerLayoutType: 'remove-footer' },
        children: [
            {
                path: '',
                component: PospExamComponent,
                canActivate: [pospProcessGuard]
            },
            {
                path: ROUTES_POSP_EXAM.POSP_EXAM_RE_EXAM,
                component: PospReExamComponent,
                canActivate: [pospProcessGuard]
            },
            {
                path: ROUTES_POSP_EXAM.POSP_EXAM_QUESTION,
                component: PospExamQuestionComponent,
                canActivate: [pospProcessGuard]
            }
        ]
    }
];