import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_RETIREMENT_PLAN } from '../../constants/routes/offline-services/offline-retirement-plan.constants';
import { OfflineRetirementPlanStepOneComponent } from '../../../offline-services/retirement-plan/offline-retirement-plan-step-one/offline-retirement-plan-step-one.component';
import { OfflineRetirementPlanStepTwoComponent } from '../../../offline-services/retirement-plan/offline-retirement-plan-step-two/offline-retirement-plan-step-two.component';
import { OfflineRetirementPlanOrdersComponent } from '../../../offline-services-orders/retirement-plan/offline-retirement-plan-orders/offline-retirement-plan-orders.component';
import { OfflineRetirementPlanOrderDetailComponent } from '../../../offline-services-orders/retirement-plan/offline-retirement-plan-order-detail/offline-retirement-plan-order-detail.component';

export const OfflineRetirementPlanRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_STEP_ONE,
        component: OfflineRetirementPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineRetirementPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineRetirementPlanStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineRetirementPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineRetirementPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RETIREMENT_PLAN.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineRetirementPlanOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];