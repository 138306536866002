import { MatDateFormats } from '@angular/material/core';
import { GLOBAL_CONSTANT } from './global-constant.constants';

export const FORM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: GLOBAL_CONSTANT?.dateTime?.formField,
  },
  display: {
    dateInput: GLOBAL_CONSTANT?.dateTime?.formField,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
