import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_TRAVEL_INSURANCE } from '../../constants/routes/offline-services/offline-travel-insurance.constants';
import { OfflineTravelInsuranceStepOneComponent } from '../../../offline-services/travel-insurance/offline-travel-insurance-step-one/offline-travel-insurance-step-one.component';
import { OfflineTravelInsuranceStepTwoComponent } from '../../../offline-services/travel-insurance/offline-travel-insurance-step-two/offline-travel-insurance-step-two.component';
import { OfflineTravelInsuranceOrdersComponent } from '../../../offline-services-orders/travel-insurance/offline-travel-insurance-orders/offline-travel-insurance-orders.component';
import { OfflineTravelInsuranceOrderDetailComponent } from '../../../offline-services-orders/travel-insurance/offline-travel-insurance-order-detail/offline-travel-insurance-order-detail.component';

export const OfflineTravelInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineTravelInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineTravelInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineTravelInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineTravelInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineTravelInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRAVEL_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineTravelInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];