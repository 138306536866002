import { FormGroup, FormControl, Validators } from '@angular/forms';

export function getFormValidators(mobileNumber: string | null): FormGroup {
    const mobile = mobileNumber || '';
    return new FormGroup({
        mobile_number: new FormControl(mobile),
        otp1: new FormControl('', [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(1),
            Validators.pattern('^[0-9]*$')
        ]),
        otp2: new FormControl('', [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(1),
            Validators.pattern('^[0-9]*$')
        ]),
        otp3: new FormControl('', [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(1),
            Validators.pattern('^[0-9]*$')
        ]),
        otp4: new FormControl('', [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(1),
            Validators.pattern('^[0-9]*$')
        ])
    });
}
