import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { filter, map, Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { PublicHeaderComponent } from './common/layouts/public-header/public-header.component';
import { PrivateHeaderComponent } from './common/layouts/private-header/private-header.component';
import { SidebarHeaderComponent } from './common/layouts/sidebar-header/sidebar-header.component';
import { PublicFooterComponent } from './common/layouts/public-footer/public-footer.component';
import { SidebarFooterComponent } from './common/layouts/sidebar-footer/sidebar-footer.component';
import { LoaderComponent } from './common/loader/loader.component';
import { environment } from '../environments/environment';
import { AuthUsersService } from './common/utilities/auth-users.service';
import { GoogleAnalyticsTagService } from './common/utilities/google-analytics-tag.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    PublicHeaderComponent,
    PrivateHeaderComponent,
    SidebarHeaderComponent,
    PublicFooterComponent,
    SidebarFooterComponent,
    LoaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title: string = '';
  headerLayoutType: string = 'public-layout';
  footerLayoutType: string = 'public-layout';
  footerCompanyInfoLayoutType: boolean = false;

  isSeoTag: boolean = environment.seoTags;
  enableGoogleAnalytics: boolean = environment.enableGoogleAnalytics;

  isLoggedIn$!: Observable<boolean>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authUsersService: AuthUsersService,
    private router: Router,
    private metaSeo: Meta,
    private titleSeo: Title,
    private googleAnalyticsTagService: GoogleAnalyticsTagService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.routerState.root.snapshot)
    ).subscribe((routeSnapshot: any) => {
      this.headerLayoutType = routeSnapshot.firstChild?.data.headerLayoutType || 'public-layout';
      this.footerLayoutType = routeSnapshot.firstChild?.data.footerLayoutType || 'public-layout';
      this.footerCompanyInfoLayoutType = routeSnapshot.firstChild?.data.footerCompanyInfoLayoutType || false;
    });
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authUsersService.isLoggedIn$;
    if (this.enableGoogleAnalytics === true) {
      if (isPlatformBrowser(this.platformId)) {
        this.googleAnalyticsTagService.sendEvent('page_view', {
          page_location: window.location.href
        });
      }
    }

    if (this.isSeoTag === true) {
      this.titleSeo.setTitle('Insurance - Compare & Buy Insurance Plans - Health, Term, Life, Car, Bike, Investment - Telsysweb Insurance');
      this.metaSeo.addTags([
        { name: 'description', content: 'Compare insurance policies offered by various insurers in India & buy insurance policy online. Get instant quotes & save huge on premiums.' },
        { name: 'keywords', content: 'compare, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy' },
        { name: 'robots', content: 'index, follow' }
      ]);
    } else {
      this.titleSeo.setTitle('Insurance - Compare & Buy Insurance Plans - Health, Term, Life, Car, Bike, Investment - Telsysweb Insurance');
      this.metaSeo.addTags([
        { name: 'description', content: 'Compare insurance policies offered by various insurers in India & buy insurance policy online. Get instant quotes & save huge on premiums.' },
        { name: 'keywords', content: 'compare, insurance, life, car, health, travel, pension, investment, child, home, corporate, quotes, rates, online, plans, policy, best, India, companies, buy' },
        { name: 'robots', content: 'noindex, nofollow' }
      ]);
    }
  }
}
