<button (click)="sidetoggleMenu()" type="button" [ngClass]="{'active': isSideNavOpen}" class="mobile-nav-toggle d-md-none" aria-label="menu"><i class="lni lni-menu"></i></button>
<div class="custom-sidebar header_area main-nav" [ngClass]="{'sidenav-open': isSideNavOpen}">
    <!-- <div class="bg-success text-white py-2 px-3 fs-10 fw-semi text-uppercase">Main Dashboard Menu</div> -->
    <div class="dbmenu">
        <ul class="nav-menu">
            <li>
                <a [routerLink]="[globalConstant.base_url, routesUsers.USER_DASHBOARD || '']" routerLinkActive="active" (click)="closesidetoggleMenu()" class="dashboard">
                    <img src="images/icons/dashboarda.png" [alt]="projectName" width="16" />
                    Dashboard
                </a>
            </li>
            <li>
                <a [routerLink]="[globalConstant.base_url, routesUsers.USER_SERVICES || '']" routerLinkActive="active" (click)="closesidetoggleMenu()" class="services">
                    <img src="images/icons/re-file.png" [alt]="projectName" width="16" />
                    Services
                </a>
            </li>
            <!-- @if(!isProduction) {
                <li class="has-child">
                    <a [matMenuTriggerFor]="menuRenewal" class="{{ isMenuOpen('renewal') ? 'arrow_drop_up' : 'arrow_drop_down' }}" href="javascript:void(0)" (menuOpened)="onMenuOpened('renewal')" (menuClosed)="onMenuClosed('renewal')">
                        <img src="images/icons/renewable.png" [alt]="projectName" width="16" />
                        Renewal
                    </a>
                    <mat-menu #menuRenewal="matMenu" class="dbmatmenu">
                        <ul class="sidebar-content-ul sub-menu">
                            <li>
                                <a href="javascript:void(0);">
                                    - Bike Insurance
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    - Car Insurance
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    - Rickshaw Insurance
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    - Taxi Insurance
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    - Tractor Insurance
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    - Truck/Bus Insurance
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    - Truck/Pickup Insurance
                                </a>
                            </li>
                        </ul>
                    </mat-menu>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <img src="images/icons/idea.png" [alt]="projectName" width="16" />
                        Knowledge Bank
                    </a>
                </li>
                <li class="has-child">
                    <a [matMenuTriggerFor]="menuReport" class="{{ isMenuOpen('report') ? 'arrow_drop_up' : 'arrow_drop_down' }}" href="javascript:void(0)" (menuOpened)="onMenuOpened('report')" (menuClosed)="onMenuClosed('report')">
                        <img src="images/icons/report.png" [alt]="projectName" width="16" />
                        Report
                    </a>
                    <mat-menu #menuReport="matMenu" class="dbmatmenu">
                        <ul class="sidebar-content-ul sub-menu">
                            <li> <a href="javascript:void(0);">- Bookings</a></li>
                            <li> <a href="javascript:void(0);">- Product Wise</a></li>
                        </ul>
                    </mat-menu>
                </li>
                <li class="has-child">
                    <a [matMenuTriggerFor]="menuTicket" class="{{ isMenuOpen('ticket') ? 'arrow_drop_up' : 'arrow_drop_down' }}" href="javascript:void(0)" (menuOpened)="onMenuOpened('ticket')" (menuClosed)="onMenuClosed('ticket')">
                        <img src="images/icons/tickets.png" [alt]="projectName" width="16" />
                        Tickets
                    </a>
                    <mat-menu #menuTicket="matMenu" class="dbmatmenu">
                        <ul class="sidebar-content-ul sub-menu">
                            <li> <a href="javascript:void(0);">My Tickets</a></li>
                            <li> <a href="javascript:void(0);">New Ticket</a></li>
                            <li> <a href="javascript:void(0);">PO Tickets</a></li>
                        </ul>
                    </mat-menu>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <img src="images/icons/help.png" [alt]="projectName" width="16" />
                        Help
                    </a>
                </li>
            } -->
        </ul>
    </div>
</div>