<section class="sec sec-checkout pt-3 pb-5">
    <div class="container">
        <div class="row mt-3">
            @if(objectLength(vehicleDetail) > 0) {
                <div class="col-md-8">
                    <div class="border bg-white p-3 rounded-2">
                        <div class="cmnform onlinesvcform">
                            <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <p class="fw-bold fs-18">Proposal Form (Owner details)</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Title / (शीर्षक) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <select id="title" formControlName="title" class="form-control form-select">
                                                <option value="">-- Select Title --</option>
                                                <!-- @if(arrayLength(getInsuredTitle) > 0) {
                                                @for(data of getInsuredTitle; track i; let i=$index) {
                                                <option value="{{ data?.key || '' }}">{{ data?.label || '' }}</option>
                                                }
                                                } -->
                                            </select>
                                            <mat-error *ngIf="getErrorMessage('title')" class="custom-error">
                                                {{ getErrorMessage('title') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Full Name / (पूरा नाम) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="full_name" formControlName="full_name" id="full_name" placeholder="Full Name" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('full_name')" class="custom-error">
                                                {{ getErrorMessage('full_name') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Mobile Number / (मोबाइल नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput appNumericInput name="phone" formControlName="phone" id="phone" placeholder="Mobile Number" minlength="10" maxlength="10" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('phone')" class="custom-error">
                                                {{ getErrorMessage('phone') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Email ID / (ईमेल) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="email" formControlName="email" id="email" placeholder="Email ID" maxlength="100" type="email" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('email')" class="custom-error">
                                                {{ getErrorMessage('email') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-label class="fs-14 mb-1 custom-label">Address / (पता) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="address" formControlName="address" id="address" placeholder="Address" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('address')" class="custom-error">
                                                {{ getErrorMessage('address') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <p class="fw-bold fs-18 mt-2">Vehicle details</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Vehicle Number / (वाहन नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="vehicle_number" formControlName="vehicle_number" id="vehicle_number" placeholder="Vehicle Number:  (eg. RJ14AB1234)" maxlength="10" type="text" class="form-control" appUppercase />
                                            <mat-error *ngIf="getErrorMessage('vehicle_number')" class="custom-error">
                                                {{ getErrorMessage('vehicle_number') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Engine Number / (इंजन नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="engine_number" formControlName="engine_number" id="engine_number" placeholder="Engine Number" type="text" class="form-control" appUppercase />
                                            <mat-error *ngIf="getErrorMessage('engine_number')" class="custom-error">
                                                {{ getErrorMessage('engine_number') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Chassis Number / (चेसिस नंबर) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="chassis_number" formControlName="chassis_number" id="chassis_number" placeholder="Chassis Number" type="text" class="form-control" appUppercase />
                                            <mat-error *ngIf="getErrorMessage('chassis_number')" class="custom-error">
                                                {{ getErrorMessage('chassis_number') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Vehicle Registration Date / (वाहन पंजीकरण तिथि) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group icon-group mb-3">
                                            <input matInput [matDatepicker]="registration_date" [min]="minRegistrationDate" [max]="maxRegistrationDate"
                                                id="registration_date" formControlName="registration_date" placeholder="DD-MM-YYYY" type="text"
                                                class="form-control custom-input" autocomplete="off" (click)="openRegistrationDateDatepicker()"
                                                (keydown)="restrictNonNumericInput($event)" />
                                            <mat-icon matSuffix class="custom-icon icon">
                                                <mat-datepicker #registration_date></mat-datepicker>
                                            </mat-icon>
                                            <mat-error *ngIf="getErrorMessage('registration_date')" class="custom-error">
                                                {{ getErrorMessage('registration_date') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <p class="fw-bold fs-18 mt-2">Nominee Details</p>
                                <div class="row">
                                    <div class="col-md-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee First Name / (नॉमिनी प्रथम नाम) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="nominee_first_name" formControlName="nominee_first_name" id="nominee_first_name" placeholder="Nominee First Name" maxlength="70" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('nominee_first_name')" class="custom-error">
                                                {{ getErrorMessage('nominee_first_name') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee Middle Name / (नॉमिनी मध्य नाम) </mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="nominee_middle_name" formControlName="nominee_middle_name" id="nominee_middle_name" placeholder="Nominee Middle Name" maxlength="50" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('nominee_middle_name')" class="custom-error">
                                                {{ getErrorMessage('nominee_middle_name') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee Last Name / (नॉमिनी अंतिम नाम) </mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput name="nominee_last_name" formControlName="nominee_last_name" id="nominee_last_name" placeholder="Nominee Last Name" maxlength="50" type="text" class="form-control custom-input" />
                                            <mat-error *ngIf="getErrorMessage('nominee_last_name')" class="custom-error">
                                                {{ getErrorMessage('nominee_last_name') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee Relation / (नॉमिनी सबंध) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <select id="nominee_relation" formControlName="nominee_relation" class="form-control form-control-custom form-select-custom form-select">
                                                <option value="">-- Select Nominee Relation --</option>
                                                <!-- @if(arrayLength(getNomineeRelation) > 0) {
                                                @for(data of getNomineeRelation; track i; let i=$index) {
                                                <option value="{{ data?.key || '' }}">{{ data?.label || '' }}</option>
                                                }
                                                } -->
                                            </select>
                                            <mat-error *ngIf="getErrorMessage('nominee_relation')" class="custom-error">
                                                {{ getErrorMessage('nominee_relation') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-label class="fs-14 mb-1 custom-label">Nominee D.O.B / (नॉमिनी जन्म तिथि) <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group mb-3">
                                            <input matInput [matDatepicker]="nominee_dob" [min]="minNomineeDobDate" [max]="maxNomineeDobDate"
                                                id="nominee_dob" formControlName="nominee_dob" placeholder="DD-MM-YYYY" type="text"
                                                class="form-control custom-input" autocomplete="off" (click)="openNomineeDobDatepicker()"
                                                (keydown)="restrictNonNumericInput($event)" />
                                            <mat-icon matSuffix class="custom-icon icon">
                                                <mat-datepicker #nominee_dob></mat-datepicker>
                                            </mat-icon>
                                            <mat-error *ngIf="getErrorMessage('nominee_dob')" class="custom-error">
                                                {{ getErrorMessage('nominee_dob') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-label class="fs-14 mb-1 custom-label">Do you remember previous policy details? <span class="text-danger"> * </span></mat-label>
                                        <div class="form-group">
                                            <div class="form-check form-check-inline typeradio customradio">
                                                <input type="radio" id="remember_previous_policy_yes" name="remember_previous_policy" formControlName="remember_previous_policy" value="yes" class="form-check-input">
                                                <label for="remember_previous_policy_yes" class="form-check-label">Yes</label>
                                            </div>
                                            <div class="form-check form-check-inline typeradio customradio">
                                                <input type="radio" id="remember_previous_policy_no" name="remember_previous_policy" formControlName="remember_previous_policy" value="no" class="form-check-input">
                                                <label for="remember_previous_policy_no" class="form-check-label">No</label>
                                            </div>
                                            <mat-error *ngIf="getErrorMessage('remember_previous_policy')" class="custom-error">
                                                {{ getErrorMessage('remember_previous_policy') }}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mt-2">
                                        <div class="form-group text-center">
                                            <button type="submit" class="btn btn-success rounded-5 btn-lg px-5" [disabled]="isLoading">Save and Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mt-3 mt-md-0">
                    <div class="border bg-white p-3 rounded-2">
                        <!-- <div *ngIf="vehicleDetail$ | async as vehicleDetail"> -->
                            <div class="insure_meber">
                                <div class="inCard bg-white border-0 border-bottom p-0 pb-2 rounded-0">
                                    <div class="userview">
                                        <div class="fw-semi"><!-- {{ vehicleDetail?.['vehicle_model'] }} --></div>
                                        <div class="fs-13 fw-normal">
                                            <span><!-- {{ vehicleDetail?.['vehicle_number'] }} --></span>
                                            <span class="ms-1 ps-2 border-start border-green border-1">Year:
                                                <span class="fw-semi">
                                                    <!-- @if(vehicleDetail?.['registration_date'] &&
                                                    vehicleDetail?.['registration_date'] != '') {
                                                    {{ formatDate(vehicleDetail?.['registration_date']) }}
                                                    } -->
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </div> -->
                        <!-- <div *ngIf="insurerDetail$ | async as insurerDetail"> -->
                            <div class="card-top my-3">
                                <div class="fnc-img rounded-2 border p-2">
                                    <!-- <img [src]="insurerDetail?.['insurer_logo'] ?? 'images/default/default-insurance.png'"
                                        alt="{{ insurerDetail?.['insurer_logo'] }}" width="80" height="53" /> -->
                                </div>
                                <div class="fncName">
                                    <p class="plantype mb-0">Comprehensive</p>
                                    <p class="planvalue text-muted fs-14 mb-0">Vehicle value (IDV):
                                        <span class="fw-semi">
                                            <!-- {{ insurerDetail?.['currency_symbol'] }}
                                            {{ insurerDetail?.['idv']?.['default_idv'] || '' }} -->
                                        </span>
                                    </p>

                                </div>
                            </div>
                            <div class="cartamoutinfo">
                                <ul>
                                    <li>
                                        <div class="head fs-14">Basic own damage premium</div>
                                        <div class="value fw-normal fs-6"><i class="bx bx-rupee align-top"></i>153</div>
                                    </li>
                                    <li>
                                        <div class="head fs-14">Third party property damage</div>
                                        <div class="value fw-normal fs-6"><i class="bx bx-rupee align-top"></i>573</div>
                                    </li>
                                    <li class="last">
                                        <div class="head fw-semi">Net Premium</div>
                                        <div class="value">
                                            <!-- {{ insurerDetail?.['currency_symbol'] }}{{ insurerDetail?.['net_premium'] || ''
                                            }} -->
                                        </div>
                                    </li>
                                    <li>
                                        <div class="head fs-14">GST(18%)</div>
                                        <div class="value fw-normal fs-6"><i class="bx bx-plus align-top"></i><i
                                                class="bx bx-rupee align-top"></i>130</div>
                                    </li>
                                    <li class="last">
                                        <div class="head fw-semi">Total Premium</div>
                                        <div class="value">
                                            <!-- {{ insurerDetail?.['currency_symbol'] }}{{ insurerDetail?.['net_premium'] || ''
                                            }} -->
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        <!-- </div> -->
                    </div>
                </div>
            } @else {
                <div class="col-md-12 col-lg-12 text-center">
                    Vehicle details not found.
                </div>
            }
        </div>
    </div>
</section>
<app-insurance-terms-conditions></app-insurance-terms-conditions>