import { Action, createReducer, on } from '@ngrx/store';
import { storeSetCarInsuranceQuotesList, storeResetCarInsuranceQuotesList } from '../../../actions/online-services/car-insurance/car-insurance-quotes-list.actions';

export interface StoreCarInsuranceQuotesList { [key: string]: any; }

export interface StoreStateCarInsuranceQuotesList {
  storeData: StoreCarInsuranceQuotesList | null;
}

export const initialState: StoreStateCarInsuranceQuotesList = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetCarInsuranceQuotesList, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetCarInsuranceQuotesList, state => ({ ...state, storeData: null }))
);

export function reducerOnlineCarInsuranceQuotesList(state: StoreStateCarInsuranceQuotesList | undefined, action: Action) { return _storeReducer(state, action); }