import { Component } from '@angular/core';

@Component({
  selector: 'app-posp-blog',
  standalone: true,
  imports: [],
  templateUrl: './posp-blog.component.html'
})
export class PospBlogComponent {

}
