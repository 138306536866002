import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class PospKycBasicDetailsService {

  constructor(
    private apiService: ApiService
  ) { }

  getAgentType() {
    return this.apiService.request<any>('POST', 'agent-type');
  }

  getState() {
    return this.apiService.request<any>('GET', 'states');
  }
}
