import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TermsAndConditionsService } from './terms-and-conditions.service';
import { LoaderService } from '../../common/loader/loader.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [],
  templateUrl: './terms-and-conditions.component.html'
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private termsAndConditionsService: TermsAndConditionsService
  ) { }

  private apitermsAndConditionsSubscription: Subscription | undefined;

  public title: string = '';
  public termsCondinData: any[] = [];

  ngOnInit(): void {
    this.apiGetPageData();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  apiGetPageData() {
    this.loaderService.show();
    this.apitermsAndConditionsSubscription = this.termsAndConditionsService.getPageData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.loaderService.hide();
          let getData = response?.data;
          this.title = getData?.title;
          this.termsCondinData = getData?.data && this.arrayLength(getData?.data) > 0 ? getData.data : [];
        },
        (error) => {
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apitermsAndConditionsSubscription) {
      this.apitermsAndConditionsSubscription.unsubscribe();
    }
  }

}
