import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_TRACTOR_INSURANCE } from '../../constants/routes/offline-services/offline-tractor-insurance.constants';
import { OfflineTractorInsuranceStepOneComponent } from '../../../offline-services/tractor-insurance/offline-tractor-insurance-step-one/offline-tractor-insurance-step-one.component';
import { OfflineTractorInsuranceStepTwoComponent } from '../../../offline-services/tractor-insurance/offline-tractor-insurance-step-two/offline-tractor-insurance-step-two.component';
import { OfflineTractorInsuranceOrdersComponent } from '../../../offline-services-orders/tractor-insurance/offline-tractor-insurance-orders/offline-tractor-insurance-orders.component';
import { OfflineTractorInsuranceOrderDetailComponent } from '../../../offline-services-orders/tractor-insurance/offline-tractor-insurance-order-detail/offline-tractor-insurance-order-detail.component';

export const OfflineTractorInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineTractorInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineTractorInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineTractorInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineTractorInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineTractorInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TRACTOR_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineTractorInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];