@if(pageShowType == 'exam_instruction' && objectLength(getExamData) > 0) {
<section class="sec insplan py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="rounded shadow bg-white">
                    <p class="p-3 mb-0"><strong [innerHTML]="getExamData?.title"></strong></p>
                    <div class="border-top border-bottom px-3">
                        <div class="bg-success my-3">
                            <p class="text-white m-0 p-2" [innerHTML]="getExamData?.subtitle"></p>
                        </div>
                        @if(getExamData?.content && arrayLength(getExamData?.content) > 0) {
                        <ul class="inc-instuction my-3">
                            @for(data of getExamData?.content; track i; let i=$index) {
                            <li [innerHTML]="data"></li>
                            }
                        </ul>
                        }
                    </div>
                    <div class="d-flex align-items-center justify-content-center py-3">
                        <div class="text-center">
                            <a [routerLink]="[globalConstant.base_url, routesPospExam.POSP_EXAM || '', routesPospExam.POSP_EXAM_QUESTION || '']"
                                class="btn bg-success px-md-5 px-2 text-white">Confirm</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
} @else if(pageShowType == 'exam_passed' && objectLength(getExamData) > 0) {
<section class="sec insplan mt-5 mb-5 d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto">
                <div class="row">
                    <div class="col- col-sm-12 col-md-12 col-lg-12">
                        <div class="bg-lightgreen w-100 text-start border-bottom  rounded-2 border-2 mb-4">
                            <div class="py-1 px-4 py-3 bg-light-success">
                                <h2 class="fs-5 mb-0 text-center">Result Summary </h2>
                            </div>
                            <div class="col-md-12 pt-4 pb-2 px-4">
                                <div class="text-center">
                                    <img src="images/icons/trophy.png" [alt]="projectName" width="90" />
                                    <h3 class="fs-4 py-3">Congratulations </h3>
                                </div>
                            </div>
                            <div class="border_line"></div>
                            <div class="col-md-12 px-4">
                                <div class="text-center fs-3 pt-2 fw-semi text-success">
                                    <h3 class="m-0 fw-bold">PASS</h3>
                                </div>
                                <p class="text-center fs-5 m-0">Your score</p>
                                <p class="text-center fw-bold fs-2 text-success">
                                    {{ getExamData?.correct_answer ? getExamData?.correct_answer : 0 }} /
                                    {{ getExamData?.total_question ? getExamData?.total_question : 0 }}
                                </p>
                            </div>
                            @if(getExamData?.exam_certificate_doc && getExamData?.exam_certificate_doc != '') {
                                <div class="col-md-12 pt-3">
                                    <div class="form-group text-center mt-2 mb-4">
                                        <button (click)="downloadDocument(getExamData?.exam_certificate_doc)" class="btn btn-green rounded-5 px-5 btn-lg">
                                            Download Certificate
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
} @else if(pageShowType == 'exam_failed' && objectLength(getExamData) > 0) {
<section class="sec insplan mt-5 mb-5 d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-md-6 mx-auto">
                <div class="row">
                    <div class="col- col-sm-12 col-md-12 col-lg-12">
                        <div class="bg-lightgreen w-100 text-start border-bottom  rounded-2 border-2 mb-4">
                            <div class="py-1 px-4 py-3 bg-light-success">
                                <h2 class="fs-5 mb-0 text-center">Result Summary </h2>
                            </div>
                            <form>
                                <div class="col-md-12 pt-4 pb-2 px-4">
                                    <div class="text-center">
                                        <img src="images/icons/failed.png" [alt]="projectName" width="90" />
                                        <h3 class="fs-4 py-3">Oops! </h3>
                                    </div>
                                </div>
                                <div class="border_line"></div>
                                <div class="col-md-12 px-4">
                                    <div class="text-center fs-3 pt-2 fw-semi text-success">
                                        <h3 class="m-0 fw-bold text-danger"> FAILED!</h3>
                                    </div>
                                    <p class="text-center fs-5 m-0">Your score</p>
                                    <p class="text-center fw-bold fs-2 text-danger">
                                        {{ getExamData?.correct_answer ? getExamData?.correct_answer : 0 }} /
                                        {{ getExamData?.total_question ? getExamData?.total_question : 0 }}
                                    </p>
                                </div>
                                <div class="col-md-12 pt-3">
                                    <div class="form-group text-center mt-2 mb-4 exam-buttons">
                                        <a [routerLink]="[globalConstant.base_url, routesPospTraining.POSP_TRAINING || '']"
                                            class="btn btn-green rounded-5 px-5 btn-lg me-3"> Re-Training </a>
                                        <a [routerLink]="[globalConstant.base_url, routesPospExam.POSP_EXAM || '', routesPospExam.POSP_EXAM_RE_EXAM || '']"
                                            class="btn btn-green rounded-5 px-5 btn-lg"> Re-Exam </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
}