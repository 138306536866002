/* import { NgIf } from '@angular/common'; */
import { Component } from '@angular/core';
/* import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ONLINE_SERVICES_ID, ONLINE_SERVICES_STEP } from '../../../common/constants/services/online-services-url.constants';
import { LoaderService } from '../../../common/loader/loader.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';
import { StoreUtilitiesService } from '../../../common/utilities/store-utilities.service';
import { InsuranceTermsConditionsComponent } from '../../../common/layouts/insurance-terms-conditions/insurance-terms-conditions.component';
import { OnlineCarInsuranceChoosePlanService } from './online-car-insurance-choose-plan.service';

import { StoreCarInsuranceBasicDetail, StoreStateCarInsuranceBasicDetail } from '../../../store/reducers/online-services/car-insurance/car-insurance-basic-detail.reducers';
import { selectStoreCarInsuranceBasicDetail } from '../../../store/selectors/online-services/car-insurance/car-insurance-basic-detail.selectors';
import { storeSetCarInsuranceBasicDetail, storeResetCarInsuranceBasicDetail } from '../../../store/actions/online-services/car-insurance/car-insurance-basic-detail.actions';

import { StoreCarInsuranceQuotesList, StoreStateCarInsuranceQuotesList } from '../../../store/reducers/online-services/car-insurance/car-insurance-quotes-list.reducers';
import { selectStoreCarInsuranceQuotesList } from '../../../store/selectors/online-services/car-insurance/car-insurance-quotes-list.selectors';
import { storeSetCarInsuranceQuotesList, storeResetCarInsuranceQuotesList } from '../../../store/actions/online-services/car-insurance/car-insurance-quotes-list.actions'; */

@Component({
  selector: 'app-online-car-insurance-choose-plan',
  standalone: true,
  imports: [
    /* NgIf,
    ReactiveFormsModule,
    MatFormFieldModule,
    InsuranceTermsConditionsComponent */
  ],
  templateUrl: './online-car-insurance-choose-plan.component.html'
})
export class OnlineCarInsuranceChoosePlanComponent { /* implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  onLineServicesID = ONLINE_SERVICES_ID;
  onLineServicesStep = ONLINE_SERVICES_STEP;

  projectName = this.globalConstant?.projectName;

  isLoading = false;
  serverErrors: { [key: string]: string[] } = {};

  uuId: string | null = null;
  vehicleDetail: any = {};
  quotesList: any = {};

  getStoreVehicleDetail$: Observable<StoreCarInsuranceBasicDetail | null>;
  getStoreQuotesList$: Observable<StoreCarInsuranceQuotesList | null>;

  private apiGetVehicleDetailSubscription: Subscription | undefined;
  private apiGetQuotesListSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{ 
      selectStoreCarInsuranceBasicDetail: StoreStateCarInsuranceBasicDetail,
      selectStoreCarInsuranceQuotesList: StoreStateCarInsuranceQuotesList
    }>,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private storeUtilitiesService: StoreUtilitiesService,
    private onlineCarInsuranceChoosePlanService: OnlineCarInsuranceChoosePlanService
  ) {
    this.getStoreVehicleDetail$ = this.store.pipe(select(selectStoreCarInsuranceBasicDetail));
    this.getStoreQuotesList$ = this.store.pipe(select(selectStoreCarInsuranceQuotesList));
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
    });
    this.loaderService.show();
    this.storeUtilitiesService.processStore(
      this.getStoreVehicleDetail$,
      () => this.apiGetVehicleDetailData(),
      (data) => this.setStoreVehicleDetailData(data)
    );

    this.storeUtilitiesService.processStore(
      this.getStoreQuotesList$,
      () => this.apiGetQuotesListData(),
      (data) => this.setStoreQuotesListData(data)
    );
    this.loaderService.hide();
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  formatDate(date: string): string {
    return this.momentUtilitiesService.formatDate(date, this.globalConstant.dateTime.yearShow);
  }

  async apiGetVehicleDetailData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      let params = {
        'uuid': this.uuId
      };
      this.apiGetVehicleDetailSubscription = this.onlineCarInsuranceChoosePlanService.getVehicleDetail(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.store.dispatch(storeResetCarInsuranceBasicDetail());
            this.store.dispatch(storeSetCarInsuranceBasicDetail({ storeData: getData }));
            this.setStoreVehicleDetailData(getData);
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  setStoreVehicleDetailData(data: StoreCarInsuranceBasicDetail | null) {
    if (!data) return;
    this.vehicleDetail = data;
  }

  async apiGetQuotesListData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      let params = {
        'quote_uuid': this.uuId,
        'insurer_id': 2
      };
      this.apiGetQuotesListSubscription = this.onlineCarInsuranceChoosePlanService.getQuotesList(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.store.dispatch(storeResetCarInsuranceQuotesList());
            this.store.dispatch(storeSetCarInsuranceQuotesList({ storeData: getData }));
            this.setStoreQuotesListData(getData);
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  setStoreQuotesListData(data: StoreCarInsuranceBasicDetail | null) {
    if (!data) return;
    this.quotesList = data;
  }

  ngOnDestroy(): void {
    if (this.apiGetVehicleDetailSubscription) {
      this.apiGetVehicleDetailSubscription.unsubscribe();
    }
    if (this.apiGetQuotesListSubscription) {
      this.apiGetQuotesListSubscription.unsubscribe();
    }
  } */
}
