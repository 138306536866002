import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appNumericInput]',
  standalone: true
})
export class NumericInputDirective {
  @Input() customMaxLength: number | undefined;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const maxLength = parseInt(inputElement.getAttribute('maxlength') || '0', 10) || this.customMaxLength || 0;
    const inputValue = inputElement.value;
    const sanitizedValue = inputValue.replace(/\D/g, '');

    if (sanitizedValue.length >= maxLength) {
      inputElement.value = sanitizedValue.slice(0, maxLength);
    } else {
      inputElement.value = sanitizedValue;
    }
  }
}
