import { Routes } from '@angular/router';
import { ROUTES_BLOG } from '../../constants/routes/blog/blog.constants';
import { BlogComponent } from '../../../blog/blog.component';
import { BlogDetailComponent } from '../../../blog/blog-detail/blog-detail.component';

export const BlogRoutes: Routes = [
    {
        path: ROUTES_BLOG.INDEX,
        component: BlogComponent
    },
    {
        path: ROUTES_BLOG.DETAIL + '/:slug',
        component: BlogDetailComponent
    }
];