import { Injectable } from '@angular/core';
import { ApiService } from '../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineServicesOrdersService {

  constructor(
    private apiService: ApiService
  ) { }

  getServicesCount() {
    return this.apiService.request<any>('GET_AUTH', 'offline/service-count');
  }
}
