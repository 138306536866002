export const ROUTES_PAGES = {
  HOME: '',
  ABOUT_US: 'about-us',
  CONTACT_US: 'contact-us',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  POSP_TERMS_AND_CONDITIONS: 'posp-terms-and-conditions',
  CAREER: 'career',
  FAQ: 'faqs',
  PRIVACY_POLICY: 'privacy-policy',
  REFUND_AND_CANCELLATION: 'refund-and-cancellation'
};