import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ROUTES_POSP_EXAM } from '../../../common/constants/routes/posp/posp-exam.constants';
import { LoaderService } from '../../../common/loader/loader.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { navigatePopsError } from '../../../common/utilities/navigate-posp-status';
import { PospReExamService } from './posp-re-exam.service';

@Component({
  selector: 'app-posp-re-exam',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './posp-re-exam.component.html'
})
export class PospReExamComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPospExam = ROUTES_POSP_EXAM;

  getExamData: any = {};

  private apiReExamDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private pospReExamService: PospReExamService
  ) { }

  ngOnInit(): void {
    this.apiGetPageData();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  apiGetPageData() {
    this.loaderService.show();
    this.apiReExamDataSubscription = this.pospReExamService.getPageData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.loaderService.hide();
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            this.getExamData = response?.data;
          }
        },
        (error) => {
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
          const navigatedRoute = navigatePopsError(error);
          if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
            this.router.navigate(navigatedRoute.route as string[]);
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiReExamDataSubscription) {
      this.apiReExamDataSubscription.unsubscribe();
    }
  }

}
