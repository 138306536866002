import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { confirmationValidator, ifscCodeValidator } from '../../../common/utilities/form-rules';

export function getFormValidators(): FormGroup {
    const globalConstant = GLOBAL_CONSTANT;
    return new FormGroup({
        kyc_step: new FormControl(globalConstant?.posp_kyc_step_match?.bank_details || 'bank_details'),
        bank_account_number: new FormControl('', [
            Validators.required,
            Validators.maxLength(30)
        ]),
        confirm_bank_account_number: new FormControl('', [
            Validators.required,
            Validators.maxLength(30)
        ]),
        ifsc_code: new FormControl('', [
            Validators.required,
            Validators.minLength(11),
            Validators.maxLength(11),
            ifscCodeValidator
        ]),
        bank_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        bank_branch_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        ref_id: new FormControl('', [
            Validators.maxLength(50)
        ]),
        accept_t_c: new FormControl(false, [
            Validators.requiredTrue
        ]),
        cheque_doc: new FormControl('', [
            Validators.required
        ]),
        signature_doc: new FormControl('', [
            Validators.required
        ])
    }, {
        validators: confirmationValidator('bank_account_number', 'confirm_bank_account_number')
    });
}
