export const API_RESPONSE = {
  status: {
    success: true,
    error: false
  },
  status_codes: {
    success: 200,
    bad_request: 400,
    unauthorized: 401,
    exception: 400,
    method_not_allowed: 405,
    unprocessable_entity: 422,

    user_detail_not_found: 1001,
    home_route_open: 1002,
    select_course_route_open: 1003,
    kyc_route_open: 1004,
    training_route_open: 1005,
    exam_route_open: 1006
  }
};