import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_TAXI_INSURANCE } from '../../constants/routes/offline-services/offline-taxi-insurance.constants';
import { OfflineTaxiInsuranceStepOneComponent } from '../../../offline-services/taxi-insurance/offline-taxi-insurance-step-one/offline-taxi-insurance-step-one.component';
import { OfflineTaxiInsuranceStepTwoComponent } from '../../../offline-services/taxi-insurance/offline-taxi-insurance-step-two/offline-taxi-insurance-step-two.component';
import { OfflineTaxiInsuranceOrdersComponent } from '../../../offline-services-orders/taxi-insurance/offline-taxi-insurance-orders/offline-taxi-insurance-orders.component';
import { OfflineTaxiInsuranceOrderDetailComponent } from '../../../offline-services-orders/taxi-insurance/offline-taxi-insurance-order-detail/offline-taxi-insurance-order-detail.component';

export const OfflineTaxiInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineTaxiInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineTaxiInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineTaxiInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineTaxiInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineTaxiInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TAXI_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineTaxiInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];