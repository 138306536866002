import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_OFFLINE_HEALTH_INSURANCE } from '../../constants/routes/offline-services/offline-health-insurance.constants';
import { OfflineHealthInsuranceStepOneComponent } from '../../../offline-services/health-insurance/offline-health-insurance-step-one/offline-health-insurance-step-one.component';
import { OfflineHealthInsuranceStepTwoComponent } from '../../../offline-services/health-insurance/offline-health-insurance-step-two/offline-health-insurance-step-two.component';
import { OfflineHealthInsuranceOrdersComponent } from '../../../offline-services-orders/health-insurance/offline-health-insurance-orders/offline-health-insurance-orders.component';
import { OfflineHealthInsuranceOrderDetailComponent } from '../../../offline-services-orders/health-insurance/offline-health-insurance-order-detail/offline-health-insurance-order-detail.component';

export const OfflineHealthInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineHealthInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineHealthInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineHealthInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineHealthInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineHealthInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_HEALTH_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineHealthInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];