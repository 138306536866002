import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-detail',
  standalone: true,
  imports: [],
  templateUrl: './blog-detail.component.html'
})
export class BlogDetailComponent {

}
