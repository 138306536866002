import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_RICKSHAW_INSURANCE } from '../../constants/routes/offline-services/offline-rickshaw-insurance.constants';
import { OfflineRickshawInsuranceStepOneComponent } from '../../../offline-services/rickshaw-insurance/offline-rickshaw-insurance-step-one/offline-rickshaw-insurance-step-one.component';
import { OfflineRickshawInsuranceStepTwoComponent } from '../../../offline-services/rickshaw-insurance/offline-rickshaw-insurance-step-two/offline-rickshaw-insurance-step-two.component';
import { OfflineRickshawInsuranceOrdersComponent } from '../../../offline-services-orders/rickshaw-insurance/offline-rickshaw-insurance-orders/offline-rickshaw-insurance-orders.component';
import { OfflineRickshawInsuranceOrderDetailComponent } from '../../../offline-services-orders/rickshaw-insurance/offline-rickshaw-insurance-order-detail/offline-rickshaw-insurance-order-detail.component';

export const OfflineRickshawInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineRickshawInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineRickshawInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineRickshawInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineRickshawInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineRickshawInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_RICKSHAW_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineRickshawInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];