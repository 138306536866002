import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { NgIf } from '@angular/common';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ROUTES_POSP_KYC } from '../../../common/constants/routes/posp/posp-kyc.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { NumericInputDirective } from '../../../common/directives/numeric-input.directive';
import { handleFileSelection, handleReadFile } from '../../../common/utilities/form-rules';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { DownloadService } from '../../../common/utilities/download.service';
import { LoaderService } from '../../../common/loader/loader.service';
import { PospKycService } from '../../../common/utilities/posp-kyc.service';
import { navigatePopsKycProcessStatus } from '../../../common/utilities/navigate-posp-status';
import { getFormValidators } from './form-utils';

@Component({
  selector: 'app-posp-kyc-pan-aadhar-details',
  standalone: true,
  imports: [
    NgIf,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    NumericInputDirective
  ],
  templateUrl: './posp-kyc-pan-aadhar-details.component.html'
})
export class PospKycPanAadharDetailsComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPospKyc = ROUTES_POSP_KYC;

  projectName = this.globalConstant?.projectName;

  customForm: FormGroup;
  panCardDocFile: File | null = null;
  aadharFrontDocFile: File | null = null;
  aadharBackDocFile: File | null = null;
  serverErrors: { [key: string]: string[] } = {};
  panCardDocFileUrl: string = '';
  aadharFrontDocFileUrl: string = '';
  aadharBackDocFileUrl: string = '';

  isLoading = false;

  private apiGetFormDataSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private pospKycService: PospKycService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private downloadService: DownloadService
  ) {
    this.customForm = getFormValidators();
  }

  ngOnInit(): void {
    this.apiGetFormData();
  }

  isPdfOrImage(url: string): string {
    return this.utilitiesService.isPdfOrImage(url);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  apiGetFormData() {
    this.isLoading = true;
    this.apiGetFormDataSubscription = this.pospKycService.getFormData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.isLoading = false;
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            let userData = response?.data;
            const navigatedRoute = navigatePopsKycProcessStatus(userData);
            if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
              this.router.navigate(navigatedRoute.route as string[]);
            }
            let userDetail: any = {};
            if (response?.data?.posp_detail && this.utilitiesService.objectLength(response?.data?.posp_detail) > 0) {
              userDetail = response?.data?.posp_detail;
            }
            this.customForm.patchValue({
              pan_card: userDetail?.pan_card || '',
              aadhar_number: userDetail?.aadhar_number || ''
            });
            if (userDetail?.pan_card_doc && userDetail?.pan_card_doc != '') {
              this.panCardDocFileUrl = this.globalConstant?.storage_base_url + userDetail?.pan_card_doc;
              this.customForm.get('pan_card_doc')?.clearValidators();
              this.customForm.get('pan_card_doc')?.updateValueAndValidity();
            }
            if (userDetail?.aadhar_front_doc && userDetail?.aadhar_front_doc != '') {
              this.aadharFrontDocFileUrl = this.globalConstant?.storage_base_url + userDetail?.aadhar_front_doc;
              this.customForm.get('aadhar_front_doc')?.clearValidators();
              this.customForm.get('aadhar_front_doc')?.updateValueAndValidity();
            }
            if (userDetail?.aadhar_back_doc && userDetail?.aadhar_back_doc != '') {
              this.aadharBackDocFileUrl = this.globalConstant?.storage_base_url + userDetail?.aadhar_back_doc;
              this.customForm.get('aadhar_back_doc')?.clearValidators();
              this.customForm.get('aadhar_back_doc')?.updateValueAndValidity();
            }
          }
        },
        (error) => {
          this.isLoading = false;
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  onPanCardDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('pan_card_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.panCardDocFile = file;
      handleReadFile(this.panCardDocFile, (result: string) => {
        this.panCardDocFileUrl = result;
      });
    }
  }

  onPanCardDocFileRemove(): void {
    this.panCardDocFile = null;
    this.panCardDocFileUrl = '';
    this.customForm.get('pan_card_doc')?.reset();
    this.customForm.get('pan_card_doc')?.setValidators([Validators.required]);
    this.customForm.get('pan_card_doc')?.updateValueAndValidity();
  }

  onAadharFrontDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('aadhar_front_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.aadharFrontDocFile = file;
      handleReadFile(this.aadharFrontDocFile, (result: string) => {
        this.aadharFrontDocFileUrl = result;
      });
    }
  }

  onAadharFrontDocFileRemove(): void {
    this.aadharFrontDocFile = null;
    this.aadharFrontDocFileUrl = '';
    this.customForm.get('aadhar_front_doc')?.reset();
    this.customForm.get('aadhar_front_doc')?.setValidators([Validators.required]);
    this.customForm.get('aadhar_front_doc')?.updateValueAndValidity();
  }

  onAadharBackDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('aadhar_back_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.aadharBackDocFile = file;
      handleReadFile(this.aadharBackDocFile, (result: string) => {
        this.aadharBackDocFileUrl = result;
      });
    }
  }

  onAadharBackDocFileRemove(): void {
    this.aadharBackDocFile = null;
    this.aadharBackDocFileUrl = '';
    this.customForm.get('aadhar_back_doc')?.reset();
    this.customForm.get('aadhar_back_doc')?.setValidators([Validators.required]);
    this.customForm.get('aadhar_back_doc')?.updateValueAndValidity();
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const data = {
        kyc_step: this.customForm.value.kyc_step,
        pan_card: this.customForm.value.pan_card,
        aadhar_number: this.customForm.value.aadhar_number
      };
      let formData = this.formUtilitiesService.toFormData(data);
      if (this.panCardDocFile) {
        formData.append('pan_card_doc', this.panCardDocFile, this.panCardDocFile.name);
      }
      if (this.aadharFrontDocFile) {
        formData.append('aadhar_front_doc', this.aadharFrontDocFile, this.aadharFrontDocFile.name);
      }
      if (this.aadharBackDocFile) {
        formData.append('aadhar_back_doc', this.aadharBackDocFile, this.aadharBackDocFile.name);
      }
      this.apiSubmitFormDataSubscription = this.pospKycService.postData(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            this.router.navigate([this.globalConstant.base_url, this.routesPospKyc.POSP_KYC || '', this.routesPospKyc.POSP_KYC_EDUCATION_DETAILS || '']);
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetFormDataSubscription) {
      this.apiGetFormDataSubscription.unsubscribe();
    }
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }

}
