<section class="sec insplan pt-4 pb-5 user-sign-form">
    <div class="container">
        <div class="col-md-12 col-lg-10 mx-auto">
            <div class="row flex-row-reverse">
                <div class="col-md-6 col-lg-5 col-xl-5 offset-xl-1 my-auto">
                    <div class="bg-white box_design border shadow p-4 rounded text-center text-md-start">
                        <h2 class="fw-bold fs-4 mb-3 agent_reg text-center">{{ pageData?.login_form?.heading || 'POSP
                            Registration!' }}</h2>
                        <p class="mb-0 fw-bold" [innerHTML]="pageData?.login_form?.sub_heading"></p>
                        <div class="mt-2 mb-3 fw-semi text-success">Enter your mobile number for Signup </div>
                        <div class="row">
                            <div class="col-md-12 signform">
                                <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                    <div class="form-group icon-group icon-group-sign mb-4">
                                        <label for="mobile_number" class="control-label input-label">Mobile
                                            Number</label>
                                        <input id="mobile_number" formControlName="mobile_number"
                                            placeholder="Enter Mobile Number" type="tel" minlength="10" maxlength="10"
                                            class="form-control form-control-custom" appNumericInput />
                                        <span class="icon">
                                            <img src="images/icons/indiaflag.svg" [alt]="projectName" width="18"
                                                height="18" /> +91
                                        </span>
                                        <mat-error *ngIf="getErrorMessage('mobile_number')" class="custom-error">
                                            {{ getErrorMessage('mobile_number') }}
                                        </mat-error>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group text-center mt-2 mb-2">
                                                <button type="submit" aria-label="plans"
                                                    class="btn btn-success rounded-5 btn-lg w-100" [disabled]="isLoading">SEND OTP</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="text-center mt-2">
                                    Already Registered!! <a
                                        [routerLink]="[globalConstant.base_url, routesPosp.POSP || '', routesPosp.POSP_SIGN_IN || '']"
                                        class="text-green fw-semi">Click Here</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-7 col-xl-6 text-center mt-4 mt-lg-0">
                    <h2 class="text-left fs-5 mt-5 mb-4" [innerHTML]="pageData?.guide?.heading"></h2>
                    @if(pageData?.guide?.src && pageData?.guide?.src != '' && pageData?.guide?.src != null) {
                    <div class="tax-save-bx mb-2">
                        <img src="{{ pageData?.guide?.src }}" [alt]="pageData?.guide?.alt" width="250" height="300">
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</section>
@if(pageData?.posp_register_steps && objectLength(pageData?.posp_register_steps) > 0) {
<section class="sec insinfo">
    <div class="container">
        <div class="bg-white">
            <h2 class="fw-bold fs-2 mb-4 text-center" [innerHTML]="pageData?.posp_register_steps?.heading"></h2>
            @if(pageData?.posp_register_steps?.list && arrayLength(pageData?.posp_register_steps?.list) > 0) {
            <div class="row">
                @for(data of pageData?.posp_register_steps?.list; track i; let i=$index) {
                <div class="col-md-6 col-lg-3 mb-2">
                    <div class="bg_box_steps">
                        @if(data?.src && data?.src != '' && data?.src != null) {
                        <span>
                            <img src="{{ data?.src }}" [alt]="data?.alt" width="60">
                        </span>
                        }
                        <h2 [innerHTML]="data?.title"></h2>
                        <p class="m-0" [innerHTML]="data?.title"></p>
                    </div>
                </div>
                }
            </div>
            }
        </div>
    </div>
</section>
}

<section class="sec insure_bg bg-white">
    <div class="container">
        <div class="col-12">
            <div class="row align-items-center">
                @if(pageData?.training_details && objectLength(pageData?.training_details) > 0) {
                <div class="col-md-7">
                    <h2 class="fw-bold fs-2 mb-3" [innerHTML]="pageData?.training_details?.heading"></h2>
                    <p class="m-0" [innerHTML]="pageData?.training_details?.content"></p>
                    @if(pageData?.training_details?.data && arrayLength(pageData?.training_details?.data) > 0) {
                    <ul class="ins-nav mt-2">
                        @for(data of pageData?.training_details?.data; track i; let i=$index) {
                        <li [innerHTML]="data"></li>
                        }
                    </ul>
                    }
                </div>
                @if(pageData?.training_details?.src && pageData?.training_details?.src != '' &&
                pageData?.training_details?.src != null) {
                <div class="col-md-5 text-center mt-4 mt-md-0 my-auto">
                    <img src="{{ pageData?.training_details?.src }}" [alt]="pageData?.training_details?.alt" width="350"
                        height="304">
                </div>
                }
                }
                @if(pageData?.certificate_details && objectLength(pageData?.certificate_details) > 0) {
                @if(pageData?.certificate_details?.src && pageData?.certificate_details?.src != '' &&
                pageData?.certificate_details?.src != null) {
                <div class="col-md-5 order-2 order-md-0 text-center mt-4 mt-md-0 my-auto">
                    <img src="{{ pageData?.certificate_details?.src }}" [alt]="pageData?.certificate_details?.alt"
                        width="350" height="328">
                </div>
                }
                <div class="col-md-7 order-1">
                    <h2 class="fw-bold fs-2 mb-3" [innerHTML]="pageData?.certificate_details?.heading"></h2>
                    <p class="m-0" [innerHTML]="pageData?.certificate_details?.content"></p>
                    @if(pageData?.certificate_details?.data && arrayLength(pageData?.certificate_details?.data) > 0) {
                    <ul class="ins-nav mt-2">
                        @for(data of pageData?.certificate_details?.data; track i; let i=$index) {
                        <li [innerHTML]="data"></li>
                        }
                    </ul>
                    }
                </div>
                }
            </div>
        </div>
    </div>
</section>
@if(pageData?.posp_info && objectLength(pageData?.posp_info) > 0) {
<section class="wha-posp py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h3 class="fw-bold fs-2 mb-2" [innerHTML]="pageData?.posp_info?.heading"></h3>
                <div [innerHTML]="pageData?.posp_info?.content"></div>
            </div>
            @if(pageData?.posp_info?.src && pageData?.posp_info?.src != '' && pageData?.posp_info?.src != null) {
            <div class="col-md-6 text-center">
                <img src="{{ pageData?.posp_info?.src }}" [alt]="pageData?.posp_info?.alt" class="img-fluid" />
            </div>
            }
        </div>
    </div>
</section>
}
<div class="clearfix"></div>
@if(pageData?.posp_benefits && objectLength(pageData?.posp_benefits) > 0) {
<section class="sec insure pb-4 pt-4">
    <div class="container">
        <div class="row">
            <h3 class="fw-bold fs-2 mb-4 text-center" [innerHTML]="pageData?.posp_benefits?.heading"></h3>
            @if(pageData?.posp_benefits?.src && pageData?.posp_benefits?.src != '' && pageData?.posp_benefits?.src !=
            null) {
            <div class="col-md-6 my-auto text-center">
                <img src="{{ pageData?.posp_benefits?.src }}" [alt]="pageData?.posp_benefits?.alt">
            </div>
            }
            <div class="col-md-6">
                @if(pageData?.posp_benefits?.list && arrayLength(pageData?.posp_benefits?.list) > 0) {
                @for(data of pageData?.posp_benefits?.list; track i; let i=$index) {
                <div class="poin_one">
                    @if(data?.src && data?.src != '' && data?.src != null) {
                    <div class="img_icons-os">
                        <img src="{{ data?.src }}" [alt]="data?.alt" width="50">
                    </div>
                    }
                    <div class="details_info">
                        <p class="m-0 fs-5 fw-semi" [innerHTML]="data?.title"></p>
                        <p class="m-0" [innerHTML]="data?.sub_title"></p>
                    </div>
                </div>
                }
                }
            </div>
        </div>
    </div>
</section>
}
@if(pageData?.eligibility_criteria && objectLength(pageData?.eligibility_criteria) > 0) {
<section class="sec insure_bg">
    <div class="container">
        <div class="col-12">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="text-green text-uppercase fw-bold fs-5 mb-1"
                        [innerHTML]="pageData?.eligibility_criteria?.heading_top"></div>
                    <h2 class="fw-bold fs-2 mb-3" [innerHTML]="pageData?.eligibility_criteria?.heading"></h2>
                    <p [innerHTML]="pageData?.eligibility_criteria?.content"></p>
                    @if(pageData?.eligibility_criteria?.data && arrayLength(pageData?.eligibility_criteria?.data) > 0) {
                    <ul class="ins-nav mt-4">
                        @for(data of pageData?.eligibility_criteria?.data; track i; let i=$index) {
                        <li [innerHTML]="data"></li>
                        }
                    </ul>
                    }
                </div>
                @if(pageData?.eligibility_criteria?.src != '' && pageData?.eligibility_criteria?.src != null) {
                <div class="col-md-6 text-center mt-md-0 my-auto">
                    <img src="{{ pageData?.eligibility_criteria?.src }}" [alt]="pageData?.eligibility_criteria?.alt"
                        width="350" height="328">
                </div>
                }
            </div>
        </div>
    </div>
</section>
}
@if(pageData?.posp_insurance_type && objectLength(pageData?.posp_insurance_type) > 0) {
<section class="sec insure pb-4 pt-4">
    <div class="container">
        <div class="row">
            <h3 class="fw-bold fs-2 mb-0 text-center" [innerHTML]="pageData?.posp_insurance_type?.heading"></h3>
            <p class="text-center mb-4" [innerHTML]="pageData?.posp_insurance_type?.content"></p>
            @if(pageData?.posp_insurance_type?.data && arrayLength(pageData?.posp_insurance_type?.data) > 0) {
            <div class="col-md-10 mx-auto">
                <div class="row">
                    @for(data of pageData?.posp_insurance_type?.data; track i; let i=$index) {
                    <div class="col-md-6 col-lg-4 mb-2 mb-md-4">
                        <div class="bg_box_steps">
                            @if(data?.src != '' && data?.src != null) {
                            <span>
                                <img src="{{ data?.src }}" [alt]="data?.alt" width="60">
                            </span>
                            }
                            <h2 [innerHTML]="data?.title"></h2>
                            <p [innerHTML]="data?.sub_title"></p>
                        </div>
                    </div>
                    }
                </div>
            </div>
            }
        </div>
    </div>
</section>
}
@if(pageData?.our_partners && objectLength(pageData?.our_partners) > 0) {
<section class="sec insure_bg">
    <div class="container">
        <div class="row align-items-center">
            <h3 class="fw-bold fs-2 mb-0 text-center" [innerHTML]="pageData?.our_partners?.title"></h3>
            <p class="text-center mb-5" [innerHTML]="pageData?.our_partners?.sub_title"></p>
            @if(pageData?.our_partners?.logos && arrayLength(pageData?.our_partners?.logos) > 0) {
            <div class="col-12">
                <ul class="partner-list">
                    @for(data of pageData?.our_partners?.logos; track i; let i=$index) {
                    @if(data?.src != '' && data?.src != null) {
                    <li>
                        <div
                            class="partner-bx border rounded-2 d-flex align-items-center justify-content-center h-100 p-2">
                            <span class="partnerBg">
                                <img [src]="data?.src" alt="{{ data?.alt }}" width="110" height="65" />
                            </span>
                        </div>
                    </li>
                    }
                    }
                </ul>
            </div>
            }
        </div>
    </div>
</section>
}
@if(pageData?.posp_faqs && objectLength(pageData?.posp_faqs) > 0) {
<section class="sec faq pt-4">
    <div class="container">
        <div class="row">
            <div class="col-12 col-xl-8 mx-auto">
                <h2 class="fs-2 fw-bold text-center mb-4" [innerHTML]="pageData?.posp_faqs?.title"></h2>
                @if(pageData?.posp_faqs?.faqs && arrayLength(pageData?.posp_faqs?.faqs) > 0) {
                <div class="faq">
                    <div class="accordion" id="faqaccordion">
                        <mat-accordion>
                            @for(data of pageData?.posp_faqs?.faqs; track i; let i=$index) {
                            <mat-expansion-panel class="faqs-design" [expanded]="i === 0">
                                <mat-expansion-panel-header>
                                    <mat-panel-title [innerHTML]="data?.question"></mat-panel-title>
                                </mat-expansion-panel-header>
                                <div [innerHTML]="data?.answer"></div>
                            </mat-expansion-panel>
                            }
                        </mat-accordion>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</section>
}