import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_TERM_LIFE_INSURANCE } from '../../constants/routes/offline-services/offline-term-life-insurance.constants';
import { OfflineTermLifeInsuranceStepOneComponent } from '../../../offline-services/term-life-insurance/offline-term-life-insurance-step-one/offline-term-life-insurance-step-one.component';
import { OfflineTermLifeInsuranceStepTwoComponent } from '../../../offline-services/term-life-insurance/offline-term-life-insurance-step-two/offline-term-life-insurance-step-two.component';
import { OfflineTermLifeInsuranceOrdersComponent } from '../../../offline-services-orders/term-life-insurance/offline-term-life-insurance-orders/offline-term-life-insurance-orders.component';
import { OfflineTermLifeInsuranceOrderDetailComponent } from '../../../offline-services-orders/term-life-insurance/offline-term-life-insurance-order-detail/offline-term-life-insurance-order-detail.component';

export const OfflineTermLifeInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineTermLifeInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineTermLifeInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineTermLifeInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineTermLifeInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineTermLifeInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_TERM_LIFE_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineTermLifeInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];