import { Component } from '@angular/core';

@Component({
  selector: 'app-online-car-insurance-personal-details',
  standalone: true,
  imports: [],
  templateUrl: './online-car-insurance-personal-details.component.html'
})
export class OnlineCarInsurancePersonalDetailsComponent {

}
