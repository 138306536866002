import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_OFFLINE_BUSINESS_INSURANCE } from '../../constants/routes/offline-services/offline-business-insurance.constants';
import { OfflineBusinessInsuranceStepOneComponent } from '../../../offline-services/business-insurance/offline-business-insurance-step-one/offline-business-insurance-step-one.component';
import { OfflineBusinessInsuranceStepTwoComponent } from '../../../offline-services/business-insurance/offline-business-insurance-step-two/offline-business-insurance-step-two.component';
import { OfflineBusinessInsuranceOrdersComponent } from '../../../offline-services-orders/business-insurance/offline-business-insurance-orders/offline-business-insurance-orders.component';
import { OfflineBusinessInsuranceOrderDetailComponent } from '../../../offline-services-orders/business-insurance/offline-business-insurance-order-detail/offline-business-insurance-order-detail.component';

export const OfflineBusinessInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineBusinessInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineBusinessInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineBusinessInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineBusinessInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineBusinessInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUSINESS_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineBusinessInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];