@if(arrayLength(chapterContent) > 0) {
<section class="sec insplan py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-10 mx-auto">
                <div class="row">
                    <div class="chapter_training bg-white rounded p-2">
                        @for(data of chapterContent; track i; let i=$index) {
                        @if (data?.heading) {
                        <p><strong><u [innerHTML]="data?.heading"></u></strong></p>
                        } @else if (data?.p) {
                        <div [innerHTML]="data?.p"></div>
                        } @else if (data?.img && objectLength(data?.img) > 0) {
                        <div class="image d-flex align-items-center justify-content-center">
                            <img src="{{ data?.img?.src }}" alt="{{ data?.img?.alt || '' }}"
                                width="{{ data?.img?.width || 420 }}" height="{{ data?.img?.height || 130 }}" />
                        </div>
                        } @else if (data?.ul && arrayLength(data?.ul) > 0) {
                        <ul class="li-bullets">
                            @for(dataUl of data?.ul; track i; let i=$index) {
                            <li [innerHTML]="dataUl"></li>
                            }
                        </ul>
                        }
                        }
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center py-2">
                    @if(chapterId > 1) {
                    <div class="text-center me-3">
                        <button (click)="previousChapter()" [disabled]="chapterId <= 1" type="button"
                            class="btn px-md-5 px-2 border">Previous Chapter</button>
                    </div>
                    }
                    @if(trainingData?.total_chapter && trainingData?.total_chapter == chapterId) {
                    <div class="text-center">
                        <button (click)="nextChapter()" type="button"
                            class="btn bg-success px-md-5 px-2 text-white">Finish</button>
                    </div>
                    } @else {
                    <div class="text-center">
                        <button (click)="nextChapter()" type="button"
                            class="btn bg-success px-md-5 px-2 text-white">Next Chapter</button>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</section>
}