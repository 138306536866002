import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { ROUTES_USERS } from '../constants/routes/users/users.constants';
import { ROUTES_POSP_EXAM } from '../constants/routes/posp/posp-exam.constants';
import { ROUTES_POSP_KYC } from '../constants/routes/posp/posp-kyc.constants';
import { ROUTES_POSP_TRAINING } from '../constants/routes/posp/posp-training.constants';
import { API_RESPONSE } from '../constants/api-responses.constants';

export function navigatePopsStatus(userData: any): { status: true, route: string[] } | { status: false } {
  if (userData?.kyc_status && (
    userData?.kyc_status == GLOBAL_CONSTANT?.status_match?.no_activity ||
    userData?.kyc_status == GLOBAL_CONSTANT?.status_match?.processing
  )) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_KYC?.POSP_KYC, ROUTES_POSP_KYC?.POSP_KYC_BASIC_DETAILS]
    };
  } else if (userData?.kyc_status && (
    userData?.kyc_status == GLOBAL_CONSTANT?.status_match?.pending ||
    userData?.kyc_status == GLOBAL_CONSTANT?.status_match?.rejected
  )) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_KYC?.POSP_KYC]
    };
  } else if (userData?.verified_status && (
    userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.registered_with_other_company ||
    userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.migration_request_in_processing ||
    userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.agent_deactivation_request_permanent
  )) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_KYC?.POSP_KYC]
    };
  } else if (userData?.training_status && (
    userData?.training_status == GLOBAL_CONSTANT?.status_match?.no_activity ||
    userData?.training_status == GLOBAL_CONSTANT?.status_match?.pending ||
    userData?.training_status == GLOBAL_CONSTANT?.status_match?.processing ||
    userData?.training_status == GLOBAL_CONSTANT?.status_match?.time_pending ||
    userData?.training_status == GLOBAL_CONSTANT?.status_match?.rejected
  )) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_TRAINING?.POSP_TRAINING]
    };
  } else if (userData?.exam_status && (
    userData?.exam_status == GLOBAL_CONSTANT?.status_match?.no_activity ||
    userData?.exam_status == GLOBAL_CONSTANT?.status_match?.pending ||
    userData?.exam_status == GLOBAL_CONSTANT?.status_match?.processing ||
    userData?.exam_status == GLOBAL_CONSTANT?.status_match?.failed
  )) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_EXAM?.POSP_EXAM]
    };
  } else {
    return {
      status: false
    };
  }
}

export function navigatePopsKycProcessStatus(userData: any): { status: true, route: string[] } | { status: false } {

  let isCheckTraining = false;
  let isCheckExam = false;
  if (userData?.kyc_status && userData?.kyc_status == GLOBAL_CONSTANT?.status_match?.approved) {
    isCheckTraining = true;
  } else {
    if (userData?.kyc_status && userData?.kyc_status == GLOBAL_CONSTANT?.status_match?.pending) {
      return {
        status: true,
        route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_KYC?.POSP_KYC]
      };
    } else if (userData?.verified_status && userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.posp_nvoy_irdai_checked_done) {
      isCheckTraining = true;
    } else if (userData?.verified_status && (
      userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.registered_with_other_company ||
      userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.migration_request_in_processing ||
      userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.agent_deactivation_request_permanent
    )) {
      return {
        status: true,
        route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_KYC?.POSP_KYC]
      };
    }
  }

  if (isCheckTraining) {
    if (userData?.training_status && userData?.training_status == GLOBAL_CONSTANT?.status_match?.completed) {
      isCheckExam = true;
    } else if (userData?.training_status && (
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.no_activity ||
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.pending ||
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.processing ||
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.time_pending ||
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.rejected
    )) {
      return {
        status: true,
        route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_TRAINING?.POSP_TRAINING]
      };
    }
  }

  if (isCheckExam) {
    if (userData?.exam_status && userData?.exam_status == GLOBAL_CONSTANT?.status_match?.passed) {
      return {
        status: true,
        route: [GLOBAL_CONSTANT?.base_url, ROUTES_USERS?.USER_DASHBOARD]
      };
    }
    if (userData?.exam_status && (
      userData?.exam_status == GLOBAL_CONSTANT?.status_match?.no_activity ||
      userData?.exam_status == GLOBAL_CONSTANT?.status_match?.pending ||
      userData?.exam_status == GLOBAL_CONSTANT?.status_match?.processing ||
      userData?.exam_status == GLOBAL_CONSTANT?.status_match?.failed
    )) {
      return {
        status: true,
        route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_EXAM?.POSP_EXAM]
      };
    }
  }

  return {
    status: false
  };

}

export function navigatePopsKycStatus(userData: any): { status: true, route: string[] } | { status: false } {

  let isCheckTraining = false;
  let isCheckExam = false;
  if (userData?.kyc_status && userData?.kyc_status == GLOBAL_CONSTANT?.status_match?.approved) {
    if (userData?.verified_status && userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.posp_nvoy_irdai_checked_done) {
      isCheckTraining = true;
    }
  }

  if (isCheckTraining) {
    if (userData?.training_status && userData?.training_status == GLOBAL_CONSTANT?.status_match?.completed) {
      isCheckExam = true;
    } else if (userData?.training_status && (
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.no_activity ||
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.pending ||
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.processing ||
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.time_pending ||
      userData?.training_status == GLOBAL_CONSTANT?.status_match?.rejected
    )) {
      return {
        status: true,
        route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_TRAINING?.POSP_TRAINING]
      };
    }
  }

  if (isCheckExam) {
    if (userData?.exam_status && userData?.exam_status == GLOBAL_CONSTANT?.status_match?.passed) {
      return {
        status: true,
        route: [GLOBAL_CONSTANT?.base_url, ROUTES_USERS?.USER_DASHBOARD]
      };
    }
    if (userData?.exam_status && (
      userData?.exam_status == GLOBAL_CONSTANT?.status_match?.no_activity ||
      userData?.exam_status == GLOBAL_CONSTANT?.status_match?.pending ||
      userData?.exam_status == GLOBAL_CONSTANT?.status_match?.processing ||
      userData?.exam_status == GLOBAL_CONSTANT?.status_match?.failed
    )) {
      return {
        status: true,
        route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_EXAM?.POSP_EXAM]
      };
    }
  }

  return {
    status: false
  };
}

export function navigatePopsError(error: any): { status: true, route: string[] } | { status: false } {
  const res_code = error?.error?.res_code;

  if (res_code == API_RESPONSE?.status_codes?.home_route_open) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url]
    }
  } else if (res_code == API_RESPONSE?.status_codes?.select_course_route_open) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_TRAINING.POSP_TRAINING || '', ROUTES_POSP_TRAINING?.POSP_TRAINING_SELECT_COURSE]
    }
  } if (res_code == API_RESPONSE?.status_codes?.kyc_route_open) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_KYC?.POSP_KYC]
    }
  } else if (res_code == API_RESPONSE?.status_codes?.training_route_open) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_TRAINING?.POSP_TRAINING]
    }
  } else if (res_code == API_RESPONSE?.status_codes?.exam_route_open) {
    return {
      status: true,
      route: [GLOBAL_CONSTANT?.base_url, ROUTES_POSP_EXAM?.POSP_EXAM]
    }
  } else {
    return {
      status: false
    }
  }
}
