<section class="sec faq pt-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xl-12 mx-auto">
        <h2 class="fs-2 fw-bold text-center mb-4">Frequently Asked Questions</h2>
        <div class="faq">
          <div class="accordion" id="faqaccordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="faqOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                  data-bs-target="#faqcollapseOne" aria-expanded="true" aria-controls="faqcollapseOne"> What is the
                  Insurance?</button>
              </h2>
              <div id="faqcollapseOne" class="accordion-collapse collapse show" aria-labelledby="faqOne"
                data-bs-parent="#faqaccordion">
                <div class="accordion-body">Insurance is a financial arrangement where individuals or entities pay
                  premiums to an insurance company in exchange for protection against specified risks. This protection
                  involves the insurer compensating policyholders for covered loss or damage.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>