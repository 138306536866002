import { Injectable } from '@angular/core';
import moment from 'moment';
import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';

@Injectable({
  providedIn: 'root'
})
export class MomentUtilitiesService {

  constructor() {
  }

  getCurrentDateFormatted(format: string = GLOBAL_CONSTANT?.dateTime?.formField): string {
    return moment().format(format);
  }

  getCurrentDateTimeAsDateTime(): Date {
    return moment().toDate();
  }

  getCustomDateTimeAsDateTime(dateInput: string | Date): Date {
    return moment(dateInput).toDate();
  }

  getDBDateTimeShow(date: string, format: string = GLOBAL_CONSTANT?.dateTime?.dbDateTimeShow): string {
    return moment(date).format(format);
  }

  getDBDateShow(date: string, format: string = GLOBAL_CONSTANT?.dateTime?.dbDateShow): string {
    return moment(date).format(format);
  }

  formInsertDateFormate(date?: Date | string, format: string = GLOBAL_CONSTANT?.dateTime?.formField): string {
    const momentDate = date ? moment(date) : moment();
    return momentDate.isValid() ? momentDate.format(format) : '';
  }

  subtractYearsFromDate(years: number, date?: Date | string): Date {
    const momentDate = date ? moment(date) : moment();
    return momentDate.subtract(years, 'years').toDate();
  }

  addOHourFromDate(hour: number, date?: Date | string): Date {
    const momentDate = date ? moment(date) : moment();
    return momentDate.add(hour, 'hour').toDate();
  }

  formatDate(date: string, formatTo: string): string {
    return moment(date).format(formatTo);
  }

}
