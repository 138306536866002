import { Injectable } from '@angular/core';
import { ApiService } from '../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineCommisionService {

  constructor(
    private apiService: ApiService
  ) { }

  getOfflineCommissionList() {
    return this.apiService.request<any>('GET', 'offline/commission-count');
  }
}
