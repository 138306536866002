import { Component, HostListener } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../constants/global-constant.constants';
import { ROUTES_PAGES } from '../../constants/routes/pages/pages.constants'
import { OFFLINE_SERVICES_ID } from '../../constants/services/offline-services-url.constants';
import { UtilitiesService } from '../../utilities/utilities.service';

@Component({
  selector: 'app-public-footer',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './public-footer.component.html'
})
export class PublicFooterComponent {
  private deferredPrompt: any;

  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;
  offlineServicesID = OFFLINE_SERVICES_ID;

  projectName = this.globalConstant?.projectName;

  constructor(
    private utilitiesService: UtilitiesService
  ) { }

  getOffLineServiceFullName(id: number): string {
    return this.utilitiesService.getOffLineServiceFullName(id);
  }

  getOffLineServiceURL(id: number, step: string): string[] {
    return this.utilitiesService.getOffLineServiceURL(id, step);
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: any) {
    event.preventDefault();
    this.deferredPrompt = event;

    const installButton = document.getElementById('installButton');
    if (installButton) {
      installButton.style.display = 'block';
    }
  }

  installPWA() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
    }
  }
}
