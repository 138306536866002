import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineRickshawInsuranceOrdersService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrders(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'offline/rickshaw-insurance/orders', formData);
  }

}
