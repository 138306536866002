import { Action, createReducer, on } from '@ngrx/store';
import { storeSetUserProfileGuard, storeResetUserProfileGuard } from '../actions/user-profile-guard.actions';

export interface StoreUserProfileGuard { [key: string]: any; }

export interface StoreStateUserProfileGuard {
  storeData: StoreUserProfileGuard | null;
}

export const initialState: StoreStateUserProfileGuard = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetUserProfileGuard, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetUserProfileGuard, state => ({ ...state, storeData: null }))
);

export function reducerUserProfileGuard(state: StoreStateUserProfileGuard | undefined, action: Action) { return _storeReducer(state, action); }