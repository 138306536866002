import { FormGroup, FormControl, Validators } from '@angular/forms';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        mobile_number: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('^[0-9]*$')
        ])
    });
}
