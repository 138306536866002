import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { NgIf } from '@angular/common';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ROUTES_PAGES } from '../../../common/constants/routes/pages/pages.constants';
import { ROUTES_POSP_KYC } from '../../../common/constants/routes/posp/posp-kyc.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { NumericInputDirective } from '../../../common/directives/numeric-input.directive';
import { handleFileSelection, handleReadFile } from '../../../common/utilities/form-rules';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { DownloadService } from '../../../common/utilities/download.service';
import { LoaderService } from '../../../common/loader/loader.service';
import { AuthUsersService } from '../../../common/utilities/auth-users.service';
import { PospKycService } from '../../../common/utilities/posp-kyc.service';
import { navigatePopsKycProcessStatus } from '../../../common/utilities/navigate-posp-status';
import { getFormValidators } from './form-utils';

@Component({
  selector: 'app-posp-kyc-bank-details',
  standalone: true,
  imports: [
    NgIf,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    NumericInputDirective,
  ],
  templateUrl: './posp-kyc-bank-details.component.html'
})
export class PospKycBankDetailsComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;
  routesPospKyc = ROUTES_POSP_KYC;
  projectName = this.globalConstant?.projectName;

  customForm: FormGroup;
  chequeDocFile: File | null = null;
  signatureDocFile: File | null = null;
  serverErrors: { [key: string]: string[] } = {};
  chequeDocFileUrl: string = '';
  signatureDocFileUrl: string = '';
  showRefIdInput: boolean = false;

  isLoading = false;

  private apiGetFormDataSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private downloadService: DownloadService,
    private authUsersService: AuthUsersService,
    private pospKycService: PospKycService,
  ) {
    this.customForm = getFormValidators();
  }

  ngOnInit(): void {
    this.apiGetFormData();
  }

  isPdfOrImage(url: string): string {
    return this.utilitiesService.isPdfOrImage(url);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  apiGetFormData() {
    this.isLoading = true;
    this.apiGetFormDataSubscription = this.pospKycService.getFormData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.isLoading = false;
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            let userData = response?.data;
            const navigatedRoute = navigatePopsKycProcessStatus(userData);
            if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
              this.router.navigate(navigatedRoute.route as string[]);
            }
            let userDetail: any = {};
            if (response?.data?.posp_detail && this.utilitiesService.objectLength(response?.data?.posp_detail) > 0) {
              userDetail = response?.data?.posp_detail;
            }
            this.customForm.patchValue({
              bank_account_number: userDetail?.bank_account_number || '',
              confirm_bank_account_number: userDetail?.bank_account_number || '',
              ifsc_code: userDetail?.ifsc_code || '',
              bank_name: userDetail?.bank_name || '',
              bank_branch_name: userDetail?.bank_branch_name || ''
            });
            if (userDetail?.cheque_doc && userDetail?.cheque_doc != '') {
              this.chequeDocFileUrl = this.globalConstant?.storage_base_url + userDetail?.cheque_doc;
              this.customForm.get('cheque_doc')?.clearValidators();
              this.customForm.get('cheque_doc')?.updateValueAndValidity();
            }
            if (userDetail?.signature_doc && userDetail?.signature_doc != '') {
              this.signatureDocFileUrl = this.globalConstant?.storage_base_url + userDetail?.signature_doc;
              this.customForm.get('signature_doc')?.clearValidators();
              this.customForm.get('signature_doc')?.updateValueAndValidity();
            }
          }
        },
        (error) => {
          this.isLoading = false;
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  onChequeDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('cheque_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.chequeDocFile = file;
      handleReadFile(this.chequeDocFile, (result: string) => {
        this.chequeDocFileUrl = result;
      });
    }
  }

  onChequeDocFileRemove(): void {
    this.chequeDocFile = null;
    this.chequeDocFileUrl = '';
    this.customForm.get('cheque_doc')?.reset();
    this.customForm.get('cheque_doc')?.setValidators([Validators.required]);
    this.customForm.get('cheque_doc')?.updateValueAndValidity();
  }

  onSignatureDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('signature_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.signatureDocFile = file;
      handleReadFile(this.signatureDocFile, (result: string) => {
        this.signatureDocFileUrl = result;
      });
    }
  }

  onSignatureDocFileRemove(): void {
    this.signatureDocFile = null;
    this.signatureDocFileUrl = '';
    this.customForm.get('signature_doc')?.reset();
    this.customForm.get('signature_doc')?.setValidators([Validators.required]);
    this.customForm.get('signature_doc')?.updateValueAndValidity();
  }

  toggleRefIdInput(): void {
    this.showRefIdInput = !this.showRefIdInput;
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      let accept_t_c = 'no';
      if (this.customForm.value.accept_t_c) {
        accept_t_c = 'yes';
      }
      const data = {
        kyc_step: this.customForm.value.kyc_step,
        bank_account_number: this.customForm.value.bank_account_number,
        confirm_bank_account_number: this.customForm.value.confirm_bank_account_number,
        ifsc_code: this.customForm.value.ifsc_code,
        bank_name: this.customForm.value.bank_name,
        bank_branch_name: this.customForm.value.bank_branch_name,
        ref_id: this.customForm.value.ref_id,
        accept_t_c: accept_t_c
      };
      let formData = this.formUtilitiesService.toFormData(data);
      if (this.chequeDocFile) {
        formData.append('cheque_doc', this.chequeDocFile, this.chequeDocFile.name);
      }
      if (this.signatureDocFile) {
        formData.append('signature_doc', this.signatureDocFile, this.signatureDocFile.name);
      }
      this.apiSubmitFormDataSubscription = this.pospKycService.postData(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.authUsersService.resetUserProfileStore();
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            this.router.navigate([this.globalConstant.base_url, this.routesPospKyc.POSP_KYC || '']);
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetFormDataSubscription) {
      this.apiGetFormDataSubscription.unsubscribe();
    }
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }

}
