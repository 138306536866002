import { Action, createReducer, on } from '@ngrx/store';
import { storeSetCarInsuranceBasicDetail, storeResetCarInsuranceBasicDetail } from '../../../actions/online-services/car-insurance/car-insurance-basic-detail.actions';

export interface StoreCarInsuranceBasicDetail { [key: string]: any; }

export interface StoreStateCarInsuranceBasicDetail {
  storeData: StoreCarInsuranceBasicDetail | null;
}

export const initialState: StoreStateCarInsuranceBasicDetail = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetCarInsuranceBasicDetail, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetCarInsuranceBasicDetail, state => ({ ...state, storeData: null }))
);

export function reducerOnlineCarInsuranceBasicDetail(state: StoreStateCarInsuranceBasicDetail | undefined, action: Action) { return _storeReducer(state, action); }