import { FormGroup, FormControl, Validators } from '@angular/forms';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        exam: new FormControl('', [
            Validators.required
        ]),
        question: new FormControl('', [
            Validators.required
        ]),
        answer: new FormControl('')
    });
}
