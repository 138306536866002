import { Injectable } from '@angular/core';
import { ApiService } from '../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class PospSignUpService {

  constructor(
    private apiService: ApiService
  ) { }

  getPageData() {
    return this.apiService.request<any>('GET', 'posp/posp-setting');
  }

  postData(formData: any) {
    return this.apiService.request<any>('POST', 'posp/sign-up', formData);
  }
}
