import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PospTermsAndConditionsService } from './posp-terms-and-conditions.service';
import { LoaderService } from '../../common/loader/loader.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';

@Component({
  selector: 'app-posp-terms-and-conditions',
  standalone: true,
  imports: [],
  templateUrl: './posp-terms-and-conditions.component.html'
})
export class PospTermsAndConditionsComponent implements OnInit {

  constructor(
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private pospTermsAndConditionsService: PospTermsAndConditionsService
  ) { }

  private apiPospTermsAndConditionsSubscription: Subscription | undefined;

  public termsCondinData: any[] = [];

  ngOnInit(): void {
    this.apiGetPageData();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  apiGetPageData() {
    this.loaderService.show();
    this.apiPospTermsAndConditionsSubscription = this.pospTermsAndConditionsService.getPageData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.loaderService.hide();
          let getData = response?.data;
          this.termsCondinData = getData?.data && this.arrayLength(getData?.data) > 0 ? getData.data : [];
        },
        (error) => {
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiPospTermsAndConditionsSubscription) {
      this.apiPospTermsAndConditionsSubscription.unsubscribe();
    }
  }

}
