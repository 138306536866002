import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';

export function getFormValidators(): FormGroup {
    const globalConstant = GLOBAL_CONSTANT;
    return new FormGroup({
        kyc_step: new FormControl(globalConstant?.posp_kyc_step_match?.basic_details || 'basic_details'),
        first_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(70)
        ]),
        middle_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        last_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(100),
            Validators.email
        ]),
        date_of_birth: new FormControl('', [
            Validators.required
        ]),
        gender: new FormControl('', [
            Validators.required
        ]),
        agent_type: new FormControl('', [
            Validators.required
        ]),
        state_code: new FormControl('', [
            Validators.required
        ]),
        city: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        pincode: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
        ]),
        address: new FormControl('', [
            Validators.required
        ]),
        profile_doc: new FormControl('', [
            Validators.required
        ])
    });
}
