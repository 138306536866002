<section class="sec insplan mt-5 pt-4 pb-5 user-sign-form">
    <div class="container">
        <div class="col-md-12 col-lg-10 mx-auto">
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-5 col-xl-5 my-auto">
                    <div class="bg-white box_design border shadow p-4 rounded text-center text-md-start">
                        <h2 class="fw-bold fs-4 mb-3 agent_reg text-center">Welcome Back</h2>
                        <div class="mt-2 mb-3 fw-semi text-success text-center">Enter your mobile number to start your session</div>
                        <div class="row">
                            <div class="col-md-12 signform">
                                <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                    <div class="form-group icon-group icon-group-sign mb-4">
                                        <label for="mobile_number" class="control-label input-label">Mobile
                                            Number</label>
                                        <input id="mobile_number" formControlName="mobile_number"
                                            placeholder="Enter Mobile Number" type="tel" minlength="10" maxlength="10"
                                            class="form-control form-control-custom" appNumericInput />
                                        <span class="icon">
                                            <img src="images/icons/indiaflag.svg" [alt]="projectName" width="18"
                                                height="18" /> +91
                                        </span>
                                        <mat-error *ngIf="getErrorMessage('mobile_number')" class="custom-error">
                                            {{ getErrorMessage('mobile_number') }}
                                        </mat-error>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group text-center mt-2 mb-2">
                                                <button type="submit" class="btn btn-success rounded-5 btn-lg w-100"
                                                [disabled]="isLoading">SEND OTP</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>