@if(objectLength(getExamData) > 0) {
<section class="sec insplan py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="rounded shadow bg-white">
                    <p class="p-3 mb-0"><strong [innerHTML]="getExamData?.title"></strong></p>
                    <div class="border-top border-bottom px-3">
                        <div class="bg-success my-3">
                            <p class="text-white m-0 p-2" [innerHTML]="getExamData?.subtitle"></p>
                        </div>
                        @if(getExamData?.content && arrayLength(getExamData?.content) > 0) {
                        <ul class="inc-instuction my-3">
                            @for(data of getExamData?.content; track i; let i=$index) {
                            <li [innerHTML]="data"></li>
                            }
                        </ul>
                        }
                    </div>
                    <div class="d-flex align-items-center justify-content-center py-3">
                        <div class="text-center">
                            <a [routerLink]="[globalConstant.base_url, routesPospExam.POSP_EXAM || '', routesPospExam.POSP_EXAM_QUESTION || '']"
                                class="btn bg-success px-md-5 px-2 text-white">Confirm</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
}