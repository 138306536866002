import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class PospTrainingChapterService {

  constructor(
    private apiService: ApiService
  ) { }

  getChapter(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'posp/training/chapter', formData);
  }

  readChapter(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'posp/training/read-chapter', formData);
  }
}
