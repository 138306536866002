import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, lastValueFrom } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { ROUTES_USERS } from '../constants/routes/users/users.constants';
import { ROUTES_POSP } from '../constants/routes/posp/posp.constants';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { CookieStorageService } from './cookie-storage.service';
import { MomentUtilitiesService } from './moment-utilities.service';
import { storeResetUserProfileGuard } from '../../store/actions/user-profile-guard.actions';
import { storeResetUserProfile } from '../../store/actions/user-profile.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthUsersService {

  globalConstant = GLOBAL_CONSTANT;
  routesUsers = ROUTES_USERS;
  routesPosp = ROUTES_POSP;

  private loggedIn = new BehaviorSubject<boolean>(this.checkLoginStatus());
  public readonly isLoggedIn$: Observable<boolean> = this.loggedIn.asObservable();

  constructor(
    private store: Store,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    private momentUtilitiesService: MomentUtilitiesService
  ) {
  }

  private checkLoginStatus(): boolean {
    return this.localStorageService.isLoggedIn(this.globalConstant?.session?.auth_token);
  }

  setIsLogin(): void {
    this.loggedIn.next(true);
  }

  setIsLogout(): void {
    this.loggedIn.next(false);
  }

  getProfileAllUsersData() {
    return this.apiService.request<any>('GET_AUTH', 'profile-get');
  }

  getProfileProcessAllUsersData() {
    return this.apiService.request<any>('GET_AUTH', 'profile-process');
  }

  getPospProfile() {
    return this.apiService.request<any>('GET_AUTH', 'posp/profile');
  }

  getUserProfile() {
    return this.apiService.request<any>('GET_AUTH', 'users/profile');
  }

  logoutAllUsers(): Observable<any> {
    return this.apiService.request<any>('GET_AUTH', 'sign-out');
  }

  resetUserProfileStore() {
    this.store.dispatch(storeResetUserProfileGuard());
    this.store.dispatch(storeResetUserProfile());
  }

  resetLocalStore() {
    this.localStorageService.removeItem(this.globalConstant?.session?.auth_token);
    this.cookieStorageService.deleteCookie(this.globalConstant?.cookie?.otp_verification_data);
    this.cookieStorageService.deleteCookie(this.globalConstant?.cookie?.service_login_data);
  }

  logoutUsers(): Promise<void> {
    return lastValueFrom(
      this.logoutAllUsers().pipe(
        switchMap((response) => {
          this.setIsLogout();
          this.resetUserProfileStore();
          this.resetLocalStore()
          return of(void 0);
        }),
        catchError((err) => {
          this.setIsLogout();
          this.resetUserProfileStore();
          this.resetLocalStore()
          return of(void 0);
        })
      )
    );
  }

  userSignIn(formData: any): Observable<any> {
    return this.apiService.request<any>('POST', 'users/login', formData);
  }

  getUserLogIn(formData: any): Observable<any> {
    return new Observable(observer => {
      if (formData?.phone) {
        let action_type = formData?.action_type ?? 'user_signin';
        this.userSignIn({ mobile_number: formData?.phone, action_type: action_type }).subscribe(
          (response) => {
            if (response?.status && response?.status == true && response?.data?.user_type) {
              let timeDifference = response?.data?.time_difference || 0;
              const loginData = {
                mobileNumber: formData?.phone,
                timeDifference: timeDifference,
                startTime: this.momentUtilitiesService.getCurrentDateTimeAsDateTime(),
              };
              if (response?.data?.user_type == this.globalConstant.user_type.posp) {
                if (formData?.service_id && formData?.service_id != '') {
                  observer.next({
                    status: true,
                    route: [this.globalConstant?.base_url || '/', this.routesPosp?.POSP || '/', this.routesPosp?.POSP_SIGN_IN_OTP_VERIFICATION || '/'],
                    loginData: loginData,
                    serviceData: formData
                  });
                  observer.complete();
                } else {
                  observer.next({
                    status: true,
                    route: [this.globalConstant?.base_url || '/', this.routesPosp?.POSP || '/', this.routesPosp?.POSP_SIGN_IN_OTP_VERIFICATION || '/'],
                    loginData: loginData,
                  });
                  observer.complete();
                }
              } else if (response?.data?.user_type == this.globalConstant.user_type.user) {
                if (formData?.service_id && formData?.service_id != '') {
                  observer.next({
                    status: true,
                    route: [this.globalConstant?.base_url || '/', this.routesUsers?.USER || '/', this.routesUsers?.USER_SIGN_IN_OTP_VERIFICATION || '/'],
                    loginData: loginData,
                    serviceData: formData
                  });
                  observer.complete();
                } else {
                  observer.next({
                    status: true,
                    route: [this.globalConstant?.base_url || '/', this.routesUsers?.USER || '/', this.routesUsers?.USER_SIGN_IN_OTP_VERIFICATION || '/'],
                    loginData: loginData
                  });
                  observer.complete();
                }
              } else {
                observer.next({
                  status: false
                });
                observer.complete();
              }
            } else {
              observer.next({
                status: false
              });
              observer.complete();
            }
          },
          (error) => {
            observer.next({
              status: false
            });
            observer.complete();
          }
        );
      } else {
        observer.next({
          status: false
        });
        observer.complete();
      }
    });
  }
}
