@if(arrayLength(termsCondinData) > 0) {
<section class="sec pt-4 mrtop-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="bg-white">
          @for(data of termsCondinData; track i; let i=$index) {
          <p><strong>{{data?.heading}}</strong></p>
          @if(data?.content_p_array && arrayLength(data?.content_p_array) > 0) {
          @for(datap of data?.content_p_array; track ip; let ip=$index) {
          <p [innerHTML]="datap"></p>
          }
          }
          @if(data?.data_array && arrayLength(data?.data_array) > 0) {
          @for(datada of data?.data_array; track ida; let ida=$index) {
          <p><strong [innerHTML]="datada?.heading"></strong></p>
          @if(datada?.content_p_array && arrayLength(datada?.content_p_array) > 0) {
          @for(datadap of datada?.content_p_array; track idap; let idap=$index) {
          <p [innerHTML]="datadap"></p>
          }
          }
          @if(datada?.content_li_array && arrayLength(datada?.content_li_array) > 0) {
          <ul class="mb-3">
            @for(datadali of datada?.content_li_array; track idali; let idali=$index) {
            <li [innerHTML]="datadali"></li>
            }
          </ul>
          }
          }
          }
          @if(data?.content_li_array && arrayLength(data?.content_li_array) > 0) {
          <ul class="mb-3">
            @for(datali of data?.content_li_array; track ip; let ip=$index) {
            <li [innerHTML]="datali"></li>
            }
          </ul>
          }
          }
        </div>
      </div>
    </div>
  </div>
</section>
}