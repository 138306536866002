<header class="private-header header-top-2 border-bottom">
    <div class="top-header">
        <div class="container">
            <ul class="siteInfo">
                <li>
                    <a href="mailto:support&#64;telsyswebinsurance.com" class="topheaderlink" aria-label="mailus">
                        <i class="bx bx-envelope align-middle"></i>
                        support&#64;telsyswebinsurance.com
                    </a>
                </li>
                <li>
                    <a href="tel:7413827827" class="topheaderlink" aria-label="support">
                        <i class="bx bx-support align-middle"></i>
                        7413827827
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="header-row d-flex justify-content-between align-items-center">
            <div class="logo">
                <a [routerLink]="[routesPages.HOME || '']">
                    <img src="images/header-logo.svg" [alt]="projectName" width="240" height="62" />
                </a>
            </div>
            <div class="menu d-md-flex align-items-center">
                <nav class="main-nav d-none d-xl-block px-3">
                    <div class="logo d-xl-none">
                        <a [routerLink]="[routesPages.HOME || '']">
                            <img src="images/header-logo.svg" [alt]="projectName" width="240" height="62" />
                        </a>
                    </div>
                </nav>
                @if(isLoggedIn$ | async) {
                    <div class="login_account">
                        <a (click)="logout()" class="btn btn-success">
                            <i class="bx bx-power-off align-middle"></i> Logout
                        </a>
                    </div>
                }
            </div>
        </div>
    </div>
</header>