import { Routes } from '@angular/router';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { ROUTES_OFFLINE_SMART_SAVING_PLAN } from '../../constants/routes/offline-services/offline-smart-saving-plan.constants';
import { OfflineSmartSavingPlanStepOneComponent } from '../../../offline-services/smart-saving-plan/offline-smart-saving-plan-step-one/offline-smart-saving-plan-step-one.component';
import { OfflineSmartSavingPlanStepTwoComponent } from '../../../offline-services/smart-saving-plan/offline-smart-saving-plan-step-two/offline-smart-saving-plan-step-two.component';
import { OfflineSmartSavingPlanOrdersComponent } from '../../../offline-services-orders/smart-saving-plan/offline-smart-saving-plan-orders/offline-smart-saving-plan-orders.component';
import { OfflineSmartSavingPlanOrderDetailComponent } from '../../../offline-services-orders/smart-saving-plan/offline-smart-saving-plan-order-detail/offline-smart-saving-plan-order-detail.component';

export const OfflineSmartSavingPlanRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_STEP_ONE,
        component: OfflineSmartSavingPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineSmartSavingPlanStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineSmartSavingPlanStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineSmartSavingPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineSmartSavingPlanOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_SMART_SAVING_PLAN.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineSmartSavingPlanOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];