import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refund-and-cancellation',
  standalone: true,
  imports: [],
  templateUrl: './refund-and-cancellation.component.html'
})
export class RefundAndCancellationComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }
  
}
