import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { panCardValidator } from '../../../common/utilities/form-rules';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        service_step: new FormControl(GLOBAL_CONSTANT?.offline_smart_saving_plans_step_match?.step_2 || 'plan_details'),
        uuid: new FormControl(''),
        pan_card: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            panCardValidator
        ]),
        date_of_birth: new FormControl('', [
            Validators.required
        ]),
        nominee_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        nominee_dob: new FormControl('', [
            Validators.required
        ]),
        nominee_relation: new FormControl('', [
            Validators.required
        ]),
        education: new FormControl('', [
            Validators.required
        ]),
        state_code: new FormControl('', [
            Validators.required
        ]),
        city: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        pincode: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
        ]),
        address: new FormControl('', [
            Validators.required
        ]),
        accept_t_c: new FormControl('', [
            Validators.required
        ]),
        pan_doc: new FormControl('', [
            Validators.required
        ]),
        aadhar_doc: new FormControl('', []),
        other_doc1: new FormControl('', []),
        other_doc2: new FormControl('', [])
    });
}
