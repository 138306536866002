<header class="sidebar-header header-top-2 border-bottom">
    <div class="top-header">
        <ul class="siteInfo">
            <li>
                <a href="mailto:.com" class="topheaderlink" aria-label="mailus">
                    <i class="bx bx-envelope align-middle"></i>
                    support&#64;telsyswebinsurance.com
                </a>
            </li>
            <li>
                <a href="tel:7413827827" class="topheaderlink" aria-label="support">
                    <i class="bx bx-support align-middle"></i>
                    7413827827
                </a>
            </li>
        </ul>
    </div>
    <div class="header-row">
        <div class="logo">
            <a [routerLink]="[globalConstant.base_url, routesUsers.USER_DASHBOARD || '']">
                <img src="images/header-logo.svg" [alt]="projectName" width="240" height="62" />
            </a>
        </div>
        <div class="header-user-info action_button">
            <div class="logout_btn">
                <div class="dropdown text-end user_lispro">
                    <a [matMenuTriggerFor]="menu" href="javascript:void(0);" class="d-block link-dark text-decoration-none dropdown-toggle {{ isProfileOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}" data-bs-toggle="dropdown" aria-expanded="false" (menuOpened)="onProfileOpened()" (menuClosed)="onProfileClosed()">
                        <div class="full_swidl">
                            <div class="img_user">
                                <img src="images/icons/user-icon.png" [alt]="projectName" width="230" height="43">
                            </div>
                            <div class="id_tril_emp">
                                <span>{{ profileData?.full_name || '' }}</span>
                                <span>{{ profileData?.user_id || '' }}</span>
                            </div>
                        </div>
                    </a>
                    <mat-menu class="header-user-info-detail bg-white" #menu="matMenu">
                        <ul class="header-user-ul text-small py-2">
                            <div class="profile_bar">
                                @if(profileData?.user_type && profileData?.user_type == globalConstant.user_type.posp) {
                                    <div class="user_certificate py-2">
                                        <img src="images/icons/certification-partner.png" [alt]="projectName" width="96" height="75" />
                                    </div>
                                }
                                <p class="m-0 fw-normal">{{ profileData?.full_name || '' }}</p>
                                <span class="user_id fw-normal">{{ profileData?.user_id || '' }}</span>
                                @if(profileData?.last_login_at != '' && profileData?.last_login_at != null) {
                                    <div class="last_login">
                                        <p class="fs-12 m-0 fw-normal">
                                            <strong>Last Login</strong> - {{ getDBDateTimeShow(profileData?.last_login_at)
                                            }}
                                        </p>
                                    </div>
                                }
                            </div>
                            @if(profileData?.user_type && profileData?.user_type == globalConstant.user_type.posp) {
                            <li>
                                <a [routerLink]="[globalConstant.base_url, routesPosp.POSP || '', routesPosp.POSP_PROFILE || '']" class="dropdown-item"> 
                                    <i class="bx bx-user align-middle"></i> My Profile
                                </a>
                            </li>
                            }
                            @if(profileData?.user_type && profileData?.user_type == globalConstant.user_type.posp && profileData?.posp_detail?.exam_certificate_doc != '') {
                                <li>
                                    <button (click)="downloadDocument(profileData?.posp_detail?.exam_certificate_doc)" class="dropdown-item">
                                        <i class="bx bx-file align-middle"></i> Download Certificate
                                    </button>
                                </li>
                            }
                            <!-- @if(!isProduction) {
                                <li>
                                    <a class="dropdown-item" href="javascript:void(0)">
                                        <i class="bx bx-alarm align-middle"></i> Statement</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:void(0)">
                                        <i class="bx bx-file align-middle"></i> TDS Certificate</a>
                                </li>
                            } -->
                            <li>
                                <a (click)="logout()" class="logout dropdown-item">
                                    <i class="bx bx-power-off align-middle"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="leftSideBar">
    <app-sidebar-navigation></app-sidebar-navigation>
</div>