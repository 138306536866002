import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [],
  templateUrl: './contact-us.component.html'
})
export class ContactUsComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
