import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appUpperCase]',
  standalone: true
})
export class UpperCaseDirective {
  constructor(private el: ElementRef<HTMLInputElement>) { }
  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    this.el.nativeElement.value = value.toUpperCase();
  }
}

