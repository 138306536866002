import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { NgIf } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { API_RESPONSE } from '../../common/constants/api-responses.constants';
import { ROUTES_POSP } from '../../common/constants/routes/posp/posp.constants';
import { NumericInputDirective } from '../../common/directives/numeric-input.directive';
import { MomentUtilitiesService } from '../../common/utilities/moment-utilities.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { FormUtilitiesService } from '../../common/utilities/form-utilities.service';
import { CookieStorageService } from '../../common/utilities/cookie-storage.service';
import { PospSignInService } from './posp-sign-in.service';
import { getFormValidators } from './form-utils';
import { LoaderService } from '../../common/loader/loader.service';

@Component({
  selector: 'app-posp-sign-in',
  standalone: true,
  imports: [
    RouterModule,
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    NumericInputDirective
  ],
  templateUrl: './posp-sign-in.component.html'
})
export class PospSignInComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPosp = ROUTES_POSP;

  projectName = this.globalConstant?.projectName;

  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};

  isLoading = false;

  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private pospSignInService: PospSignInService,
    private momentUtilitiesService: MomentUtilitiesService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private cookieStorageService: CookieStorageService,
  ) {
    this.customForm = getFormValidators();
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      let mobile_number = this.customForm.value.mobile_number;
      const data = {
        mobile_number: mobile_number,
        action_type: 'posp_signin'
      };
      this.apiSubmitFormDataSubscription = this.pospSignInService.postData(data).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            let timeDifference = 0;
            if (response?.data?.time_difference) {
              timeDifference = response?.data?.time_difference;
            }
            const stateData = {
              mobileNumber: mobile_number,
              timeDifference: timeDifference,
              startTime: this.momentUtilitiesService.getCurrentDateTimeAsDateTime()
            }
            this.cookieStorageService.setCookie(this.globalConstant?.cookie?.otp_verification_data, stateData, this.globalConstant?.cookie?.otp_verification_data_expire, 'minutes');
            this.router.navigate([this.globalConstant?.base_url, ROUTES_POSP?.POSP, ROUTES_POSP?.POSP_SIGN_IN_OTP_VERIFICATION]);
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }

}
