<div class="fixed-footer">
    <div class="w-100 py-4 text-center">
        <div class="container">
            <div class="row sidebar-footer">
                <div class="col-md-12">
                    <p class="fw-semi mb-2">POSP Partners is a brand of Telsysweb Insurance Brokers Private Limited
                    </p>
                    <ul class="contact_details">
                        <li>
                            <i class="bx bx-support"></i> 
                            <a href="tel:+917413827827">+91 7413827827</a>
                        </li>
                        <li>
                            <i class="bx bx-time"></i> 
                            <a href="#">10 AM to 8 PM (Monday - Sunday)</a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i> 
                            <a href="#">support&#64;telsyswebinsurance.com</a>
                        </li>
                    </ul>
                    <div class="p-2">
                        <p class="fs-14 mb-0">
                            Copyright © 2024 Telsysweb Insurance Brokers Private Limited. All rights reserved.
                        </p>
                    </div>
                    <div>
                        <p class="mb-2 fs-5 fw-semi">Follow us on:</p>
                        <ul class="social_media mb-4">
                            <li>
                                <a href="javascript:void(0);" aria-label="Facebook">
                                    <img src="images/icons/facebook.png" alt="Facebook" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" aria-label="Twitter">
                                    <img src="images/icons/instagram.png" alt="Instagram" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" aria-label="Twitter">
                                    <img src="images/icons/twitter-icon.png" alt="Twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" aria-label="Instagram">
                                    <img src="images/icons/linkedin.png" alt="Facebook" />
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" aria-label="Linkedin">
                                    <img src="images/icons/youtube.png" alt="Youtube" />
                                </a>
                            </li>
                        </ul>
                        <p class="mb-0 fs-14">
                            <strong>CIN</strong>: U67110RJ2022PTC083201 | <strong>Registered Office</strong> - Ist Floor, Web Plaza, Shyam Nagar, Benar Road, Jhotwara, Jaipur,
                        </p>
                        <p class="m-0 fs-14">
                            Telsysweb Insurance is now registered as a Direct Broker | <strong>Registration Certificate No</strong>: IRDAI/1026DB/2023, Composite Insurance Broker (Direct &amp; Reinsurance)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>