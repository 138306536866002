<section class="sec serviceForm basicInfo bg-lgwhite py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-6 mx-auto">
                <div class="border shadow-sm p-3 rounded-1 bg-white">
                    <div class="text-center mb-3">
                        <h1 class="fs-5 mb-1 fw-bold">Rickshaw Insurance Online</h1>
                        <div class="fs-18 fw-semi text-center">Let's Start Insurance Fill the Basic Details</div>
                    </div>
                    <div class="cmnform services-form">
                        <div class="indicators">
                            <div class="step-tabs step-current"><span>Basic Details</span></div>
                            <div class="step-tabs"><span>Insurance Details</span></div>
                        </div>
                        <div class="stpesForm mt-4">
                            <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                            <div class="row">
                                <div class="col-sm-12">
                                    <mat-label class="fs-14 mb-1 custom-label">Full Name / (नाम) <span class="text-danger"> * </span></mat-label>
                                    <div class="form-group icon-group mb-3">
                                        <input matInput name="full_name" formControlName="full_name" id="full_name" placeholder="Full Name" maxlength="100" type="text" class="form-control custom-input" />
                                        <mat-icon matSuffix class="custom-icon icon">
                                            <img src="images/icons/user.svg" [alt]="projectName" width="16" height="18">
                                        </mat-icon>
                                        <mat-error *ngIf="getErrorMessage('full_name')" class="custom-error">
                                            {{ getErrorMessage('full_name') }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <mat-label class="fs-14 mb-1 custom-label">Mobile Number / (मोबाइल नंबर) <span class="text-danger"> * </span></mat-label>
                                    <div class="form-group icon-group mb-3">
                                        <input matInput appNumericInput name="phone" formControlName="phone" id="phone" placeholder="Mobile Number" minlength="10" maxlength="10" type="text" class="form-control custom-input" />
                                        <mat-icon matSuffix class="custom-icon icon">
                                            <img src="images/icons/mobile.svg" [alt]="projectName" width="16" height="20">
                                        </mat-icon>
                                        <mat-error *ngIf="getErrorMessage('phone')" class="custom-error">
                                            {{ getErrorMessage('phone') }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <mat-label class="fs-14 mb-1 custom-label">Email ID / (ईमेल) <span class="text-danger"> * </span></mat-label>
                                    <div class="form-group icon-group mb-3">
                                        <input matInput name="email" formControlName="email" id="email" placeholder="Email ID" maxlength="100" type="email" class="form-control custom-input" />
                                        <mat-icon matSuffix class="custom-icon icon">
                                            <img src="images/icons/email.svg" [alt]="projectName" width="18" height="20">
                                        </mat-icon>
                                        <mat-error *ngIf="getErrorMessage('email')" class="custom-error">
                                            {{ getErrorMessage('email') }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group text-center">
                                        <button type="submit" class="btn btn-success rounded-5 btn-lg px-5"
                                            [disabled]="isLoading">
                                            NEXT
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>