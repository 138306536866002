import { reducerUserProfileGuard } from './reducers/user-profile-guard.reducers';
import { reducerUserProfile } from './reducers/user-profile.reducers';
import { reducerHome } from './reducers/home.reducers';
import { reducerOnlineTwoWheelerInsuranceBasicDetail } from './reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.reducers';
import { reducerOnlineCarInsuranceBasicDetail } from './reducers/online-services/car-insurance/car-insurance-basic-detail.reducers';
import { reducerOnlineTwoWheelerInsuranceQuotesList } from './reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.reducers';
import { reducerOnlineCarInsuranceQuotesList } from './reducers/online-services/car-insurance/car-insurance-quotes-list.reducers';

export const reducers = {
    selectStoreUserProfileGuard: reducerUserProfileGuard,
    selectStoreUserProfile: reducerUserProfile,
    selectStoreHome: reducerHome,
    selectStoreTwoWheelerInsuranceBasicDetail: reducerOnlineTwoWheelerInsuranceBasicDetail,
    selectStoreCarInsuranceBasicDetail: reducerOnlineCarInsuranceBasicDetail,
    selectStoreTwoWheelerInsuranceQuotesList: reducerOnlineTwoWheelerInsuranceQuotesList,
    selectStoreCarInsuranceQuotesList: reducerOnlineCarInsuranceQuotesList
};