import { Routes } from '@angular/router';
import { pospProcessGuard } from '../../guards/posp-process.guard';
import { ROUTES_POSP_TRAINING } from '../../constants/routes/posp/posp-training.constants';
import { PospTrainingComponent } from '../../../posp/posp-training/posp-training.component';
import { PospSelectCourseComponent } from '../../../posp/posp-training/posp-select-course/posp-select-course.component';
import { PospTrainingChapterComponent } from '../../../posp/posp-training/posp-training-chapter/posp-training-chapter.component';

export const PospTrainingRoutes: Routes = [
    {
        path: ROUTES_POSP_TRAINING.POSP_TRAINING,
        data: { headerLayoutType: 'private-layout', footerLayoutType: 'remove-footer' },
        children: [
            {
                path: '',
                component: PospTrainingComponent,
                canActivate: [pospProcessGuard]
            },
            {
                path: ROUTES_POSP_TRAINING.POSP_TRAINING_SELECT_COURSE,
                component: PospSelectCourseComponent,
                canActivate: [pospProcessGuard]
            },
            {
                path: ROUTES_POSP_TRAINING.POSP_TRAINING_CHAPTER + '/:chapterId',
                component: PospTrainingChapterComponent,
                canActivate: [pospProcessGuard]
            }
        ]
    }
];