import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsTagService {

  enableGoogleAnalytics: boolean = environment.enableGoogleAnalytics;

  private gtagFunction: ((...args: any[]) => void) | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (this.enableGoogleAnalytics === true) {
      if (isPlatformBrowser(this.platformId)) {
        this.loadGoogleAnalytics();
      }
    }
  }

  private loadGoogleAnalytics() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const scriptId = `gtag-script-${GLOBAL_CONSTANT.googleAnalyticID}`;
    if (document.getElementById(scriptId)) {
      this.gtagFunction = (window as any).gtag;
      if (this.gtagFunction) {
        /* console.log('Google Analytics script is already loaded.'); */
        this.initializeGtag();
      } else {
        console.warn('Google Analytics gtag function is not defined even though the script is present.');
      }
      return;
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GLOBAL_CONSTANT.googleAnalyticID}`;
    script.async = true;
    document.head.appendChild(script);

    const script1 = document.createElement('script');
    script1.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${GLOBAL_CONSTANT.googleAnalyticID}');
    `;
    document.head.appendChild(script1);

    script.onload = () => {
      /* console.log('Google Analytics script loaded successfully.'); */
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).gtag = function gtag(...args: any[]) {
        (window as any).dataLayer.push(args);
      };
      this.gtagFunction = (window as any).gtag;
      this.initializeGtag();
    };

    script.onerror = () => {
      /* console.error('Failed to load Google Analytics script.'); */
    };
  }

  private initializeGtag() {
    if (this.gtagFunction) {
      /* console.log('Initializing gtag...'); */
      this.gtagFunction('js', new Date());
      this.gtagFunction('config', GLOBAL_CONSTANT.googleAnalyticID);
    } else {
      /* console.warn('Google Analytics gtag function is not defined during initialization.'); */
    }
  }

  public sendEvent(eventName: string, eventParams: Record<string, any>) {
    if (this.gtagFunction) {
      this.gtagFunction('event', eventName, eventParams);
    } else {
      /* console.warn('Google Analytics gtag function is not defined. Retrying...'); */
    }
  }
}
