<section class="sec insplan py-4 worldmap">
    <div class="container">
        <div class="cmnform">
            <div class="row">
                <div class="col-md-12 col-lg-8 mx-auto">
                    <div class="indicators">
                        <div class="step-tabs step-completed"><span> Basic Info</span></div>
                        <div class="step-tabs step-completed"><span>PAN &amp; Aadhar </span></div>
                        <div class="step-tabs step-completed"><span>Education </span></div>
                        <div class="step-tabs step-current"><span>Bank Info</span></div>
                    </div>
                    <div class="mt-3">
                        <div class="travel-wizard">
                            <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off"
                                enctype="multipart/form-data">
                                <div class="steps-wizard">
                                    <div class="text-center fw-semi mb-3">
                                        <div class="fs-4 mb-1">
                                            Enter Bank Details
                                            <button type="button" class="add_ref" (click)="toggleRefIdInput()">
                                                {{ showRefIdInput ? '-' : '+' }}
                                            </button>
                                        </div>
                                        <div class="fs-18 fw-normal">Let's Start Earning Now</div>
                                    </div>
                                    <div class="row">
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="bank_account_number" class="control-label input-label">
                                                    Enter Account Number <span class="text-danger"> * </span>
                                                </label>
                                                <input id="bank_account_number" formControlName="bank_account_number"
                                                    placeholder="Enter Account Number" maxlength="30" type="password"
                                                    class="form-control form-control-custom" autocomplete="off"
                                                    appNumericInput />
                                                <span class="icon">
                                                    <img src="images/icons/account.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('bank_account_number')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('bank_account_number') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="confirm_bank_account_number"
                                                    class="control-label input-label">
                                                    Confirm Account Number <span class="text-danger"> * </span>
                                                </label>
                                                <input id="confirm_bank_account_number"
                                                    formControlName="confirm_bank_account_number"
                                                    placeholder="Enter Confirm Account Number" maxlength="30"
                                                    type="text" class="form-control form-control-custom"
                                                    autocomplete="off" appNumericInput />
                                                <span class="icon">
                                                    <img src="images/icons/confirm-account.svg" [alt]="projectName"
                                                        width="20" height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('confirm_bank_account_number')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('confirm_bank_account_number') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="ifsc_code" class="control-label input-label">
                                                    Enter IFSC Code <span class="text-danger"> * </span>
                                                </label>
                                                <input id="ifsc_code" formControlName="ifsc_code"
                                                    placeholder="Enter IFSC Code" minlength="11" maxlength="11" type="text"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/pin-code.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('ifsc_code')" class="custom-error">
                                                    {{ getErrorMessage('ifsc_code') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col- col-sm-6 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="bank_name" class="control-label input-label">
                                                    Enter Bank Name <span class="text-danger"> * </span>
                                                </label>
                                                <input id="bank_name" formControlName="bank_name"
                                                    placeholder="Enter Bank Name" maxlength="100" type="text"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/bank.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('bank_name')" class="custom-error">
                                                    {{ getErrorMessage('bank_name') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="bank_branch_name" class="control-label input-label">
                                                    Enter Branch Name <span class="text-danger"> * </span>
                                                </label>
                                                <input id="bank_branch_name" formControlName="bank_branch_name"
                                                    placeholder="Enter Branch Name" maxlength="100" type="text"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/building.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('bank_branch_name')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('bank_branch_name') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="cheque_doc" class="control-label fs-15 fw-semi mb-2">
                                                Upload Cancel Cheque or Passbook <small class="text-danger">(Max file
                                                    size 3MB)</small> <span class="text-danger"> * </span>
                                            </label>
                                            <div class="form-group">
                                                <div class="drop-zone">
                                                    <input id="cheque_doc" formControlName="cheque_doc"
                                                        (change)="onChequeDocFileSelected($event)"
                                                        accept="{{ globalConstant.file.file_image_pdf_accept }}"
                                                        type="file" class="drop-zone__input" autocomplete="off" />
                                                    @if(chequeDocFileUrl) {
                                                    <div class="drop-zone__thumb">
                                                        @if(isPdfOrImage(chequeDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                                            <img (click)="downloadDocument(chequeDocFileUrl)" src="images/icons/pdf.png" [alt]="projectName" class="custom-download-document" />
                                                        } @else  {
                                                            <img (click)="downloadDocument(chequeDocFileUrl)" [src]="chequeDocFileUrl" [alt]="projectName" class="custom-download-document" />
                                                        }
                                                    </div>
                                                    <button type="button"
                                                        class="btn btn-remove text-danger fs-14 fw-bold"
                                                        id="cheque_doc_remove" (click)="onChequeDocFileRemove()"><i
                                                            class="bx bx-trash me-1"></i>REMOVE</button>
                                                    }@else {
                                                    <p class="text-dark fw-semi">Browse Upload</p>
                                                    }
                                                </div>
                                                <p class="fs-13 text-muted mb-2"> {{ globalConstant.file.file_image_pdf_accept_label }}</p>
                                                <mat-error *ngIf="getErrorMessage('cheque_doc')" class="custom-error">
                                                    {{ getErrorMessage('cheque_doc') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="signature_doc" class="control-label fs-15 fw-semi mb-2">
                                                Upload signature <small class="text-danger">{{ globalConstant.file.max_file_label }}</small>
                                                <span class="text-danger"> * </span>
                                            </label>
                                            <div class="form-group">
                                                <div class="drop-zone">
                                                    <input id="signature_doc" formControlName="signature_doc"
                                                        (change)="onSignatureDocFileSelected($event)"
                                                        accept="{{ globalConstant.file.file_image_pdf_accept }}"
                                                        type="file" class="drop-zone__input" autocomplete="off" />
                                                    @if(signatureDocFileUrl) {
                                                    <div class="drop-zone__thumb">
                                                        @if(isPdfOrImage(signatureDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                                            <img (click)="downloadDocument(signatureDocFileUrl)" src="images/icons/pdf.png" [alt]="projectName"  class="custom-download-document" />
                                                        } @else {
                                                            <img (click)="downloadDocument(signatureDocFileUrl)" [src]="signatureDocFileUrl" [alt]="projectName"  class="custom-download-document" />
                                                        }
                                                    </div>
                                                    <button type="button"
                                                        class="btn btn-remove text-danger fs-14 fw-bold"
                                                        id="signature_doc_remove"
                                                        (click)="onSignatureDocFileRemove()"><i
                                                            class="bx bx-trash me-1"></i>REMOVE</button>
                                                    }@else {
                                                    <p class="text-dark fw-semi">Browse Upload</p>
                                                    }
                                                </div>
                                                <p class="fs-13 text-muted mb-2"> {{ globalConstant.file.file_image_pdf_accept_label }}</p>
                                                <mat-error *ngIf="getErrorMessage('signature_doc')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('signature_doc') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 signform step_form" *ngIf="showRefIdInput">
                                            <div class="form-group icon-group mt-2">
                                                <label for="ref_id" class="control-label input-label">
                                                    Add Reference Number
                                                </label>
                                                <input id="ref_id" formControlName="ref_id"
                                                    placeholder="Enter Reference Number" maxlength="50" type="text"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/pin-code.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('ref_id')" class="custom-error">
                                                    {{ getErrorMessage('ref_id') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="mt-2 mb-3 form-check position-relative">
                                                <input id="accept_t_c" formControlName="accept_t_c" value="yes"
                                                    type="checkbox" class="form-check-input" />
                                                <label for="accept_t_c" class="form-check-label">
                                                    I agree and accept the <a
                                                        [routerLink]="[globalConstant.base_url, routesPages.POSP_TERMS_AND_CONDITIONS || '']"
                                                        target="_blank" class="text-success">Terms and Conditions</a>
                                                </label>
                                                <mat-error *ngIf="getErrorMessage('accept_t_c')" class="custom-error">
                                                    {{ getErrorMessage('accept_t_c') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt-3">
                                    <a [routerLink]="[globalConstant.base_url, routesPospKyc.POSP_KYC || '', routesPospKyc.POSP_KYC_EDUCATION_DETAILS || '']"
                                        class="btnPrev btn btn-dark rounded-1 px-3">
                                        <i class="bx bx-left-arrow-alt align-middle"></i> Back
                                    </a>
                                    <button type="submit" class="btnNext btn btn-success rounded-1 px-3 ms-2" [disabled]="isLoading">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>