import { Action, createReducer, on } from '@ngrx/store';
import { storeSetUserProfile, storeResetUserProfile } from '../actions/user-profile.actions';

export interface StoreUserProfile { [key: string]: any; }

export interface StoreStateUserProfile {
  storeData: StoreUserProfile | null;
}

export const initialState: StoreStateUserProfile = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetUserProfile, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetUserProfile, state => ({ ...state, storeData: null }))
);

export function reducerUserProfile(state: StoreStateUserProfile | undefined, action: Action) { return _storeReducer(state, action); }