import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_POSP_TRAINING } from '../../common/constants/routes/posp/posp-training.constants';
import { ROUTES_POSP_EXAM } from '../../common/constants/routes/posp/posp-exam.constants';
import { LoaderService } from '../../common/loader/loader.service';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { navigatePopsError } from '../../common/utilities/navigate-posp-status';
import { PospTrainingService } from './posp-training.service';

@Component({
  selector: 'app-posp-training',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './posp-training.component.html'
})
export class PospTrainingComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPospTraining = ROUTES_POSP_TRAINING;
  routesPospExam = ROUTES_POSP_EXAM;

  isShowReExamButton = false;

  public chapterList: any[] = [];
  private apiPospTrainingSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private pospTrainingService: PospTrainingService
  ) { }

  ngOnInit(): void {
    this.apiGetPageData();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  apiGetPageData() {
    this.loaderService.show();
    this.apiPospTrainingSubscription = this.pospTrainingService.getPageData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          this.loaderService.hide();
          if (response?.data?.is_show_re_exam_button && response?.data?.is_show_re_exam_button == this.globalConstant?.status_match?.yes) {
            this.isShowReExamButton = true;
          }
          this.chapterList = response?.data?.chapter_list && this.arrayLength(response?.data?.chapter_list) > 0 ? response?.data?.chapter_list : [];
        },
        (error) => {
          this.loaderService.hide();
          this.utilitiesService.handleHttpError(error);
          const navigatedRoute = navigatePopsError(error);
          if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
            this.router.navigate(navigatedRoute.route as string[]);
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiPospTrainingSubscription) {
      this.apiPospTrainingSubscription.unsubscribe();
    }
  }

}
