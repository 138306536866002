import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ROUTES_PAGES } from '../../common/constants/routes/pages/pages.constants';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {
  routesPages = ROUTES_PAGES;

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
