import { Environment } from "./environment.interface";

export const environment: Environment = {
    production: false,
    debugMode: true,
    enableGoogleAnalytics: false,
    seoTags: false,

    storage_base_url: 'https://test.webinsurance.in/storage/',
    apiURL: 'https://testservices.webinsurance.in/api/v1/'
};
