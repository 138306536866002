<header class="public-header">
    <button (click)="toggleMenu()" type="button" [ngClass]="{'active': isMenuOpen}" class="mobile-nav-toggle d-md-none" aria-label="menu"><i class="lni lni-menu"></i></button>
    <div class="top-header">
        <div class="container">
            <div class="siteInfo">
                @if(!(isLoggedIn$ | async)) {
                    <div class="btnl">
                    <div class="loginBtn cus-l">
                        <a [routerLink]="[globalConstant.base_url, routesUsers.USER || '', routesUsers.USER_SIGN_IN || '']"
                            class="posp-login btn btn-dark text-white">
                            Customer Login
                        </a>
                    </div>
                    <div class="loginBtn pos-l ms-md-2">
                        <a [routerLink]="[globalConstant.base_url, routesPosp.POSP || '', routesPosp.POSP_SIGN_IN || '']"
                            class="posp-login btn btn-success text-white">
                            POSP Login
                        </a>
                    </div>
                </div>
                }

                <ul class="sitehelp">
                    <li>
                        <a href="mailto:support&#64;telsyswebinsurance.com" class="topheaderlink" aria-label="mailus">
                            <i class="bx bx-envelope align-middle"></i>
                            support&#64;telsyswebinsurance.com
                        </a>
                    </li>
                    <li>
                        <a href="tel:7413827827" class="topheaderlink" aria-label="support">
                            <i class="bx bx-support align-middle"></i>
                            7413827827
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="header-row d-flex justify-content-between align-items-center">
            <div class="logo">
                @if(isLoggedIn$ | async) {
                    <a [routerLink]="[globalConstant.base_url, routesUsers.USER_DASHBOARD || '']">
                        <img src="images/header-logo.svg" [alt]="projectName" width="240" height="62" />
                    </a>
                }@else{
                <a [routerLink]="[routesPages.HOME || '']">
                    <img src="images/header-logo.svg" [alt]="projectName" width="240" height="62" />
                </a>
                }
            </div>
            <div class="menu d-md-flex align-items-center">
                <nav class="main-nav px-3" [ngClass]="{'menu-open': isMenuOpen}">
                    <div class="logo d-md-none">
                        <a [routerLink]="[routesPages.HOME || '']" (click)="closetoggleMenu()">
                            <img src="images/header-logo.svg" [alt]="projectName" width="240" height="62" />
                        </a>
                    </div>
                    <ul class="menu-nav nav">
                        <li class="">
                            <a [routerLink]="[globalConstant.base_url]" routerLinkActive="active" (click)="closetoggleMenu()">Home</a>
                        </li>
                        <li class="drop-down mins spic">
                            <a href="javascript:void(0);" (click)="SubMenuOpen()">Offline Insurance Services</a>
                            <ul class="submenu" [ngClass]="{'open': isSubMenuOpen}">
                                <li>
                                    <a [routerLink]="getOffLineServiceURL(offlineServicesID.CAR_INSURANCE, 'step_1')" routerLinkActive="active" (click)="closetoggleMenu()">{{ getOffLineServiceFullName(offlineServicesID.CAR_INSURANCE) }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="getOffLineServiceURL(offlineServicesID.BIKE_INSURANCE, 'step_1')" routerLinkActive="active" (click)="closetoggleMenu()">{{ getOffLineServiceFullName(offlineServicesID.BIKE_INSURANCE) }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="getOffLineServiceURL(offlineServicesID.RICKSHAW_INSURANCE, 'step_1')" routerLinkActive="active" (click)="closetoggleMenu()">{{ getOffLineServiceFullName(offlineServicesID.RICKSHAW_INSURANCE) }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="getOffLineServiceURL(offlineServicesID.TAXI_INSURANCE, 'step_1')" routerLinkActive="active" (click)="closetoggleMenu()">{{ getOffLineServiceFullName(offlineServicesID.TAXI_INSURANCE) }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="getOffLineServiceURL(offlineServicesID.TRACTOR_INSURANCE, 'step_1')" routerLinkActive="active" (click)="closetoggleMenu()">{{ getOffLineServiceFullName(offlineServicesID.TRACTOR_INSURANCE) }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="getOffLineServiceURL(offlineServicesID.TRUCK_BUS_INSURANCE, 'step_1')" routerLinkActive="active" (click)="closetoggleMenu()">{{ getOffLineServiceFullName(offlineServicesID.TRUCK_BUS_INSURANCE) }}</a>
                                </li>
                                <li>
                                    <a [routerLink]="getOffLineServiceURL(offlineServicesID.TRUCK_PICKUP_INSURANCE, 'step_1')" routerLinkActive="active" (click)="closetoggleMenu()">{{ getOffLineServiceFullName(offlineServicesID.TRUCK_PICKUP_INSURANCE) }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                @if(isLoggedIn$ | async) {
                    <div class="login_account">
                        <a [matMenuTriggerFor]="profilemenu" href="javascript:void(0);"
                        class="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false" (menuOpened)="myProfileOpen()"
                        (menuClosed)="myProfileClosed()">
                        <i class="bx bx-user align-middle me-1"></i> My Account
                        </a>
                        <mat-menu class="acc_nav" #profilemenu="matMenu">
                            <div class="profileMenu">
                                <a [routerLink]="[globalConstant.base_url, routesUsers.USER_DASHBOARD || '']"
                                class="dashboard"> <i class="bx bx-home align-middle"></i> Dashboard</a>
                                <a (click)="logout()" class="logout">
                                    <i class="bx bx-power-off align-middle"></i> Logout
                                </a>
                            </div>
                        </mat-menu>
                    </div>
                }   @else{
                        <div class="acc-btn">
                            <a [routerLink]="[globalConstant.base_url, routesPosp.POSP || '', routesPosp.POSP_SIGN_UP || '']"
                                class="btn btn-dark ms-md-3 btn-posp">
                                Become an Agent POSP
                            </a>
                        </div>
                    }
            </div>
        </div>
    </div>
</header>