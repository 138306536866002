import { Injectable } from '@angular/core';
import { ApiService } from '../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserSignInOtpVerificationService {

  constructor(
    private apiService: ApiService
  ) { }

  resendOTP(formData: any) {
    return this.apiService.request<any>('POST', 'users/sign-in-resend-otp', formData);
  }

  postData(formData: any) {
    return this.apiService.request<any>('POST', 'users/sign-in-otp-verification', formData);
  }
}
