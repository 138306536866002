import { Action, createReducer, on } from '@ngrx/store';
import { storeSetTwoWheelerInsuranceQuotesList, storeResetTwoWheelerInsuranceQuotesList } from '../../../actions/online-services/two-wheeler-insurance/two-wheeler-insurance-quotes-list.actions';

export interface StoreTwoWheelerInsuranceQuotesList { [key: string]: any; }

export interface StoreStateTwoWheelerInsuranceQuotesList {
  storeData: StoreTwoWheelerInsuranceQuotesList | null;
}

export const initialState: StoreStateTwoWheelerInsuranceQuotesList = { storeData: null };

const _storeReducer = createReducer(
  initialState,
  on(storeSetTwoWheelerInsuranceQuotesList, (state, { storeData }) => ({ ...state, storeData })),
  on(storeResetTwoWheelerInsuranceQuotesList, state => ({ ...state, storeData: null }))
);

export function reducerOnlineTwoWheelerInsuranceQuotesList(state: StoreStateTwoWheelerInsuranceQuotesList | undefined, action: Action) { return _storeReducer(state, action); }