export const ROUTES_POSP = {
  POS: 'pos',
  POSP: 'posp',
  POSP_SIGN_IN: 'sign-in',
  POSP_SIGN_IN_OTP_VERIFICATION: 'sign-in-otp-verification',
  POSP_SIGN_UP: 'sign-up',
  POSP_SIGN_UP_OTP_VERIFICATION: 'sign-up-otp-verification',
  POSP_PROFILE: 'profile',
  POSP_EXAM_SCORE: 'exam-score',
  POSP_OFFLINE_COMMISSION: 'offline-commission',
};