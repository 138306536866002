<section class="sec insplan py-4 worldmap">
    <div class="container">
        <div class="cmnform">
            <div class="row">
                <div class="col-md-12 col-lg-8 mx-auto">
                    <div class="indicators">
                        <div class="step-tabs step-completed"><span> Basic Info</span></div>
                        <div class="step-tabs step-completed"><span>PAN &amp; Aadhar </span></div>
                        <div class="step-tabs step-current"><span>Education </span></div>
                        <div class="step-tabs"><span>Bank Info</span></div>
                    </div>
                    <div class="mt-3">
                        <div class="travel-wizard">
                            <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off"
                                enctype="multipart/form-data">
                                <div class="steps-wizard">
                                    <div class="text-center fw-semi mb-3">
                                        <div class="fs-4 mb-1">Education &amp; Other Details</div>
                                        <div class="fs-18 fw-normal">Let's Start Earning Now</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label class="control-label input-label"
                                                    for="educational_qualification">
                                                    Enter Qualification <span class="text-danger"> * </span>
                                                </label>
                                                <input id="educational_qualification"
                                                    formControlName="educational_qualification"
                                                    placeholder="Enter Qualification" maxlength="100" type="text"
                                                    class="form-control form-control-custom" autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/education.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('educational_qualification')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('educational_qualification') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="educational_certificate_doc"
                                                class="control-label fs-15 fw-semi mb-2">
                                                Upload Highest Qualification <small class="text-danger">(Max file size
                                                    3MB)</small> <span class="text-danger"> * </span>
                                            </label>
                                            <div class="form-group">
                                                <div class="drop-zone">
                                                    <input id="educational_certificate_doc"
                                                        formControlName="educational_certificate_doc"
                                                        (change)="onEducationalCertificateDocFileSelected($event)"
                                                        accept="{{ globalConstant.file.file_image_pdf_accept }}"
                                                        type="file" class="drop-zone__input" autocomplete="off" />
                                                    @if(educationalCertificateDocFileUrl) {
                                                    <div class="drop-zone__thumb">
                                                        @if(isPdfOrImage(educationalCertificateDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                                            <img (click)="downloadDocument(educationalCertificateDocFileUrl)" src="images/icons/pdf.png" [alt]="projectName" class="custom-download-document" />
                                                        } @else {
                                                            <img (click)="downloadDocument(educationalCertificateDocFileUrl)" [src]="educationalCertificateDocFileUrl" [alt]="projectName" class="custom-download-document" />
                                                        } 
                                                    </div>
                                                    <button type="button"
                                                        class="btn btn-remove text-danger fs-14 fw-bold"
                                                        id="educational_certificate_doc_remove"
                                                        (click)="onEducationalCertificateDocFileRemove()"><i
                                                            class="bx bx-trash me-1"></i>REMOVE</button>
                                                    }@else {
                                                    <p class="text-dark fw-semi">Browse Upload</p>
                                                    }
                                                </div>
                                                <p class="fs-13 text-muted"> {{ globalConstant.file.file_image_pdf_accept_label }}</p>
                                                <mat-error *ngIf="getErrorMessage('educational_certificate_doc')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('educational_certificate_doc') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="d-block fs-6 fw-semi mb-3">
                                                Are you POSP with any other broker/Insurer? <span
                                                    class="text-danger required"> * </span>
                                            </label>
                                            <div class="form-group">
                                                <div class="form-check form-check-inline typeradio customradio">
                                                    <input type="radio" id="already_posp_yes" name="already_posp"
                                                        formControlName="already_posp" value="yes"
                                                        class="form-check-input">
                                                    <label for="already_posp_yes" class="form-check-label">Yes</label>
                                                </div>
                                                <div class="form-check form-check-inline typeradio customradio">
                                                    <input type="radio" id="already_posp_no" name="already_posp"
                                                        formControlName="already_posp" value="no"
                                                        class="form-check-input">
                                                    <label for="already_posp_no" class="form-check-label">No</label>
                                                </div>
                                                <mat-error *ngIf="getErrorMessage('already_posp')" class="custom-error">
                                                    {{ getErrorMessage('already_posp') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt-3">
                                    <a [routerLink]="[globalConstant.base_url, routesPospKyc.POSP_KYC || '', routesPospKyc.POSP_KYC_PAN_AADHAR_DETAILS || '']"
                                        class="btnPrev btn btn-dark rounded-1 px-3">
                                        <i class="bx bx-left-arrow-alt align-middle"></i> Back
                                    </a>
                                    <button type="submit" class="btnNext btn btn-success rounded-1 px-3 ms-2" [disabled]="isLoading">
                                        Next <i class="bx bx-right-arrow-alt align-middle"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>