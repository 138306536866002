import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../common/constants/global-constant.constants';
import { ROUTES_POSP_KYC } from '../../common/constants/routes/posp/posp-kyc.constants';
import { UtilitiesService } from '../../common/utilities/utilities.service';
import { navigatePopsKycStatus } from '../../common/utilities/navigate-posp-status';
import { PospKycService } from './posp-kyc.service';

@Component({
  selector: 'app-posp-kyc',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './posp-kyc.component.html'
})
export class PospKycComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  routesPospKyc = ROUTES_POSP_KYC;

  projectName = this.globalConstant?.projectName;


  private apiPospKycDataSubscription: Subscription | undefined;

  getUserData: any = {};

  constructor(
    private router: Router,
    private pospKycService: PospKycService,
    private utilitiesService: UtilitiesService
  ) {
  }

  ngOnInit(): void {
    this.apiGetPageData();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  apiGetPageData() {
    this.apiPospKycDataSubscription = this.pospKycService.getPageData().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            let userData = response?.data;
            this.getUserData = response?.data;
            const navigatedRoute = navigatePopsKycStatus(userData);
            if (navigatedRoute?.status && this.utilitiesService?.objectLength(navigatedRoute?.route)) {
              this.router.navigate(navigatedRoute.route as string[]);
            }
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    if (this.apiPospKycDataSubscription) {
      this.apiPospKycDataSubscription.unsubscribe();
    }
  }

}
