<section class="sec pt-4 mrtop-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="bg-white">
          <h1 class="fs-4 text-center fw-bold mb-3">Privacy Policy - Telsysweb Insurance Brokers Pvt. Ltd.</h1>
          <p>Welcome to Telsysweb Insurance Brokers Pvt. Ltd.! Your trust is our top priority and we want you to feel safe & secure
            visiting our website. We are deeply committed to safeguarding your privacy and ensuring the security of your
            personal information. This Privacy Policy serves as a comprehensive guide, detailing how we collect,
            utilize, disclose, and protect the information you entrust to us. By accessing any of our services, you
            acknowledge that you have carefully reviewed and comprehended the terms and conditions of service, inclusive
            of this Privacy Policy, and consent to abide by its entirety.</p>
          <h2 class="fs-5 fw-bold">Reason to collect your data</h2>
          <p>Telsysweb Insurance Brokers Pvt. Ltd. collects your information when you register for an account, when you use its products
            or services, visit its Website's pages or when an employer organization shares its employee information.
            When you register with Telsysweb Insurance Brokers Pvt. Ltd. you are asked for submitting certain information which may be
            personal to you such as mobile number, your name and address. Once you register and sign in to our website,
            you are no longer unknown to us. Hence, by registering you authorize the Telsysweb Insurance Brokers Pvt. Ltd. to send texts
            and email alerts to you with your login details and any other service requirements, including promotional
            mails and SMSs.</p>
          <h2 class="fs-5 fw-bold">Information We Collect</h2>
          <p>We collect various types of information to provide you with the best insurance products and services
            possible. This may include: Personal Information: When you interact with us, such as when you request a
            quote, purchase a policy, or file a claim, we may collect personal details such as your name, address, email
            address, phone number, and payment information. Information Sharing</p>
          <h2 class="fs-5 fw-bold">Information from Third Parties:</h2>
          <p>We may also receive information about you from third parties, such as credit bureaus or other insurance
            companies, to help us provide and improve our services.</p>
          <h2 class="fs-5 fw-bold">How We Use Your Information</h2>
          <ul class="mb-3">
            <li>We utilize the collected information for a variety of purposes, including:</li>
            <li>Enhancing the functionality and performance of our website.</li>
            <li>Delivering services to all users of the website.</li>
            <li>Conducting statistical analyses to better understand user behavior.</li>
            <li>Personalizing your browsing experience.</li>
            <li>Facilitating sales processes and gathering post-sales feedback.</li>
            <li>Improving our products and customer service.</li>
            <li>Ensuring compliance with relevant laws and regulations, responding to legal requests, and safeguarding
              our rights and assets.</li>
            <li>Sending you information about our company and/or our products and services, and contacting you as
              necessary.</li>
          </ul>
          <h2 class="fs-5 fw-bold">Information Sharing</h2>
          <ul class="mb-3">
            <li>We understand the importance of safeguarding your personal information and only share it under specific
              circumstances outlined below:</li>
            <li>For statistical analysis and verification or risk management.</li>
            <li>To detect, prevent, or otherwise address fraud, security or technical issues.</li>
            <li>Meet the requirements for participation in any telecommunications or electronic clearing network.</li>
            <li>Enforcing applicable terms of service, including investigating potential violations.</li>
            <li>To outsource non-core activities in accordance with IRDAI regulations.</li>
            <li>To medical personnel, investigators / fraud investigators</li>
          </ul>
          <h2 class="fs-5 fw-bold">Data Security</h2>
          <p>We take reasonable measures to protect the personal information we collect from unauthorized access,
            disclosure, alteration or destruction. Rest assured, your privacy and security are our top priorities, and
            we constantly strive to maintain the highest standards of security.</p>
          <h2 class="fs-5 fw-bold">Updates to This Privacy Policy</h2>
          <p>We retain the right to periodically update this Privacy Policy to accommodate changes in our business
            practices or legal obligations. Notification of any significant changes will be provided by posting the
            updated policy on our website or through other legally required means.</p>
          <h2 class="fs-5 fw-bold">Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please don't
            hesitate to contact us at 7413827827. We are here to help and address any issues you may have.</p>
          <p>Telsysweb Insurance Brokers Pvt. Ltd. reserves the right to contact you through various channels such as email, mobile
            messaging services, WhatsApp, telephone, if you have consented to receive such communication or if
            operational or regulatory necessities mandate us to do so.</p>
          <p>Thank you for trusting Telsysweb Insurance Brokers Pvt. Ltd. with your insurance needs. We appreciate your business and are
            committed to protecting your privacy every step of the way.</p>
        </div>
      </div>
    </div>
  </div>
</section>