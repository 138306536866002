import { NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { ONLINE_SERVICES_ID, ONLINE_SERVICES_STEP } from '../../../common/constants/services/online-services-url.constants';

import { LoaderService } from '../../../common/loader/loader.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { StoreUtilitiesService } from '../../../common/utilities/store-utilities.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';

import { getFormValidators } from './form-utils';

import { InsuranceTermsConditionsComponent } from '../../../common/layouts/insurance-terms-conditions/insurance-terms-conditions.component';
import { OnlineTwoWheelerInsurancePersonalDetailsService } from './online-two-wheeler-insurance-personal-details.service';

import { StoreTwoWheelerInsuranceBasicDetail, StoreStateTwoWheelerInsuranceBasicDetail } from '../../../store/reducers/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.reducers';
import { selectStoreTwoWheelerInsuranceBasicDetail } from '../../../store/selectors/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.selectors';
import { storeSetTwoWheelerInsuranceBasicDetail, storeResetTwoWheelerInsuranceBasicDetail } from '../../../store/actions/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.actions';

@Component({
  selector: 'app-online-two-wheeler-insurance-personal-details',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    InsuranceTermsConditionsComponent
  ],
  templateUrl: './online-two-wheeler-insurance-personal-details.component.html'
})
export class OnlineTwoWheelerInsurancePersonalDetailsComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  onLineServicesID = ONLINE_SERVICES_ID;
  onLineServicesStep = ONLINE_SERVICES_STEP;

  projectName = this.globalConstant?.projectName;

  isLoading = false;
  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};

  uuId: string | null = null;
  vehicleDetail: any = {};

  getStoreVehicleDetail$: Observable<StoreTwoWheelerInsuranceBasicDetail | null>;

  private apiGetVehicleDetailSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{
      selectStoreTwoWheelerInsuranceBasicDetail: StoreStateTwoWheelerInsuranceBasicDetail
    }>,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private momentUtilitiesService: MomentUtilitiesService,
    private storeUtilitiesService: StoreUtilitiesService,
    private onlineTwoWheelerInsurancePersonalDetailsService: OnlineTwoWheelerInsurancePersonalDetailsService
  ) {
    this.customForm = getFormValidators();
    this.getStoreVehicleDetail$ = this.store.pipe(select(selectStoreTwoWheelerInsuranceBasicDetail));
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
    });
    this.loaderService.show();
    this.storeUtilitiesService.processStore(
      this.getStoreVehicleDetail$,
      () => this.apiGetVehicleDetailData(),
      (data) => this.setStoreVehicleDetailData(data)
    );
    this.loaderService.hide();
  }

  objectLength(arr: any): number {
    return this.utilitiesService.objectLength(arr);
  }

  @ViewChild('registration_date', { static: false }) registration_date_datepicker!: MatDatepicker<Date>;
  @ViewChild('nominee_dob', { static: false }) nominee_dob_datepicker!: MatDatepicker<Date>;
  maxRegistrationDate: Date = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();
  minRegistrationDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);
  maxNomineeDobDate: Date = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();
  minNomineeDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);

  openRegistrationDateDatepicker() {
    this.registration_date_datepicker.open();
  }

  openNomineeDobDatepicker() {
    this.nominee_dob_datepicker.open();
  }

  restrictNonNumericInput(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', '-', '/'];
    if (!allowedKeys.includes(event.key) && (event.key < '0' || event.key > '9')) {
      event.preventDefault();
    }
  }

  async apiGetVehicleDetailData(): Promise<void> {
    if (this.uuId != null && this.uuId != '') {
      let params = {
        'uuid': this.uuId
      };
      this.apiGetVehicleDetailSubscription = this.onlineTwoWheelerInsurancePersonalDetailsService.getVehicleDetail(params).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            const getData = response?.data;
            this.store.dispatch(storeResetTwoWheelerInsuranceBasicDetail());
            this.store.dispatch(storeSetTwoWheelerInsuranceBasicDetail({ storeData: getData }));
            this.setStoreVehicleDetailData(getData);
          },
          (error) => {
            this.utilitiesService.handleHttpError(error);
          }
        )
      );
    }
  }

  setStoreVehicleDetailData(data: StoreTwoWheelerInsuranceBasicDetail | null) {
    if (!data) return;
    this.vehicleDetail = data;
    this.customForm.patchValue({
      full_name: this.vehicleDetail?.full_name || '',
      phone: this.vehicleDetail?.phone || '',
      email: this.vehicleDetail?.email || '',
      address: this.vehicleDetail?.present_address || '',
      vehicle_number: this.vehicleDetail?.vehicle_number || '',
      engine_number: this.vehicleDetail?.engine_number || '',
      chassis_number: this.vehicleDetail?.chassis_number || '',
      registration_date: this.vehicleDetail?.registration_date || '',

      nominee_first_name: this.vehicleDetail?.nominee_first_name || '',
      nominee_middle_name: this.vehicleDetail?.nominee_middle_name || '',
      nominee_last_name: this.vehicleDetail?.nominee_last_name || '',
      nominee_dob: this.vehicleDetail?.nominee_dob || '',

    });
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();

      /* let accept_t_c = 'no';
      if (this.customForm.value.accept_t_c) {
        accept_t_c = 'yes';
      } */

      let registration_date = '';
      let nominee_dob = '';
      if (this.customForm.value.registration_date) {
        registration_date = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.registration_date);
      }
      if (this.customForm.value.nominee_dob) {
        nominee_dob = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.nominee_dob);
      }

      const formData = {
        "quote_uuid": this.uuId,
        "insurer_id": 2,
        "title": "mr",
        "full_name": this.customForm?.value?.full_name,
        "phone": this.customForm?.value?.phone,
        "email": this.customForm?.value?.email,
        "present_address": this.customForm?.value?.address,
        "vehicle_number": this.customForm?.value?.vehicle_number,
        "engine_number": this.customForm?.value?.engine_number,
        "chassis_number": this.customForm?.value?.chassis_number,
        "registration_date": registration_date,
        "nominee_first_name": this.customForm?.value?.nominee_first_name,
        "nominee_middle_name": this.customForm?.value?.nominee_middle_name,
        "nominee_last_name": this.customForm?.value?.nominee_last_name,

        "nominee_dob": nominee_dob,
        "nominee_relation": "father",
        "remember_previous_policy": "no",
        // "insurance_expiry_date": "25-07-1914"
      };
      /* this.store.dispatch(storeResetTwoWheelerInsuranceBasicDetail()); */
      this.apiSubmitFormDataSubscription = this.onlineTwoWheelerInsurancePersonalDetailsService.postData(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            let getResponse = response?.data;
            console.log(getResponse, ">>> getResponse >>>");
            /* let getUuid = response?.data?.uuid;
            if (getUuid != '') {
              this.store.dispatch(storeSetTwoWheelerInsuranceBasicDetail({ storeData: getResponse }));
              let getRoute = this.utilitiesService.getOnLineServiceURL(this.onLineServicesID.TWO_WHEELER_INSURANCE, this.onLineServicesStep.STEP_2);
              getRoute = [...getRoute, getUuid];
              this.router.navigate(getRoute);
            } */
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }


}
