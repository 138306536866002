import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [],
  templateUrl: './faqs.component.html'
})
export class FaqsComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
