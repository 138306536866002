import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatFormFieldModule } from '@angular/material/form-field';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { ONLINE_SERVICES_ID, ONLINE_SERVICES_STEP } from '../../../common/constants/services/online-services-url.constants';
import { UpperCaseDirective } from '../../../common/directives/upper-case.directive';
import { LoaderService } from '../../../common/loader/loader.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { getFormValidators } from './form-utils';
import { OnlineTwoWheelerInsuranceBasicDetailsService } from './online-two-wheeler-insurance-basic-details.service';

import { storeSetTwoWheelerInsuranceBasicDetail, storeResetTwoWheelerInsuranceBasicDetail } from '../../../store/actions/online-services/two-wheeler-insurance/two-wheeler-insurance-basic-detail.actions';

@Component({
  selector: 'app-online-two-wheeler-insurance-basic-details',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatFormFieldModule,
    UpperCaseDirective
  ],
  templateUrl: './online-two-wheeler-insurance-basic-details.component.html'
})
export class OnlineTwoWheelerInsuranceBasicDetailsComponent {

  onLineServicesID = ONLINE_SERVICES_ID;
  onLineServicesStep = ONLINE_SERVICES_STEP;

  isLoading = false;
  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};

  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private onlineTwoWheelerInsuranceBasicDetailsService: OnlineTwoWheelerInsuranceBasicDetailsService
  ) {
    this.customForm = getFormValidators();
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();
      const formData = {
        vehicle_number: this.customForm.value.vehicle_number
      };
      this.store.dispatch(storeResetTwoWheelerInsuranceBasicDetail());
      this.apiSubmitFormDataSubscription = this.onlineTwoWheelerInsuranceBasicDetailsService.postData(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            let getResponse = response?.data;
            let getUuid = response?.data?.uuid;
            if (getUuid != '') {
              this.store.dispatch(storeSetTwoWheelerInsuranceBasicDetail({ storeData: getResponse }));
              let getRoute = this.utilitiesService.getOnLineServiceURL(this.onLineServicesID.TWO_WHEELER_INSURANCE, this.onLineServicesStep.STEP_2);
              getRoute = [...getRoute, getUuid];
              this.router.navigate(getRoute);
            }
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }
}
