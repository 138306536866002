import { Injectable } from '@angular/core';
import { ApiService } from '../../../common/utilities/api.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineBusinessInsuranceStepTwoService {

  constructor(
    private apiService: ApiService
  ) { }

  getNomineeRelation() {
    return this.apiService.request<any>('POST_AUTH', 'nominee-relation');
  }

  getEducationType() {
    return this.apiService.request<any>('POST_AUTH', 'education-type');
  }

  getState() {
    return this.apiService.request<any>('GET', 'states');
  }

  getFormData(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'offline/business-insurance/order-edit', formData);
  }

  postData(formData: any) {
    return this.apiService.request<any>('POST_AUTH', 'offline/business-insurance/order-store', formData);
  }

}

