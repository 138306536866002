import { Routes } from '@angular/router';
import { authUsersGuard } from '../../guards/auth-users.guard';
import { checkUsersGuard } from '../../guards/check-users.guard';
import { ROUTES_OFFLINE_BUS_INSURANCE } from '../../constants/routes/offline-services/offline-bus-insurance.constants';
import { OfflineBusInsuranceStepOneComponent } from '../../../offline-services/bus-insurance/offline-bus-insurance-step-one/offline-bus-insurance-step-one.component';
import { OfflineBusInsuranceStepTwoComponent } from '../../../offline-services/bus-insurance/offline-bus-insurance-step-two/offline-bus-insurance-step-two.component';
import { OfflineBusInsuranceOrdersComponent } from '../../../offline-services-orders/bus-insurance/offline-bus-insurance-orders/offline-bus-insurance-orders.component';
import { OfflineBusInsuranceOrderDetailComponent } from '../../../offline-services-orders/bus-insurance/offline-bus-insurance-order-detail/offline-bus-insurance-order-detail.component';

export const OfflineBusInsuranceRoutes: Routes = [
    {
        path: ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_STEP_ONE,
        component: OfflineBusInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [checkUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_STEP_ONE + '/:uuid',
        component: OfflineBusInsuranceStepOneComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_STEP_TWO + '/:uuid',
        component: OfflineBusInsuranceStepTwoComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer' },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_ORDERS + '/:order_show_type',
        component: OfflineBusInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_ORDERS + '/:order_show_type' + '/:date_key',
        component: OfflineBusInsuranceOrdersComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    },
    {
        path: ROUTES_OFFLINE_BUS_INSURANCE.OFFLINE_ORDER_DETAILS + '/:uuid',
        component: OfflineBusInsuranceOrderDetailComponent,
        data: { headerLayoutType: 'sidebar-layout', footerLayoutType: 'remove-footer', footerCompanyInfoLayoutType : true },
        canActivate: [authUsersGuard]
    }
];