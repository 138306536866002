import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { vehicleNumberValidator } from '../../../common/utilities/form-rules';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        service_step: new FormControl(GLOBAL_CONSTANT?.online_bike_insurance_step_match?.step_3 || 'update_quote'),
        uuid: new FormControl(''),
        title: new FormControl('mr'/* , [
            Validators.required
        ] */),
        insurer_id: new FormControl('1', [
            /* Validators.required, */
            Validators.maxLength(100)
        ]),
        full_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        phone: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('^[0-9]*$')
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.maxLength(100),
            Validators.email
        ]),
        address: new FormControl('', [
            Validators.required
        ]),
        vehicle_number: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            vehicleNumberValidator,
        ]),
        engine_number: new FormControl('', [
            Validators.required
        ]),
        chassis_number: new FormControl('', [
            Validators.required
        ]),
        registration_date: new FormControl('', [
            Validators.required
        ]),
        nominee_first_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(70)
        ]),
        nominee_middle_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        nominee_last_name: new FormControl('', [
            Validators.maxLength(50)
        ]),
        nominee_relation: new FormControl('father'/* , [
            Validators.required
        ] */),
        nominee_dob: new FormControl('', [
            Validators.required
        ]),
        remember_previous_policy: new FormControl('no', [
            Validators.maxLength(50)
        ]),
    });
}

