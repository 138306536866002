import { Component } from '@angular/core';

@Component({
  selector: 'app-insurance-terms-conditions',
  standalone: true,
  imports: [],
  templateUrl: './insurance-terms-conditions.component.html'
})
export class InsuranceTermsConditionsComponent {

}
