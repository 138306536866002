@if(arrayLength(chapterList) > 0) {
<section class="sec insplan py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-10 mx-auto">
                <div class="row">
                    <div class="col-7 col-md-8">
                        <p class="fw-semi fs-5 mb-0">Training Chapter </p>
                        <p class="mb-4">Complete the training and proceed to exam!</p>
                    </div>
                    <div class="col-5 col-md-4 text-end">
                        <div class="d-block fw-semi"> Minimum Training Time : 15 Hours </div>
                    </div>
                    @for(data of chapterList; track i; let i=$index) {
                    <div class="col- col-sm-12 col-md-12 col-lg-6">
                        <div class="bg-lightgreen w-100 text-start border-bottom  rounded-2 border-2 mb-4">
                            <div class="py-1 px-4 py-2 bg-light-success">
                                <h2 class="fs-5 mb-0 text-success" [innerHTML]="data?.chapter_title"></h2>
                                <p class="mb-0 text-success" [innerHTML]="data?.chapter_sub_title"></p>
                            </div>
                            <div class="px-3">
                                <div class="row">
                                    <div class="col-md-7 py-4">
                                        @if(data?.is_read) {
                                        <p class="fs-6 fw-semi">
                                            Progress <span class="count_percentage text-end text-success">100%</span>
                                        </p>
                                        <div class="progress bg-success progress-height"></div>
                                        } @else {
                                        <p class="fs-6 fw-semi">
                                            Progress <span class="count_percentage text-end">0%</span>
                                        </p>
                                        <div class="progress progress-height"></div>
                                        }
                                    </div>
                                    <div class="col-md-5 py-0 py-md-4 px-4 my-auto">
                                        <div class="form-group text-center mt-2 mb-4">
                                            <a [routerLink]="[globalConstant.base_url, routesPospTraining.POSP_TRAINING || '', routesPospTraining.POSP_TRAINING_CHAPTER || '', data?.chapter || 1]"
                                                class="btn rounded-5 btn-lg border bg-white">
                                                Read Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div class="col-md-12">
                    <div class="form-group text-center mt-2 mb-4">
                        @if(isShowReExamButton && isShowReExamButton === true) {
                            <a [routerLink]="[globalConstant.base_url, routesPospExam.POSP_EXAM || '', routesPospExam.POSP_EXAM_RE_EXAM || '']"
                                class="btn btn-green rounded-5 px-5 btn-lg">
                                Re-Exam
                            </a>
                        }
                        <a [routerLink]="[globalConstant.base_url, routesPospTraining.POSP_TRAINING || '', routesPospTraining.POSP_TRAINING_CHAPTER || '', 1]"
                            class="btn btn-green rounded-5 px-5 ms-1 btn-lg">
                            Start Training
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
}