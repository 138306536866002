import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { GLOBAL_CONSTANT } from '../../constants/global-constant.constants';
import { ROUTES_PAGES } from '../../constants/routes/pages/pages.constants';
import { ROUTES_USERS } from '../../constants/routes/users/users.constants';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar-navigation',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatMenuModule
  ],
  templateUrl: './sidebar-navigation.component.html'
})
export class SidebarNavigationComponent {

  isProduction = environment.production;
  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;
  routesUsers = ROUTES_USERS;

  projectName = this.globalConstant?.projectName;

  menuState: { [key: string]: boolean } = {};

  onMenuOpened(menu: string) {
    this.menuState[menu] = true;
  }

  onMenuClosed(menu: string) {
    this.menuState[menu] = false;
  }

  isMenuOpen(menu: string): boolean {
    return this.menuState[menu] || false;
  }

  isSideNavOpen: boolean = false;

  sidetoggleMenu() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }

  closesidetoggleMenu() {
    this.isSideNavOpen = false;
  }

}
