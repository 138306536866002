<footer class="footer pt-4 text-white mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-3">
                <div class="flogo mb-3">
                    <img src="images/footer-logo.svg" [alt]="projectName" width="240" height="62" />
                </div>
                <p class="mb-2">CIN: U67110RJ2022PTC083201</p>
                <p class="mb-2">Social Media</p>
                <ul class="f-share mb-2">
                    <li>
                        <a href="javascript:void(0);" aria-label="Facebook">
                            <i class="lni lni-facebook-filled"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" aria-label="Twitter">
                            <i class="lni lni-twitter-filled"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" aria-label="Instagram">
                            <i class="lni lni-instagram-original"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" aria-label="Linkedin">
                            <i class="lni lni-linkedin-original"></i>
                        </a>
                    </li>
                </ul>
                <p class="mb-2">Email: support&#64;telsyswebinsurance.com</p>
                <p>Call: +91 7413827827</p>
            </div>
            <div class="col-md-6 col-lg-3">
                <ul class="f-nav">
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.CAR_INSURANCE, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.CAR_INSURANCE) }}</a>
                    </li>
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.BIKE_INSURANCE, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.BIKE_INSURANCE) }}</a>
                    </li>
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.RICKSHAW_INSURANCE, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.RICKSHAW_INSURANCE) }}</a>
                    </li>
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.TAXI_INSURANCE, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.TAXI_INSURANCE) }}</a>
                    </li>
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.TRACTOR_INSURANCE, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.TRACTOR_INSURANCE) }}</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-3">
                <ul class="f-nav">
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.TRUCK_BUS_INSURANCE, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.TRUCK_BUS_INSURANCE) }}</a>
                    </li>
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.TRUCK_PICKUP_INSURANCE, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.TRUCK_PICKUP_INSURANCE) }}</a>
                    </li>
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.CHILD_SAVING_PLAN, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.CHILD_SAVING_PLAN) }}</a>
                    </li>
                    <li>
                        <a [routerLink]="getOffLineServiceURL(offlineServicesID.RETIREMENT_PLAN, 'step_1')">{{
                            getOffLineServiceFullName(offlineServicesID.RETIREMENT_PLAN) }}</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-6 col-lg-3">
                <ul class="f-nav">
                    <li>
                        <a [routerLink]="[globalConstant.base_url, routesPages.FAQ || '']">FAQ's</a>
                    </li>
                    <li>
                        <a [routerLink]="[globalConstant.base_url, routesPages.TERMS_AND_CONDITIONS || '']">Terms and
                            Conditions</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-6 col-lg-3">
                <p class="text-uppercase mb-1">Payment</p>
                <div class="bg-white rounded-1 p-2 text-center">
                    <img src="images/icons/payment-option.png" [alt]="projectName" width="579" height="59" />
                </div>
            </div>
            <div class="col-md-6 col-lg-6 mt-4 mt-lg-0">
                <p class="text-uppercase mb-1">Company</p>
                <ul class="fhr-nav f-nav">
                    <li>
                        <a [routerLink]="[globalConstant.base_url, routesPages.ABOUT_US || '']">About us</a>
                    </li>
                    <li>
                        <a [routerLink]="[globalConstant.base_url, routesPages.CONTACT_US || '']">Contact us</a>
                    </li>
                    <li>
                        <a [routerLink]="[globalConstant.base_url, routesPages.PRIVACY_POLICY || '']">Privacy Policy</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-12 col-lg-3 mt-4 mt-lg-0">
                <div class="text-center d-flex align-items-center justify-content-center">
                    <span class="pwa-icon me-4" id="installButton" (click)="installPWA()" style="display: none;">
                        <img src="images/icons/pwa.png" [alt]="projectName" width="91" height="44" />
                    </span>
                    <span>
                        <img src="images/icons/ssl-secure.png" [alt]="projectName" width="150" height="61" />
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright text-center text-white pb-3 mt-3">
        Copyright &copy; 2024 | TELSYSWEB INSURANCE BROKERS PRIVATE LIMITED
    </div>
</footer>