import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  openDialog(data: any): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      data: data,
      disableClose: false
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
