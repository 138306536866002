<section class="sec insplan pt-5 pb-3">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="chapter_training bg-white rounded">
          <h1 class="text-center fs-4" [innerHTML]="title"></h1>
          @if(arrayLength(termsCondinData) > 0) {
          <ul class="terms_of_use">
            @for(data of termsCondinData; track i; let i=$index) {
            <li [innerHTML]="data"></li>
            }
          </ul>
          }
        </div>
      </div>
    </div>
  </div>
</section>