<section class="sec insplan py-4 worldmap">
    <div class="container">
        <div class="cmnform">
            <div class="row">
                <div class="col-md-12 col-lg-8 mx-auto">
                    <div class="indicators">
                        <div class="step-tabs step-completed"><span> Basic Info</span></div>
                        <div class="step-tabs step-current"><span>PAN &amp; Aadhar </span></div>
                        <div class="step-tabs"><span>Education </span></div>
                        <div class="step-tabs"><span>Bank Info</span></div>
                    </div>
                    <div class="mt-3">
                        <div class="travel-wizard">
                            <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off"
                                enctype="multipart/form-data">
                                <div class="steps-wizard">
                                    <div class="text-center fw-semi mb-3">
                                        <div class="fs-4 mb-1">PAN and Aadhar Details</div>
                                        <div class="fs-18 fw-normal">Let's Start Earning Now</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="pan_card" class="control-label input-label">
                                                    Enter PAN Number <span class="text-danger"> * </span>
                                                </label>
                                                <input id="pan_card" formControlName="pan_card"
                                                    placeholder="Enter Pan Card Number" minlength="10" maxlength="10"
                                                    type="text" class="form-control form-control-custom text-uppercase"
                                                    autocomplete="off" />
                                                <span class="icon">
                                                    <img src="images/icons/pan-card.svg" [alt]="projectName" width="20"
                                                        height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('pan_card')" class="custom-error">
                                                    {{ getErrorMessage('pan_card') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="pan_card_doc" class="control-label fs-15 fw-semi mb-2">
                                                Upload PAN Card Photo <small class="text-danger">(Max file size
                                                    3MB)</small> <span class="text-danger"> * </span>
                                            </label>
                                            <div class="form-group">
                                                <div class="drop-zone">
                                                    <input id="pan_card_doc" formControlName="pan_card_doc"
                                                        (change)="onPanCardDocFileSelected($event)"
                                                        accept="{{ globalConstant.file.file_image_pdf_accept }}"
                                                        type="file" class="drop-zone__input" autocomplete="off" />
                                                    @if(panCardDocFileUrl) {
                                                    <div class="drop-zone__thumb">
                                                        @if(isPdfOrImage(panCardDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                                            <img (click)="downloadDocument(panCardDocFileUrl)" src="images/icons/pdf.png" [alt]="projectName" class="custom-download-document" />
                                                        } @else {
                                                            <img (click)="downloadDocument(panCardDocFileUrl)" [src]="panCardDocFileUrl" [alt]="projectName" class="custom-download-document" />
                                                        } 
                                                    </div>
                                                    <button type="button"
                                                        class="btn btn-remove text-danger fs-14 fw-bold"
                                                        id="pan_card_doc_remove" (click)="onPanCardDocFileRemove()"><i
                                                            class="bx bx-trash me-1"></i>REMOVE</button>
                                                    } @else {
                                                    <p class="text-dark fw-semi">Browse Upload</p>
                                                    }
                                                </div>
                                                <p class="fs-13 text-muted mb-2"> {{ globalConstant.file.file_image_pdf_accept_label }}</p>
                                                <mat-error *ngIf="getErrorMessage('pan_card_doc')" class="custom-error">
                                                    {{ getErrorMessage('pan_card_doc') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 signform step_form">
                                            <div class="form-group icon-group mb-3">
                                                <label for="aadhar_number" class="control-label input-label">
                                                    Enter Aadhar Number <span class="text-danger"> * </span>
                                                </label>
                                                <input id="aadhar_number" formControlName="aadhar_number"
                                                    placeholder="Enter Aadhar Number" minlength="12" maxlength="12"
                                                    type="text" class="form-control form-control-custom"
                                                    autocomplete="off" appNumericInput />
                                                <span class="icon">
                                                    <img src="images/icons/aadhar-card.svg" [alt]="projectName"
                                                        width="20" height="20" />
                                                </span>
                                                <mat-error *ngIf="getErrorMessage('aadhar_number')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('aadhar_number') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 mb-3">
                                            <label for="aadhar_front_doc" class="control-label fs-15 fw-semi mb-2">
                                                Upload Aadhar Front <small class="text-danger">(Max file size
                                                    3MB)</small> <span class="text-danger"> * </span>
                                            </label>
                                            <div class="form-group">
                                                <div class="drop-zone">
                                                    <input id="aadhar_front_doc" formControlName="aadhar_front_doc"
                                                        (change)="onAadharFrontDocFileSelected($event)"
                                                        accept="{{ globalConstant.file.file_image_pdf_accept }}"
                                                        type="file" class="drop-zone__input" autocomplete="off" />
                                                    @if(aadharFrontDocFileUrl) {
                                                    <div class="drop-zone__thumb">
                                                        @if(isPdfOrImage(aadharFrontDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                                            <img (click)="downloadDocument(aadharFrontDocFileUrl)" src="images/icons/pdf.png" [alt]="projectName" class="custom-download-document" />
                                                        } @else {
                                                            <img (click)="downloadDocument(aadharFrontDocFileUrl)" [src]="aadharFrontDocFileUrl" [alt]="projectName" class="custom-download-document" />
                                                        } 
                                                    </div>
                                                    <button type="button"
                                                        class="btn btn-remove text-danger fs-14 fw-bold"
                                                        id="aadhar_front_doc_remove"
                                                        (click)="onAadharFrontDocFileRemove()"><i
                                                            class="bx bx-trash me-1"></i>REMOVE</button>
                                                    } @else {
                                                    <p class="text-dark fw-semi">Browse Upload</p>
                                                    }
                                                </div>
                                                <p class="fs-13 text-muted mb-2"> {{ globalConstant.file.file_image_pdf_accept_label }}</p>
                                                <mat-error *ngIf="getErrorMessage('aadhar_front_doc')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('aadhar_front_doc') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 mb-3">
                                            <label for="aadhar_back_doc" class="control-label fs-15 fw-semi mb-2">
                                                Upload Aadhar Back <small class="text-danger">(Max file size
                                                    3MB)</small> <span class="text-danger"> * </span>
                                            </label>
                                            <div class="form-group">
                                                <div class="drop-zone">
                                                    <input id="aadhar_back_doc" formControlName="aadhar_back_doc"
                                                        (change)="onAadharBackDocFileSelected($event)"
                                                        accept="{{ globalConstant.file.file_image_pdf_accept }}"
                                                        type="file" class="drop-zone__input" autocomplete="off" />
                                                    @if(aadharBackDocFileUrl) {
                                                    <div class="drop-zone__thumb">
                                                        @if(isPdfOrImage(aadharBackDocFileUrl) == globalConstant.file_setting.type_pdf) {
                                                            <img (click)="downloadDocument(aadharBackDocFileUrl)" src="images/icons/pdf.png" [alt]="projectName" class="custom-download-document" />
                                                        } @else {
                                                            <img (click)="downloadDocument(aadharBackDocFileUrl)" [src]="aadharBackDocFileUrl" [alt]="projectName" class="custom-download-document" />
                                                        } 
                                                    </div>
                                                    <button type="button"
                                                        class="btn btn-remove text-danger fs-14 fw-bold"
                                                        id="aadhar_back_doc_remove"
                                                        (click)="onAadharBackDocFileRemove()"><i
                                                            class="bx bx-trash me-1"></i>REMOVE</button>
                                                    } @else {
                                                    <p class="text-dark fw-semi">Browse Upload</p>
                                                    }
                                                </div>
                                                <p class="fs-13 text-muted mb-2"> {{ globalConstant.file.file_image_pdf_accept_label }}</p>
                                                <mat-error *ngIf="getErrorMessage('aadhar_back_doc')"
                                                    class="custom-error">
                                                    {{ getErrorMessage('aadhar_back_doc') }}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt-3">
                                    <a [routerLink]="[globalConstant.base_url, routesPospKyc.POSP_KYC || '', routesPospKyc.POSP_KYC_BASIC_DETAILS || '']"
                                        class="btnPrev btn btn-dark rounded-1 px-3">
                                        <i class="bx bx-left-arrow-alt align-middle"></i> Back
                                    </a>
                                    <button type="submit" class="btnNext btn btn-success rounded-1 px-3 ms-2" [disabled]="isLoading">
                                        Next <i class="bx bx-right-arrow-alt"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>