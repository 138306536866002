import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { OFFLINE_SERVICES_ID } from '../../../common/constants/services/offline-services-url.constants';
import { ROUTES_PAGES } from '../../../common/constants/routes/pages/pages.constants';
import { ROUTES_OFFLINE_CAR_INSURANCE } from '../../../common/constants/routes/offline-services/offline-car-insurance.constants';
import { API_RESPONSE } from '../../../common/constants/api-responses.constants';
import { NumericInputDirective } from '../../../common/directives/numeric-input.directive';
import { handleFileSelection, handleReadFile } from '../../../common/utilities/form-rules';
import { MomentUtilitiesService } from '../../../common/utilities/moment-utilities.service';
import { UtilitiesService } from '../../../common/utilities/utilities.service';
import { FormUtilitiesService } from '../../../common/utilities/form-utilities.service';
import { DownloadService } from '../../../common/utilities/download.service';
import { LoaderService } from '../../../common/loader/loader.service';
import { DialogService } from '../../../common/dialog/dialog.service';
import { OfflineCarInsuranceStepTwoService } from './offline-car-insurance-step-two.service';
import { getFormValidators } from './form-utils';

@Component({
  selector: 'app-offline-car-insurance-step-two',
  standalone: true,
  imports: [
    NgIf,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NumericInputDirective
  ],
  templateUrl: './offline-car-insurance-step-two.component.html'
})
export class OfflineCarInsuranceStepTwoComponent implements OnInit {

  globalConstant = GLOBAL_CONSTANT;
  offlineServicesID = OFFLINE_SERVICES_ID;
  routesPages = ROUTES_PAGES;
  routesCarInsurance = ROUTES_OFFLINE_CAR_INSURANCE;

  projectName = this.globalConstant?.projectName;


  isLoading = false;
  uuId: string | null = null;
  customForm: FormGroup;
  serverErrors: { [key: string]: string[] } = {};
  getNomineeRelation: any[] = [];
  getPolicyType: any[] = [];
  getState: any[] = [];

  rcDocFile: File | null = null;
  lastInsuranceDocFile: File | null = null;
  panDocFile: File | null = null;
  aadhaarDocFile: File | null = null;
  otherOneDocFile: File | null = null;
  otherTwoDocFile: File | null = null;

  rcDocFileUrl: string = '';
  lastInsuranceDocFileUrl: string = '';
  panDocFileUrl: string = '';
  aadhaarDocFileUrl: string = '';
  otherOneDocFileUrl: string = '';
  otherTwoDocFileUrl: string = '';

  private apiGetFormDataSubscription: Subscription | undefined;
  private apiGetNomineeRelationSubscription: Subscription | undefined;
  private apiGetPolicyTypeSubscription: Subscription | undefined;
  private apiGetStateSubscription: Subscription | undefined;
  private apiSubmitFormDataSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private momentUtilitiesService: MomentUtilitiesService,
    private utilitiesService: UtilitiesService,
    private formUtilitiesService: FormUtilitiesService,
    private downloadService: DownloadService,
    private offlineCarInsuranceStepTwoService: OfflineCarInsuranceStepTwoService
  ) {
    this.customForm = getFormValidators();
  }

  arrayLength(arr: any): number {
    return this.utilitiesService.arrayLength(arr);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.uuId = params['uuid'] || null;
    });
    if (this.uuId != null && this.uuId != '') {
      this.apiGetFormData({ 'uuid': this.uuId });
    }
    this.apiGeNomineeRelation();
    this.apiGetPolicyType();
    this.apiGetState();
  }

  @ViewChild('date_of_birth', { static: false }) date_of_birth_datepicker!: MatDatepicker<Date>;
  @ViewChild('nominee_dob', { static: false }) nominee_dob_datepicker!: MatDatepicker<Date>;
  maxDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.minDobYear);
  minDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);
  maxNomineeDobDate: Date = this.momentUtilitiesService.getCurrentDateTimeAsDateTime();
  minNomineeDobDate: Date = this.momentUtilitiesService.subtractYearsFromDate(this.globalConstant?.dateTime?.maxDobYear);

  openDobDatepicker() {
    this.date_of_birth_datepicker.open();
  }

  openNomineeDatepicker() {
    this.nominee_dob_datepicker.open();
  }

  isPdfOrImage(url: string): string {
    return this.utilitiesService.isPdfOrImage(url);
  }

  downloadDocument(url: string) {
    this.downloadService.downloadDocument(url);
  }

  restrictNonNumericInput(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', '-', '/'];
    if (!allowedKeys.includes(event.key) && (event.key < '0' || event.key > '9')) {
      event.preventDefault();
    }
  }

  apiGetFormData(params: object) {
    this.apiGetFormDataSubscription = this.offlineCarInsuranceStepTwoService.getFormData(params).subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.objectLength(response?.data) > 0) {
            let orderDetail = response?.data;
            this.customForm.patchValue({
              pan_card: orderDetail?.pan_card || '',
              vehicle_number: orderDetail?.vehicle_number || '',
              date_of_birth: orderDetail?.date_of_birth || '',
              nominee_name: orderDetail?.nominee_name || '',
              nominee_dob: orderDetail?.nominee_dob || '',
              nominee_relation: orderDetail?.nominee_relation || '',
              policy_type: orderDetail?.policy_type || '',
              mother_name: orderDetail?.mother_name || '',
              previous_claim: orderDetail?.previous_claim || '',
              state_code: orderDetail?.state_code || '',
              city: orderDetail?.city || '',
              pincode: orderDetail?.pincode || '',
              address: orderDetail?.address || '',
            });

            if (orderDetail?.rc_doc && orderDetail?.rc_doc != '') {
              this.rcDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.rc_doc;
              this.customForm.get('rc_doc')?.clearValidators();
              this.customForm.get('rc_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.last_insurance_doc && orderDetail?.last_insurance_doc != '') {
              this.lastInsuranceDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.last_insurance_doc;
              this.customForm.get('last_insurance_doc')?.clearValidators();
              this.customForm.get('last_insurance_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.pan_doc && orderDetail?.pan_doc != '') {
              this.panDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.pan_doc;
              this.customForm.get('pan_doc')?.clearValidators();
              this.customForm.get('pan_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.aadhar_doc && orderDetail?.aadhar_doc != '') {
              this.aadhaarDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.aadhar_doc;
              this.customForm.get('aadhar_doc')?.clearValidators();
              this.customForm.get('aadhar_doc')?.updateValueAndValidity();
            }

            if (orderDetail?.other_doc1 && orderDetail?.other_doc1 != '') {
              this.otherOneDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.other_doc1;
              this.customForm.get('other_doc1')?.clearValidators();
              this.customForm.get('other_doc1')?.updateValueAndValidity();
            }

            if (orderDetail?.other_doc2 && orderDetail?.other_doc2 != '') {
              this.otherTwoDocFileUrl = this.globalConstant?.storage_base_url + orderDetail?.other_doc2;
              this.customForm.get('other_doc2')?.clearValidators();
              this.customForm.get('other_doc2')?.updateValueAndValidity();
            }
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
          if (error.status === API_RESPONSE?.status_codes?.bad_request) {
            this.router.navigate(this.utilitiesService.getOffLineServiceURL(this.offlineServicesID.BIKE_INSURANCE, 'step_1'));
          }
        }
      )
    );
  }

  apiGeNomineeRelation() {
    this.apiGetNomineeRelationSubscription = this.offlineCarInsuranceStepTwoService.getNomineeRelation().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
            this.getNomineeRelation = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  apiGetPolicyType() {
    this.apiGetPolicyTypeSubscription = this.offlineCarInsuranceStepTwoService.getPolicyType().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
            this.getPolicyType = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  apiGetState() {
    this.apiGetStateSubscription = this.offlineCarInsuranceStepTwoService.getState().subscribe(
      this.utilitiesService.handleSubscription(
        (response) => {
          if (response?.data && this.utilitiesService.arrayLength(response?.data) > 0) {
            this.getState = response?.data;
          }
        },
        (error) => {
          this.utilitiesService.handleHttpError(error);
        }
      )
    );
  }

  onRCDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('rc_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.rcDocFile = file;
      handleReadFile(this.rcDocFile, (result: string) => {
        this.rcDocFileUrl = result;
      });
    }
  }

  onLastInsuranceDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('last_insurance_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.lastInsuranceDocFile = file;
      handleReadFile(this.lastInsuranceDocFile, (result: string) => {
        this.lastInsuranceDocFileUrl = result;
      });
    }
  }

  onPANDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('pan_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.panDocFile = file;
      handleReadFile(this.panDocFile, (result: string) => {
        this.panDocFileUrl = result;
      });
    }
  }

  onAadhaarDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('aadhar_doc')?.setErrors(error);
      return;
    }
    if (file) {
      this.aadhaarDocFile = file;
      handleReadFile(this.aadhaarDocFile, (result: string) => {
        this.aadhaarDocFileUrl = result;
      });
    }
  }

  onOtherOneDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('other_doc1')?.setErrors(error);
      return;
    }
    if (file) {
      this.otherOneDocFile = file;
      handleReadFile(this.otherOneDocFile, (result: string) => {
        this.otherOneDocFileUrl = result;
      });
    }
  }

  onOtherTwoDocFileSelected(event: Event): void {
    const { file, error } = handleFileSelection(event, this.globalConstant.file.file_image_pdf_type, this.globalConstant.file.max_file_size);
    if (error) {
      this.customForm.get('other_doc2')?.setErrors(error);
      return;
    }
    if (file) {
      this.otherTwoDocFile = file;
      handleReadFile(this.otherTwoDocFile, (result: string) => {
        this.otherTwoDocFileUrl = result;
      });
    }
  }

  onRCDocFileRemove(): void {
    this.rcDocFile = null;
    this.rcDocFileUrl = '';
    this.customForm.get('rc_doc')?.reset();
    this.customForm.get('rc_doc')?.setValidators([Validators.required]);
    this.customForm.get('rc_doc')?.updateValueAndValidity();
  }

  onLastInsuranceDocFileRemove(): void {
    this.lastInsuranceDocFile = null;
    this.lastInsuranceDocFileUrl = '';
    this.customForm.get('last_insurance_doc')?.reset();
  }

  onPANDocFileRemove(): void {
    this.panDocFile = null;
    this.panDocFileUrl = '';
    this.customForm.get('pan_doc')?.reset();
  }

  onAadhaarDocFileRemove(): void {
    this.aadhaarDocFile = null;
    this.aadhaarDocFileUrl = '';
    this.customForm.get('aadhar_doc')?.reset();
  }

  onOtherOneDocFileRemove(): void {
    this.otherOneDocFile = null;
    this.otherOneDocFileUrl = '';
    this.customForm.get('other_doc1')?.reset();
  }

  onOtherTwoDocFileRemove(): void {
    this.otherTwoDocFile = null;
    this.otherTwoDocFileUrl = '';
    this.customForm.get('other_doc2')?.reset();
  }

  onSubmit() {
    if (this.customForm.valid) {
      this.isLoading = true;
      this.loaderService.show();

      let accept_t_c = 'no';
      if (this.customForm.value.accept_t_c) {
        accept_t_c = 'yes';
      }
      let date_of_birth = '';
      let nominee_dob = '';
      if (this.customForm.value.date_of_birth) {
        date_of_birth = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.date_of_birth);
      }
      if (this.customForm.value.nominee_dob) {
        nominee_dob = this.momentUtilitiesService.formInsertDateFormate(this.customForm.value.nominee_dob);
      }
      const data = {
        uuid: this.uuId,
        service_step: this.customForm.value.service_step,
        pan_card: this.customForm.value.pan_card,
        vehicle_number: this.customForm.value.vehicle_number,
        date_of_birth: date_of_birth,
        nominee_name: this.customForm.value.nominee_name,
        nominee_dob: nominee_dob,
        nominee_relation: this.customForm.value.nominee_relation,
        policy_type: this.customForm.value.policy_type,
        mother_name: this.customForm.value.mother_name,
        previous_claim: this.customForm.value.previous_claim,
        state_code: this.customForm.value.state_code,
        city: this.customForm.value.city,
        pincode: this.customForm.value.pincode,
        address: this.customForm.value.address,
        accept_t_c: accept_t_c
      };
      let formData = this.formUtilitiesService.toFormData(data);
      if (this.rcDocFile) {
        formData.append('rc_doc', this.rcDocFile, this.rcDocFile.name);
      }
      if (this.lastInsuranceDocFile) {
        formData.append('last_insurance_doc', this.lastInsuranceDocFile, this.lastInsuranceDocFile.name);
      }
      if (this.otherOneDocFile) {
        formData.append('other_doc1', this.otherOneDocFile, this.otherOneDocFile.name);
      }
      if (this.otherTwoDocFile) {
        formData.append('other_doc2', this.otherTwoDocFile, this.otherTwoDocFile.name);
      }
      if (this.panDocFile) {
        formData.append('pan_doc', this.panDocFile, this.panDocFile.name);
      }
      if (this.aadhaarDocFile) {
        formData.append('aadhar_doc', this.aadhaarDocFile, this.aadhaarDocFile.name);
      }
      this.apiSubmitFormDataSubscription = this.offlineCarInsuranceStepTwoService.postData(formData).subscribe(
        this.utilitiesService.handleSubscription(
          (response) => {
            this.serverErrors = {};
            this.isLoading = false;
            this.loaderService.hide();
            let orderDetail = response?.data;
            const dialogRef = this.dialogService.openDialog({ content: response?.message });
            dialogRef.afterClosed().subscribe(result => {
              if (orderDetail?.uuid) {
                this.router.navigate(this.utilitiesService.getOffLineServiceOrderDetailURL(this.offlineServicesID.CAR_INSURANCE, orderDetail?.uuid));
              } else {
                this.router.navigate([this.globalConstant.base_url]);
              }
            });
          },
          (error) => {
            this.isLoading = false;
            this.loaderService.hide();
            if (error.status === API_RESPONSE?.status_codes?.unprocessable_entity && error.error?.data) {
              this.serverErrors = this.formUtilitiesService.handleServerValidationError(error, this.customForm);
            } else {
              this.serverErrors = {};
              this.utilitiesService.handleHttpError(error);
            }
          }
        )
      );
    } else {
      this.formUtilitiesService.markAllControlsAsTouched(this.customForm);
    }
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.customForm.get(controlName);
    return control ? this.formUtilitiesService.getErrorMessage(control, controlName, this.serverErrors) : null;
  }

  ngOnDestroy(): void {
    if (this.apiGetFormDataSubscription) {
      this.apiGetFormDataSubscription.unsubscribe();
    }
    if (this.apiGetNomineeRelationSubscription) {
      this.apiGetNomineeRelationSubscription.unsubscribe();
    }
    if (this.apiGetPolicyTypeSubscription) {
      this.apiGetPolicyTypeSubscription.unsubscribe();
    }
    if (this.apiGetStateSubscription) {
      this.apiGetStateSubscription.unsubscribe();
    }
    if (this.apiSubmitFormDataSubscription) {
      this.apiSubmitFormDataSubscription.unsubscribe();
    }
  }

}
