@if(objectLength(examQuestionContent) > 0) {
<section class="sec insplan py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="row">
                    <div class="col-7 col-md-8">
                        <p class="fw-semi fs-5 mb-0">Pass this exam to Start Winning More</p>
                        <p class="mb-4">Complete the exam ! </p>
                    </div>
                    @if(isShowTimer) {
                        <div class="col-5 col-md-4 text-end">
                            <div class="d-block">
                                <span class="fw-semi fs-4 float-end" id="exam_total_hours">
                                    {{ timerValue }}
                                </span>
                                <img src="images/icons/time-clock.png" [alt]="projectName" width="40" class="me-2" />
                            </div>
                        </div>
                    }
                    <div class="col- col-sm-12 col-md-12 col-lg-12">
                        <div class="bg-white w-100 text-start border rounded-2 shadown-sm overflow-hidden">
                            <div class="py-1 px-4 py-3 bg-light-success">
                                <h2 class="fs-5 mb-0">Question {{ examQuestionContent?.current_question || '' }}
                                    <span class="float-end rounded-5 btn-lg border px-3 py-1 fs-6">
                                        {{ examQuestionContent?.current_question || '' }} of {{
                                        examQuestionContent?.total_question || '' }}
                                    </span>
                                </h2>
                            </div>
                            @if(examQuestionContent?.question && objectLength(examQuestionContent?.question) > 0) {
                            <form [formGroup]="customForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="col-md-12 pt-4 pb-2 px-4">
                                    <p class="fw-semi" [innerHTML]="examQuestionContent?.question?.question || ''"></p>
                                </div>
                                <div class="col-md-12 px-4">
                                    <div class="form-group">
                                        <div
                                            class="form-check typeradio question_aws customradio  w-100">
                                            <input class="form-check-input" type="radio" formControlName="answer"
                                                id="option_a" value="a" />
                                            <label class="form-check-label" for="option_a"
                                                [innerHTML]="examQuestionContent?.question?.option_a || ''"></label>
                                        </div>
                                        <div
                                            class="form-check typeradio question_aws customradio  w-100">
                                            <input class="form-check-input" type="radio" formControlName="answer"
                                                id="option_b" value="b" />
                                            <label class="form-check-label" for="option_b"
                                                [innerHTML]="examQuestionContent?.question?.option_b || ''"></label>
                                        </div>
                                        <div
                                            class="form-check typeradio question_aws customradio  w-100">
                                            <input class="form-check-input" type="radio" formControlName="answer"
                                                id="option_c" value="c" />
                                            <label class="form-check-label" for="option_c"
                                                [innerHTML]="examQuestionContent?.question?.option_c || ''"></label>
                                        </div>
                                        <div
                                            class="form-check typeradio question_aws customradio  w-100">
                                            <input class="form-check-input" type="radio" formControlName="answer"
                                                id="option_d" value="d" />
                                            <label class="form-check-label" for="option_d"
                                                [innerHTML]="examQuestionContent?.question?.option_d || ''"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group text-center mt-2 mb-4">
                                        <button type="submit" aria-label="plans"
                                            class="btn btn-green rounded-5 px-5 btn-lg" [disabled]="isLoading"> Save &amp; Next </button>
                                    </div>
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
}