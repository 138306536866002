import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GLOBAL_CONSTANT } from '../../../common/constants/global-constant.constants';
import { panCardValidator } from '../../../common/utilities/form-rules';

export function getFormValidators(): FormGroup {
    return new FormGroup({
        service_step: new FormControl(GLOBAL_CONSTANT?.offline_truck_pickup_insurance_step_match?.step_2 || 'insurance_details'),
        uuid: new FormControl(''),
        pan_card: new FormControl('', [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            panCardValidator
        ]),
        vehicle_number: new FormControl('', [
            Validators.required,
            Validators.maxLength(20)
        ]),
        date_of_birth: new FormControl('', [
            Validators.required
        ]),
        nominee_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        nominee_dob: new FormControl('', [
            Validators.required
        ]),
        nominee_relation: new FormControl('', [
            Validators.required
        ]),
        policy_type: new FormControl('', [
            Validators.required
        ]),
        mother_name: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        previous_claim: new FormControl('', [
            Validators.required
        ]),
        state_code: new FormControl('', [
            Validators.required
        ]),
        city: new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]),
        pincode: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
        ]),
        address: new FormControl('', [
            Validators.required
        ]),
        accept_t_c: new FormControl('', [
            Validators.required
        ]),
        rc_doc: new FormControl('', [
            Validators.required
        ]),
        last_insurance_doc: new FormControl('', []),
        other_doc1: new FormControl('', []),
        other_doc2: new FormControl('', [])
    });
}
