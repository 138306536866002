import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './loader.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule
  ],
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {
  isLoading = false;
  private loadingSub!: Subscription;

  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.loadingSub = this.loaderService.loadingState.subscribe((state: boolean) => {
      this.isLoading = state;
    });
  }

  ngOnDestroy(): void {
    this.loadingSub.unsubscribe();
  }
}
