import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { switchMap, catchError } from 'rxjs/operators';

import { GLOBAL_CONSTANT } from '../constants/global-constant.constants';
import { ROUTES_POSP } from '../constants/routes/posp/posp.constants';
import { LocalStorageService } from '../utilities/local-storage.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { AuthUsersService } from '../utilities/auth-users.service';

import { storeSetUserProfileGuard } from '../../store/actions/user-profile-guard.actions';
import { StoreStateUserProfileGuard } from '../../store/reducers/user-profile-guard.reducers';
import { selectStoreUserProfileGuard } from '../../store/selectors/user-profile-guard.selectors';

export const pospProcessGuard: CanActivateFn = (route, state) => {
  const localStorageService = inject(LocalStorageService);
  const utilitiesService = inject(UtilitiesService);
  const authUsersService = inject(AuthUsersService);
  const router = inject(Router);
  const store = inject(Store<StoreStateUserProfileGuard>);

  if (!localStorageService.isLoggedIn(GLOBAL_CONSTANT?.session?.auth_token)) {
    router.navigate([GLOBAL_CONSTANT.base_url, ROUTES_POSP?.POSP || '', ROUTES_POSP?.POSP_SIGN_IN || '']);
    return of(false);
  }

  return store.pipe(
    select(selectStoreUserProfileGuard),
    switchMap(userProfileGuard => {
      if (userProfileGuard) {
        if (userProfileGuard?.['user_type'] === GLOBAL_CONSTANT?.user_type?.posp) {
          if (
            (userProfileGuard?.['kyc_status'] && userProfileGuard?.['kyc_status'] == GLOBAL_CONSTANT?.status_match?.approved) &&
            (userProfileGuard?.['verified_status'] && userProfileGuard?.['verified_status'] == GLOBAL_CONSTANT?.posp_verified_status_match?.posp_nvoy_irdai_checked_done) &&
            (userProfileGuard?.['training_status'] && userProfileGuard?.['training_status'] == GLOBAL_CONSTANT?.status_match?.completed) &&
            (userProfileGuard?.['exam_status'] && userProfileGuard?.['exam_status'] == GLOBAL_CONSTANT?.status_match?.passed)
          ) {
            router.navigate([GLOBAL_CONSTANT.base_url]);
            return of(false);
          }
        }
        return of(true);
      } else {
        return authUsersService.getProfileProcessAllUsersData().pipe(
          switchMap(response => {
            if (response?.data && utilitiesService.objectLength(response?.data) > 0) {
              let userData = response?.data;
              if (userData?.user_type === GLOBAL_CONSTANT?.user_type?.posp) {
                if (
                  (userData?.kyc_status && userData?.kyc_status == GLOBAL_CONSTANT?.status_match?.approved) &&
                  (userData?.verified_status && userData?.verified_status == GLOBAL_CONSTANT?.posp_verified_status_match?.posp_nvoy_irdai_checked_done) &&
                  (userData?.training_status && userData?.training_status == GLOBAL_CONSTANT?.status_match?.completed) &&
                  (userData?.exam_status && userData?.exam_status == GLOBAL_CONSTANT?.status_match?.passed)
                ) {
                  router.navigate([GLOBAL_CONSTANT.base_url]);
                  return of(false);
                }
                store.dispatch(storeSetUserProfileGuard({ storeData: response.data }));
                return of(true);
              }
              authUsersService.logoutUsers();
              router.navigate([GLOBAL_CONSTANT.base_url, ROUTES_POSP?.POSP || '', ROUTES_POSP?.POSP_SIGN_IN || '']);
              return of(false);
            }
            authUsersService.logoutUsers();
            router.navigate([GLOBAL_CONSTANT.base_url, ROUTES_POSP?.POSP || '', ROUTES_POSP?.POSP_SIGN_IN || '']);
            return of(false);
          }),
          catchError(error => {
            authUsersService.logoutUsers();
            router.navigate([GLOBAL_CONSTANT.base_url, ROUTES_POSP?.POSP || '', ROUTES_POSP?.POSP_SIGN_IN || '']);
            return of(false);
          })
        );
      }
    })
  );
};
