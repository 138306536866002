import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { GLOBAL_CONSTANT } from '../../constants/global-constant.constants';
import { ROUTES_PAGES } from '../../constants/routes/pages/pages.constants';
import { AuthUsersService } from '../../utilities/auth-users.service';

@Component({
  selector: 'app-private-header',
  standalone: true,
  imports: [
    RouterModule,
    AsyncPipe
  ],
  templateUrl: './private-header.component.html'
})
export class PrivateHeaderComponent implements OnInit {
  globalConstant = GLOBAL_CONSTANT;
  routesPages = ROUTES_PAGES;

  projectName = this.globalConstant?.projectName;
  isLoggedIn$!: Observable<boolean>;

  constructor(
    private router: Router,
    private authUsersService: AuthUsersService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authUsersService.isLoggedIn$;
  }

  async logout(): Promise<void> {
    try {
      await this.authUsersService.logoutUsers();
      this.router.navigate([this.globalConstant.base_url]);
    } catch (error) {
      this.router.navigate([this.globalConstant.base_url]);
    }
  }

}
